<template>
  <div class="box-typical box-typical-padding" style="border: none;">
    <!-- <div v-if="studentAllInfo.educational_documents.length || studentAllInfo.work_history_documents.length ||
    studentAllInfo.reference_documents.length || studentAllInfo.visa_history_documents.length" class="documentation_part p-0"> -->
    <div class="documentation_part p-0">

        <div class="row" id="reload_edu_history_doc" v-if="generalRequirements && generalRequirements.length">
          <div class="col-md-12">
              <div class="content_header ml-0">
                <div class="left">
                    <i class="fa fa-list" aria-hidden="true" style="padding: 7px 7px;"></i>
                </div>
                <div class="right">
                    <span class="main_title">
                    <!-- Genereal Requirements -->
                    Document or Information Required
                    </span>
                </div>
              </div>
          </div>
          <div class="col-md-12 overflow_div_responsive" v-if="generalRequirements && generalRequirements.length">
              <div class="single_doc_item" v-for="(row, index) in generalRequirements" :key="index">
                <div class="left">
                    <i class="fa fa-file-text" v-if="row.need_upload" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                    <i class="fa fa-info" v-else style="background: #f1f0fb; padding: 5px 9px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                    <strong v-if="row.requirement_name" v-html="row.requirement_name"></strong>
                </div>
                <div class="center">
                    <!-- <span v-if="row.completed" class="text-success" v-html="'Received'"></span>
                    <span v-else v-html="'Pending'"></span> -->
                    <span v-if="row.completed" v-html="'Received'" class="label_badge_radius badge-light-green" style="border: none;"></span>
                        <span v-else v-html="'Pending'" class="label_badge_radius badge-lighter-red"></span>
                </div>
                <div class="right">
                    <div class="round_btn_groups">
                      <div class="add_more_drop tooltips" v-if="row.file_url">
                        <i v-tooltip="'View File'" @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                      </div>
                      <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                        <div class="add_more_drop open_dynamic_modal tooltips">
                            <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                        </div>
                      </a>
                      <div @click="openDocumentUploadModal(row)" v-if="row.need_upload && row.completed != 1" class="add_more_drop">
                          <i v-tooltip="'Upload File'" class="fa fa-upload dropdown-toggle"></i>
                      </div>
                      <div @click="openDocumentUploadModal(row)" v-if="!row.need_upload" class="add_more_drop">
                          <i v-tooltip="'Enter information'" style="padding: 4px 9px !important;" class="fa fa-info dropdown-toggle"></i>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          <div class="col-md-12 mt-2" v-else>
            <div role="alert" class="alert mb-1" style="border:1px solid rgb(233, 240, 243); font-size: 12px !important; color: rgb(220, 53, 69); border-radius: 30px; padding: 8px 15px; margin-left: 40px;"><span>No Education History available.</span></div>
          </div>
        </div>

        <!-- <div class="row" id="reload_edu_history_doc" v-if="enrollmentRequirements && enrollmentRequirements.length" style="margin-top:50px;">
          <div class="col-md-12">
              <div class="content_header ml-0">
                <div class="left">
                    <i class="fa fa-list" aria-hidden="true" style="padding: 7px 7px;"></i>
                </div>
                <div class="right">
                    <span class="main_title">
                      Application Requirement 
                    </span>
                </div>
              </div>
          </div>
          <div class="col-md-12" v-if="enrollmentRequirements && enrollmentRequirements.length">
              <div class="single_doc_item" v-for="(row, index) in enrollmentRequirements" :key="index">
                <div class="left" style="line-height: 1;">
                    <i class="fa fa-paper-plane" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px; top: 7px; position: relative;"></i>
                    <strong class="hand hover-danger" @click="openEnrollmentRequimentListModal(row)" v-if="row.university" v-html="row.university.name"></strong>
                    <div style="margin-left: 25px;">
                      <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;" v-if="row.level" v-html="row.level.name"></small>
                      <span v-if="row.start_date"> • </span>
                      <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;" v-if="row.start_date" v-html="dMonth(row.start_date)"></small> 
                      <span v-if="row.subject"> • </span>
                      <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;" v-html="row.subject"></small> 
                    </div>
                </div>
                <div class="right">
                    <div class="round_btn_groups">
                      <div class="round_btn_groups hand" @click="openEnrollmentRequimentListModal(row)">
                          <span class="label_badge_radius" style="background: #f5f4fd; border: 1px solid #ceccda;">
                              <strong v-html="row.student_requirements.length" style="color: #2e1153; font-weight: 700;"></strong>
                              Requirements
                          </span>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          
          <div class="col-md-12 mt-2" v-else>
            <div role="alert" class="alert mb-1" style="border:1px solid rgb(233, 240, 243); font-size: 12px !important; color: rgb(220, 53, 69); border-radius: 30px; padding: 8px 15px; margin-left: 40px;"><span>No Visa Details available.</span></div>
          </div>
        </div> -->

    </div>

    <b-modal ref="document_upload_modal" size="lg" centered hide-footer>
        <!-- <template #modal-title>
            <span class="text-primary">Add Requirement {{ form.document_upload.need_upload ? 'Document' : 'Information' }}</span>
        </template> -->

        <template #modal-header>
          <h5 class="modal_title">Add Requirement {{ form.document_upload.need_upload ? 'Document' : 'Information' }}</h5>
          <button type="button" @click="$refs.document_upload_modal.hide()">
                <i class="font-icon-close-2"></i>
            </button>
        </template>

        <div class="wait_me_form">
            <div class="row add_edit_additional_doc_form">
              <div class="col-lg-12">
                  <div class="box-typical" style="padding: 15px 15px 16px; margin: 10px 15px 15px; border-radius: .25rem; border: none;">

                    <div class="row" v-if="form.document_upload.need_upload">
                        <div class="col-md-12">
                            <div class="form-row">
                                <div class="form-group col-md-6 mb-1">
                                    <label class="form-label" for="documentfile" style="color: #5d5d5d !important; font-size: 12px; font-weight: 600; margin-bottom: 6px !important;">
                                        Upload File
                                    </label>
                                    <input type="file" v-on:change="add_attachment_for_document_upload($event)" name="documentfile" id="documentfile" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-else>
                      <div class="col-md-12">
                        <div class="form-group mb-0">
                            <div style="background: #dedbf1; padding: 7px 16px; border-radius: 30px; font-size: 12px; font-weight: 700; margin-bottom: 20px;">
                                Information
                            </div>
                            <textarea :disabled="form.document_upload.completed == 1" class="form-control" v-model="form.document_upload.information" id="information" rows="2" placeholder="Insert Student requirement information here..." style="min-height: 55px; max-height: 55px;"></textarea>
                        </div>
                      </div>
                    </div>

                  </div>
              </div>
              <div class="col-lg-12 mt-0 text-right" v-if="form.document_upload.completed != 1">
                  <button class="btn cancel_button mr-1" @click="$refs.document_upload_modal.hide()">Cancel</button>
                  <button class="btn submit_button btn-submit mr-1" @click="uploadDocumentData()" type="submit">Submit</button>
              </div>
          </div>
        </div>
    </b-modal>


    <b-modal ref="enrollment_requirement_list_modal" size="lg" centered hide-footer>
        <!-- <template #modal-title>
            <span class="text-primary">Enrollment Requirements - {{ enrollmentRequirementItem.university ? enrollmentRequirementItem.university.name : '' }}</span>
        </template> -->

        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <h5 class="modal_title">Application Requirement - {{ enrollmentRequirementItem.university ? enrollmentRequirementItem.university.name : '' }}</h5>
          <button type="button" @click="$refs.enrollment_requirement_list_modal.hide()">
                <i class="font-icon-close-2"></i>
            </button>
        </template>

        <div class="wait_me_insert_form">
            <div class="row">
              <div class="col-lg-12">
              <div class="whole_content overflow_div_responsive">
                <table id="enrollment_requirements_table" class="table table-sm table-bordered list_table thin_header">
                  <thead>
                    <tr style="width: 100%;">
                      <th class="text-center" style="width: 5%;">SL</th>
                      <th class="text-center" style="width: 10%;">Type</th>
                      <th style="width: 50%;">Particular</th>
                      <th class="text-center" style="width: 15%;">Completed</th>
                      <th class="text-center" style="width: 10%;">Doc/Info</th>
                      <th class="text-center" style="width: 10%;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in enrollmentRequirementItem.student_requirements" :key="index">
                      <td class="text-center">{{ index + 1}}</td>
                      <td class="text-center">
                        <div class="round_wrapper">
                          <div class="round_box round_box_doc" v-if="row.need_upload" v-tooltip="'Document'">
                            <i class="fa fa-file-text" aria-hidden="true"></i>
                          </div>
                          <div class="round_box round_box_info" v-else v-tooltip="'Information'">
                            <i class="fa fa-info" aria-hidden="true"></i>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span v-if="row.requirement_name" v-html="row.requirement_name"></span>
                      </td>
                      <td class="text-center">
                        <span v-if="row.completed" v-html="'Yes'" class="label_badge_radius badge-light-green" style="border: none;"></span>
                        <span v-else v-html="'Pending'" class="label_badge_radius badge-lighter-red"></span>
                      </td>
                      <td class="text-center">
                        <div class="round_btn_groups" style="justify-content: center;">
                          <div class="add_more_drop tooltips" v-if="row.file_url">
                            <i v-tooltip="'View File'" @click="global_viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                          </div>
                          <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                            <div class="add_more_drop open_dynamic_modal tooltips">
                                <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                            </div>
                          </a>
                          <div @click="openDocumentUploadModal(row)" v-if="row.need_upload && row.completed != 1" class="add_more_drop">
                              <i v-tooltip="'Upload File'" class="fa fa-upload dropdown-toggle"></i>
                          </div>
                          <div @click="openDocumentUploadModal(row)" v-if="!row.need_upload" class="add_more_drop">
                              <i v-tooltip="'Enter information'" style="padding: 4px 9px !important;" class="fa fa-info dropdown-toggle"></i>
                          </div>
                        </div>
                      </td>

                      <td class="text-center">
                        <div class="round_btn_groups"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
                
              <div class="col-lg-12 mt-0 text-right">
                  <button class="btn cancel_button" @click="$refs.enrollment_requirement_list_modal.hide()">Cancel</button>
              </div>
            </div>

        </div>
    </b-modal>

    <div :class="imageViewerImageUrl ? 'image-viewer-open' : 'image-viewer'">
          <div class="row">
            <div class="col-md-12 text-right">
                <div style="width: 100%; position: relative; display: inline;">
                    <span class="fa fa-times pdfclose" @click="closeViewFile()"></span>
                    <iframe :src="imageViewerImageUrl" style="width: 91%; height: 90vh; background: #f2f3f5;"></iframe>
                </div>
            </div>
        </div>
    </div>
    
  </div>
</template>
<script>
import feather from "feather-icons";
// import VueApexCharts from 'vue-apexcharts';
// import { BDropdown, BDropdownItem } from 'bootstrap-vue';
// import Treeselect from '@riophae/vue-treeselect';
import { BModal } from 'bootstrap-vue';

export default {
  name: "RequirementsContent",
  props: ['studentAllInfo', 'enrollmentId'],
  components: {
    // VueApexCharts,
    // BDropdown, BDropdownItem,
    // Treeselect
    BModal
  },
  data: () => {
    return {
        flag: {
            show_filter: false,
        },
        search: {
            intake_id: null,
        },
        enrolmentList: [],
        form: {
          document_upload: {
            id: '',
            requirement_name: '',
            documentfile: '',
            file_url: '',
            information: '',
            enrolment_id: '',
            group_id: '',
          },
        },
        enrollmentRequirementItem: [],
        imageViewerImageUrl: ''
    };
  },
  computed: {
    generalRequirements: function () {
      if (this.studentAllInfo.lead) {
          if (this.studentAllInfo.lead.requirement_maps) {
            return this.studentAllInfo.lead.requirement_maps.filter(item => item.group_id == 2 && item.completed !=1)
          } else {
            return []
          }
      } else {
          return []
      }
    },
    enrollmentRequirements: function () {
      if (this.studentAllInfo.enrollment_requirements) {
        return this.studentAllInfo.enrollment_requirements
      } else {
        return []
      }
    }
  },
  watch: {
  },
  methods: {
    openEnrollmentRequimentListModalByEnrollmentId: function(enrollmentId) {
      if (enrollmentId) {
        console.log('enrollmentId', enrollmentId)
        if (this.enrollmentRequirements.length) {
          const enrollmentItemObj = this.enrollmentRequirements.find(item => item.id == enrollmentId)
          if (enrollmentItemObj) {
            this.enrollmentRequirementItem = enrollmentItemObj;
            this.$refs.enrollment_requirement_list_modal.show()
          }
        }
      }
    },
    openDocumentUploadModal: function(row) {
      this.form.document_upload = row;
      this.$refs.document_upload_modal.show()
    },
    openEnrollmentRequimentListModal: function(row) {
      this.enrollmentRequirementItem = row;
      console.log('this.enrollmentRequirementItem', this.enrollmentRequirementItem)
      this.$refs.enrollment_requirement_list_modal.show()
    },
    toggle_filter: function() {
      this.flag.show_filter = !this.flag.show_filter;
    },
    viewFile: function(item) {
      // window.open(item.file_url)
      this.imageViewerImageUrl = item.file_url
    },
    global_viewFile: function(item) {
      this.$emit('open-file-view', {
            file_url: item.file_url,
        })
    },
    closeViewFile: function() {
      // window.open(item.file_url)
      this.imageViewerImageUrl = ''
    },
    additionalDocumentInitialData: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v2/student/ajax/get_additional_document_initial_data');

        try{
          var res=await jq.get(url);
          this.enrolmentList = res.data.enrollments;
        }catch(error){
          console.log(error);
        }

    },
    resetDocumentData: function () {
        this.form.document_upload = {
            id: '',
            requirement_name: '',
            documentfile: '',
            file_url: '',
            information: '',
            enrollment_id: '',
            group_id: '',
        }
    },
    add_attachment_for_document_upload: function (e) {
        let file_input = e.target.files[0];
        this.form.document_upload.documentfile = file_input
    },
    add_attachment: function (e) {
        let file_input = e.target.files[0];
        this.form.additional_documents.documentfile = file_input
    },
    uploadDocumentData: async function () {
        var ref=this;
        var jq=ref.jq();

        var form_data = new FormData();
        const item = ref.form.document_upload
        console.log('ref.form.document_upload', ref.form.document_upload)

        form_data.append('id', item.id);
        form_data.append('group_id', item.group_id);
        form_data.append('enrollment_id', item.enrollment_id);
        form_data.append('remarks', item.remarks);
        form_data.append('information', item.information);
        form_data.append('documentfile', item.documentfile);

        ref.wait_me('.wait_me_form');

        jq.ajax({
            url: ref.url('api/v2/student/ajax/update_requirement_doc_or_info'),
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{
                ref.alert(res.msg);
                ref.$refs.enrollment_requirement_list_modal.hide()
                ref.$refs.document_upload_modal.hide()
                this.resetDocumentData()
                ref.$emit('get-dashboard-data', true)
            }
        }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
        ).always(()=>ref.wait_me('.wait_me_form', 'hide'));
    },
    submitAdditionalDocumentData: async function () {
        var ref=this;
        var jq=ref.jq();

        var form_data = new FormData();
        const item = ref.form.additional_documents

        // if (item.file) {
        //     form_data.append('attachment', item.file);
        // } else if (item.file_url) {
        //     form_data.append('attachment', null);
        // } else {
        //     form_data.append('attachment', false);
        // }

        form_data.append('id', item.id);
        form_data.append('name', item.name);
        form_data.append('enrolment', item.enrolment);
        form_data.append('information', item.information);
        form_data.append('documentfile', item.documentfile);

        ref.wait_me('.wait_me_form');

        jq.ajax({
            url: ref.url('api/v2/student/ajax/store_additional_documents_data'),
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{
                ref.alert(res.msg);
                ref.$refs.additional_document_modal.hide()
                this.resetAdditionDocuments()
                ref.$emit('get-dashboard-data', true)
            }
        }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
        ).always(()=>ref.wait_me('.wait_me_form', 'hide'));
    },
  },
  created: function() {
    this.setup_urls();
  },
  mounted: function() {
    feather.replace();
    this.additionalDocumentInitialData();
  },
};
</script>

<style>
    
.download_btn{

    color: #999999;
    font-size: initial;
    border-bottom: none !important;
    padding: 0;
    border: none;
    margin: 0;

}

.documentation_part .single_doc_item{
        display: flex;
        background: fff;
        padding: 7px 16px;
        margin-top: 10px;
        border-radius: 30px;
        border: 1px solid #e9f0f3;
        margin-left: 27px;
    }
    .documentation_part .single_doc_item:hover{
        background: #f5f4fd;
    }
    .documentation_part .single_doc_item .left{
        width: calc(100% - 125px);
        align-self: center;
    }
    .documentation_part .single_doc_item .left strong{
        color: rgb(57, 59, 62);
        font-size: 12px;
    }
    .documentation_part .single_doc_item .right{
        width: 125px;
        align-self: center;
        text-align: -webkit-right;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar{
        width: fit-content;
        overflow: auto;
        border-radius: .25rem;
        /*margin: 0 auto;*/
        display: flex;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar a {
        text-align: center;
        padding: 0;
        /*color: #818084;*/
        color: #c3c3c3;
        font-size: 16px;
        margin: 0 3px;
        border-bottom: none;
        top: 0;

        display: block;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar .fa{
        font-size: .9rem;
        padding: 3px 5px;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar .btn_round{
        border: .5px solid #d8e2e7;
        border-radius: 50%;
        /*width: 25px;
        height: 25px;*/
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar .btn_round:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-left: 3px;
    }
    /*.documentation_part .single_doc_item .right .rounded_icon_bar .fa-file-pdf-o {
        padding: 3px 6px;
    }*/

    .add_more_drop .dropdown-toggle:after{
        display: none;
    }
    /*.add_more_drop .dropdown-toggle{
        color: #c3c3c3;
        cursor: pointer;
        border: .5px solid #d8e2e7;
        border-radius: 50%;
        padding: 3px 5px;
        font-size: .9rem;
        padding: 3px 5px;
    }*/
    .add_more_drop .dropdown-menu.show{
        /*margin-left: -97px;*/
        min-width: auto;
        padding: 0;

        z-index: 2;
        top: -5px !important; 
        left: 60px !important;
    }
    .add_more_drop .dropdown-menu.show .dropdown-item{
        background: #dedbf1;
        color: #2e1153;
        font-size: 12px;
        border: none;
    }
    .add_more_drop .dropdown-menu.show .dropdown-item:hover{
        background: #2e1153;
        color: #fff !important;
    }
    .add_more_drop .dropdown-menu.show .activate{
        background: #2e1153;
        color: #fff;
    }
    .add_more_drop .dropdown-menu.show .dropdown-item:first-child{
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
    .add_more_drop .dropdown-menu.show .dropdown-item:last-child{
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }


    .add_more_drop .dropdown-toggle{
        background: #dedbf1;
        padding: 4px 8px 2px;
        border-radius: 50%;
        font-size: 13px;
        border: 1px solid transparent;
        color: #826aa0;
        cursor: pointer;
    }
    .add_more_drop .dropdown-toggle:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .add_more_drop{
        z-index: auto !important;

        margin: 0 3.5px;
    }

    .documentation_part .single_doc_item .right img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }

    .round_btn_groups{
        display: flex;
        width: auto;
        justify-content: flex-end;
      }
      .round_btn_groups .add_more_drop .fa-paperclip{
        padding: 4px 6px 4px !important;
        font-size: 13px;
      }
      .round_btn_groups .add_more_drop .fa-upload, .round_btn_groups .add_more_drop .fa-trash, .round_btn_groups .add_more_drop .fa-pencil{
        color: #826aa0 !important;
      }
      .round_btn_groups .add_more_drop .fa-eye{
        padding: 4px 4.5px !important;
        color: #826aa0 !important;
      }
      .add_more_drop .dropdown-toggle{
        padding: 4px 5px !important;
      }
      .round_btn_groups .add_more_drop .font-icon{
        color: #826aa0 !important;
      }
.submit_button {
    background-color: #2e1153 !important;
    border-width: 1px !important;
    border-color: #2e1153 !important;
    color: #fff !important;
    border-radius: 30px !important;
    padding: 0 15px !important;
    height: 28px !important;
    font-size: .9rem !important;
    line-height: 2 !important;
}
.submit_button:hover {
    background-color: #fff !important;
    border-color: #2e1153 !important;
    color: #2e1153 !important;
}
.hover-danger:hover {
  color:#ff7f00 !important;
  transition: 300ms ease-in-out all;
}

.list_table .round_wrapper{
    text-align: -webkit-center;
    display: inline-flex;
  }
  .list_table .round_box{
    width: 25px;
    height: 25px;
    color: #2e1153;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin: 0 3px;
    position: relative;
    background-color: #f1f0fb !important;
  }
  .list_table .round_box_doc{
    background: #00a8ff;
  }
  .list_table .round_box_info{
    background: #46c35f;
  }
  .list_table .round_box .fa-file-text{
    position: absolute;
    top: 46%;
    transform: translate(0, -50%);
    left: 32%;
    font-size: 9px;
  }
  .list_table .round_box .fa-info{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 42%;
    font-size: 11px;
  }
  .list_table .round_box:hover{
    background: #fff;
    color: #f79322 !important;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    border: 1px solid #f79322;
  }
  .thin_header thead tr th{
    padding-top: 6px !important;
    padding-bottom: 5px !important;
  }
.whole_content{
  margin: 15px;
  background: #fff;
}
.thin_header{
  border: none !important;
}
</style>
