<template>
  <section class="personal-info">


<div class="collapse_common">
   <div class="row py-2 px-2">
      <div class="col-lg-12">
         <div style="min-height: 520px;">
            <div class="study_abroad_part_all">
               <div class="row study_abroad_part">
                  <div class="col-lg-12 px-0">
                     <div class="title_in_border">List of Previous Study Abroad</div>
                  </div>
                  <div class="col-lg-12 px-0">
                     <div class="row">
                        <div class="col-lg-12" v-for="(row, index) in list.edu_histories" :key="index">
                           <div class="single_part">
                              <div class="left">
                                 <i class="fa fa-plane" aria-hidden="true"></i>
                              </div>
                              <div class="right" id="parent">
                                 <span class="progress-lbl" style="font-size: 12px; font-weight: 600; color: black;"  v-html='row.institution'></span>
                                 •
                                 <span style="font-size: 11px;color: #868e96;">
                                 <span v-html='row.start_year'></span> <span v-html='row.start_month'></span> - <span v-html='row.end_year'></span> <span v-html='row.end_month'></span>
                                 </span>
                                 <br>
                                 <span style="color: #343434;font-weight: 400;" v-html='row.subject'></span><br>
                                 <span class="label_badge_radius" v-if='row.level'  v-html='row.level.name'> </span><br>
                              </div>
                              <div class="extra" id="parent">
                                 <div class="form_part pt-0">
                                    <div class="first">
                                       <div class="form-group mb-2">
                                          <label class="form_label">Study Destination</label>
                                            <treeselect
                                                :options="list.countries"
                                                placeholder="Select Study Destination"
                                                autocomplete="off"
                                                v-model="row.country_id"
                                            />
                                       </div>
                                    </div>
                                    <div class="second">
                                       <div class="form-group mb-2">
                                          <label class="form_label">Visa Type</label>
                                            <treeselect
                                                :options="list.visa_types"
                                                placeholder="Select Visa Type"
                                                autocomplete="off"
                                                v-model="row.visa_type"
                                            />
                                       </div>
                                    </div>
                                    <div class="third">
                                       <div class="form-group" style="padding-top: 20px; text-align: center;">
                                          <button class="btn cancel_button btn-cancel mr-1 closeBtn" style="padding: 4px 8px !important;">
                                          <i aria-hidden="true" class="fa fa-refresh" v-on:click="fetch_previous_satudy_abroad()"></i>
                                          </button>
                                          <button type="" data-id="24200" data-leadid="37228" class="btn submit_button study_save_btn ml-1 submit_cls_24200" v-on:click="update_edu_history(row)">
                                          Save
                                          </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <button class="btn submit_button passport_submit_complete complete_step pull-right"
              style="bottom: 0; position: absolute; right: 15px"
              v-if="!flag.step_completed"
              v-on:click="complete_this_step()">
              Save &amp; Complete
            </button>
         </div>
      </div>

   </div>
</div>
    
  </section>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import swal from "bootstrap-sweetalert";

export default {
  name: "PreviousStudyAbroad",
  components: {
    Treeselect,
  },
  data() {
    return {
      list: {
        edu_histories: [],
        countries: [],
        visa_types: [],
      },
      configs: {
        flatpickr: {
          dateFormat: "d M Y",
          //maxDate: new Date()
        },
      },
      flag:{
        step_completed: false
      }
    };
  },
  methods: {
    fetch_previous_satudy_abroad: async function () {

      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v2/student/ajax/previouss_atudy_abroad");

      try {

        var res = await jq.get(url);
        ref.list.edu_histories = res.data.edu_histories;
        ref.list.countries = res.data.countries;
        ref.list.visa_types = res.data.visa_types;
        ref.flag.step_completed = res.data.step_completed;

      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }

    },
    update_edu_history: async function(row){

      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v2/student/ajax/update_edu_history");
      try {

        ref.wait_me('#wait_me');
        var res = await jq.post(url, row);
        ref.fetch_previous_satudy_abroad();
        ref.alert(res.msg);
        this.$emit('dashboard-info-update', true)

      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }finally{
        ref.wait_me('#wait_me', 'hide');
      }

    },
    complete_this_step: async function(){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/lead_previous_study_abroad_step_completed");
      var jq = ref.jq();

      swal({
        // title: "Are you sure? You want to submit your previous study abroad information as completed.",
        title: "Are you sure?",
        text: "Press Yes to confirm.",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url);
                ref.alert(res.msg);
                ref.fetch_previous_satudy_abroad();

                ref.$emit("save-and-completed", true);
                
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    }
  },

  created: function () {
    this.setup_urls();
    this.fetch_previous_satudy_abroad();
  },
};
</script>

<style scoped>
    .table tbody tr td{
      font-size: 12px;
      }
      .study_abroad_part_all{
      height: 480px;
      overflow-y: auto;
      overflow-x: hidden;
      background: #fff;
      position: relative;margin-bottom: 20px;
      }
      .study_abroad_part_all::-webkit-scrollbar {
      width: 7px;
      }
      .study_abroad_part_all::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 5px;
      }
      .study_abroad_part_all::-webkit-scrollbar-thumb {
      /*background: #00a8ff;*/
      background: linear-gradient(90deg, rgba(183,180,212,1) 0%, rgb(165 164 181) 0%, #fff 75%);
      border-radius: 5px;
      }
      .study_abroad_part{
      margin: 0;
      /*padding: 20px 0 0;*/
      padding: 0;
      }
      .study_abroad_part .single_part {
      display: flex;
      width: 100%;
      /*background: #fff;
      padding: 10px 15px;
      border: none;
      border-bottom: 1px dashed #e6e8e8;*/
      padding: 7px 16px;
      margin-top: 10px;
      border-radius: .25rem;
      border: 1px solid #e9f0f3;
      align-items: center;
      }
      .study_abroad_part .single_part:hover {
      background: #f5f4fd;
      }
      .study_abroad_part .single_part .right {
      width: calc(30% - 45px);
      align-self: center;
      }
      .study_abroad_part .single_part .extra {
      /*width: calc(70% - 50px);*/
      width: 70%;
      }
      /*.study_abroad_part .single_part .action_part {
      width: 50px;
      align-self: center;
      text-align: center;
      padding-top: 7px;
      }*/
      .study_abroad_part .single_part .extra .form_part .third .cancel_button {
      margin-right: 0 !important;
      padding: 0;
      margin-left: 5px;
      }
      .study_abroad_part .single_part .extra .cancel_button .fa-refresh {
      /*padding: 5px 8px;*/
      }
      .study_abroad_part .single_part .extra .form_part{
      display: flex; 
      /*width: 100%;*/
      /*justify-content: right;*/
      width: 435px;
      margin-left: auto;
      }
      .study_abroad_part .single_part .extra .form_part .first, .form_part .second{
      /*width: calc(50% - 67.5px);*/
      width: 150px;
      margin-right: 10px;
      }
      .study_abroad_part .single_part .extra .form_part .third{
      width: 145px;
      align-self: center;
      text-align: center;
      }
      .study_abroad_part .single_part .right h3 {
      font-size: 11px; 
      font-weight: bold; 
      color: rgb(98, 95, 139); 
      margin-bottom: 6px;
      }
      .study_abroad_part .single_part .left {
      width: 45px;
      align-self: center;
      }
      .study_abroad_part .single_part .left .fa {
      background: #f1f0fb;
      padding: 10px 12px;
      border-radius: 100%;
      font-size: 14px;
      color: #2e1153;
      }
      .study_abroad_part .single_part .right .edu_level_label {
      color: rgb(0 168 255);
      border-radius: 20px;
      padding: 1px 10px;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.3;
      background: #e4f2f9;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      }
      .info_middle {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      }
      .study_abrad_bottom_list .single_part {
      display: flex;
      width: 100%;
      /*padding: 15px;
      border: none;
      border-bottom: 1px dashed #e6e8e8;
      background-color: #fff;
      margin-bottom: 0;
      border-radius: 0 !important;*/
      padding: 7px 16px;
      margin-top: 10px;
      border-radius: 50px;
      border: 1px solid #e9f0f3;
      align-items: center;
      }
      .study_abrad_bottom_list .single_part:hover {
      background: #f5f4fd;
      }
      /*.study_abroad_part .single_part:last-child {
      border-bottom: none;
      }*/
      .study_abrad_bottom_list .single_part .middle/*, .scrolled_content_part .single_part .right*/ {
      width: calc(100% - 45px);
      }
      .study_abrad_bottom_list .single_part .middle h3, .scrolled_content_part .single_part .right h3 {
      font-size: 11px; 
      font-weight: bold; 
      color: rgb(98, 95, 139); 
      margin-bottom: 6px;
      }
      .study_abrad_bottom_list .single_part .left {
      width: 45px;
      align-self: center;
      }
      .study_abrad_bottom_list .single_part .left .fa {
      background: #f1f0fb;
      padding: 10px 12px;
      border-radius: 100%;
      font-size: 14px;
      color: #2e1153;
      }
      .study_abroad_part_all .label_badge_light {
      border-radius: 20px;
      padding: 1px 10px;
      font-size: 10px;
      font-weight: 400;
      line-height: 1.3;
      /*background: #e0ddf2;*/
      letter-spacing: .2px;
      color: black;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-image: linear-gradient(to right, #dedbf1 , #f5f4fd);
      }
      .study_abrad_bottom_list_outer{
      position: relative;
      border: 1px solid #d8e2e7;
      border-radius: .25rem;
      margin-top: 10px;
      }
      .study_abroad_part .title_in_border {
      /*background: white;
      width: fit-content;
      padding: 5px;
      font-size: 13px;
      font-weight: 600;
      top: -14px;
      position: absolute;
      left: 12px;
      z-index: 2;*/
      background: #dedbf1;
      padding: 7px 16px;
      margin-top: 10px;
      border-radius: 30px;
      position: relative;
      font-size: 12px;
      font-weight: 700;
      width: inherit;
      left: 0;
      top: -10px;
      }
      .action_dropdown .dropdown-toggle{
      background: #dedbf1;
      padding: 4px 9px 3px;
      border-radius: 50%;
      font-size: 13px;
      border: 1px solid transparent;
      color: #826aa0;
      cursor: pointer;
      }
      .action_dropdown .dropdown-toggle:hover {
      color: #ff7f00 !important;
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      border: 1px solid #ff7f00;
      }
      .action_dropdown .dropdown-toggle:after{
      display: none;
      }
      .action_dropdown .dropdown-menu.show{
      margin-left: -55px;
      min-width: auto;
      padding: 0;
      margin-top: 3px;
      }
      .action_dropdown .dropdown-menu.show .dropdown-item{
      background: #dedbf1;
      color: #2e1153;
      font-size: 12px;
      border: none;
      padding: 4px 12px;
      }
      .action_dropdown .dropdown-menu.show .dropdown-item:hover{
      background: #2e1153;
      color: #fff !important;
      }
      .action_dropdown .dropdown-menu.show .activate{
      background: #2e1153;
      color: #fff;
      }
      .action_dropdown .dropdown-menu.show .dropdown-item:first-child{
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      }
      .action_dropdown .dropdown-menu.show .dropdown-item:last-child{
      border-bottom-left-radius: .25rem;
      border-bottom-right-radius: .25rem;
      }
</style>
