<template>
  <div class="card mb-2" v-if="!flag.lead_converted && !flag.stages_submitted">
  <!-- <div class="card mb-2"> -->
    <div class="card-body p-0">
      <section class="student-full-profile-stages">

        <div id="full_profile_stage_part">
          <div class="container-fluid p-0">
            <div class="form-row mx-0" id="stages">

              <div class="col-lg-3 col-6" v-for="(stage_col, index) in list.stages" :key="index">
                <ul class="list-group list-group-flush">

                  <li class="list-group-item px-0 pl-1 btn_collapse_personal_info" v-for="stage in stage_col" :key="stage.id" :class="stage.completed?'complete_li':'incomplete_li'" v-on:click="toggle_stage_form(stage)">
                    <i class="fa btn btn-rounded btn-inline btn-default" :class="stage.completed?'fa-check-circle complete_btn':'fa-circle-o incomplete_btn'"></i>
                    <strong v-html="stage.label"></strong>
                  </li>
                </ul>
              </div>

              <div v-if="cn(list, 'stages.length', null)" class="col-lg-3 col-6" style="align-self: center;padding: 0; text-align: center;">
                
                <button class="btn py-50" :class="!flag.stages_completed ? 'btn-submit-stages-disabled' : 'btn-submit-stages'" :disabled="!flag.stages_completed" v-on:click="submit_all_stages_info()">Submit</button>

              </div>

              <div
                class="text-center"
                style="margin-top: 5px; margin-bottom: 5px"
              ></div>
            </div>

            <!-- <button data-toggle="collapse" data-target="#demo">Collapsible</button> -->

            <div class="collapse_common" v-if="flag.show_stage_form">
              <personal-info v-if="info.active_stage_form.id=='personal_info'" v-on:stage-close="stageClose()" v-on:save-and-completed="check_lead_converted()" v-on:dashboard-info-update="getDashBoardData()"></personal-info>
              <edu-history v-if="info.active_stage_form.id=='education_history'" v-on:stage-close="stageClose()" v-on:save-and-completed="check_lead_converted()" v-on:dashboard-info-update="getDashBoardData()"></edu-history>
              <visa-history-and-refusal v-if="info.active_stage_form.id=='visa_history_and_refusal'" v-on:stage-close="stageClose()" v-on:save-and-completed="check_lead_converted()" v-on:dashboard-info-update="getDashBoardData()"></visa-history-and-refusal>
              <contact-info v-if="info.active_stage_form.id=='contact_info'" v-on:stage-close="stageClose()" v-on:save-and-completed="check_lead_converted()" v-on:dashboard-info-update="getDashBoardData()"></contact-info>
              <passport-details v-if="info.active_stage_form.id=='passport_details'" v-on:stage-close="stageClose()" v-on:save-and-completed="check_lead_converted()" v-on:dashboard-info-update="getDashBoardData()"></passport-details>
              <previous-study-abroad v-if="info.active_stage_form.id=='previous_study_abroad'" v-on:stage-close="stageClose()"  v-on:save-and-completed="check_lead_converted()" v-on:dashboard-info-update="getDashBoardData()"></previous-study-abroad>
              <test-score v-if="info.active_stage_form.id=='test_score'" v-on:stage-close="stageClose()" v-on:save-and-completed="check_lead_converted()" v-on:dashboard-info-update="getDashBoardData()"></test-score>
              <reference-and-work v-if="info.active_stage_form.id=='reference_and_work'" v-on:stage-close="stageClose()" v-on:save-and-completed="check_lead_converted()" v-on:dashboard-info-update="getDashBoardData()"></reference-and-work>
              <admission-preference v-if="info.active_stage_form.id=='admission_pref'" v-on:stage-close="stageClose()" v-on:save-and-completed="check_lead_converted()" v-on:dashboard-info-update="getDashBoardData()"></admission-preference>
            </div>

          </div>
        </div>

      </section>
    </div>

    <b-modal ref="enrollment_requirement_list_modal" size="lg" centered hide-footer>
        <template #modal-header>
          <h5 class="modal_title">Application Requirement</h5>
          <button type="button" @click="$refs.enrollment_requirement_list_modal.hide()">
                <i class="font-icon-close-2"></i>
            </button>
        </template>

        <div class="wait_me_insert_form">
            <div class="row">
              <div class="col-lg-12">
              <div class="table-responsive" v-if="studentAllInfo.lead && studentAllInfo.lead.lead_educational_history.length">
                <table class="table table-bordered list_table">
                  <thead>
                    <tr>
                      <th>Institution</th>
                      <th>Subject</th>
                      <th class="text-center">Result</th>
                      <th>Duration</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(education, index) in studentAllInfo.lead.lead_educational_history" :key="index">
                      <td>
                        <div class="institute_info">
                          <div class="left">
                            <i class="fa fa-university" aria-hidden="true"></i>
                          </div>
                          <div class="right">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-html="education.institution"></strong><br />
                            <span class="label_badge_light" v-if="education.certificate" v-html="education.certificate"></span>
                          </div>
                        </div>
                      </td>
                      <td v-html="education.subject"></td>
                      <td class="text-center">
                        <span v-html="education.result"></span>
                        <span v-if="education.unit" v-html="' ' + education.unit"></span>
                      </td>
                      <td v-html="education.start_month + ' ' + education.start_year + ' - ' + education.end_month + ' ' + education.end_year">
                      </td>
                      <td>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" :id="education.id" v-on:click="education_primary_selection(education)">
                          </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive" v-else>
                <div class="alert mb-1" role="alert" style="border:1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                    <span>No Education History available.</span>
                </div>
              </div>
              </div>
              <div class="col-lg-12 mt-0 text-right">
                  <button class="btn cancel_button" @click="$refs.enrollment_requirement_list_modal.hide()">Cancel</button>
              </div>
            </div>

        </div>
    </b-modal>
  </div>
</template>

<script>

import feather from "feather-icons";

import PersonalInfo from './StudentFullProfileStages/PersonalInfo.vue';
import EduHistory from './StudentFullProfileStages/EduHistory.vue';
import VisaHistoryAndRefusal from './StudentFullProfileStages/VisaHistoryAndRefusal.vue';
import ContactInfo from './StudentFullProfileStages/ContactInfo.vue';
import PassportDetails from './StudentFullProfileStages/PassportDetails.vue';
import PreviousStudyAbroad from './StudentFullProfileStages/PreviousStudyAbroad.vue';
import TestScore from './StudentFullProfileStages/TestScore.vue';
import ReferenceAndWork from './StudentFullProfileStages/ReferenceAndWork.vue';
import AdmissionPreference from './StudentFullProfileStages/AdmissionPreference.vue';
import swal from 'bootstrap-sweetalert';
import { BModal } from 'bootstrap-vue';

export default {
  name: "StudentFullProfileStages",
  props: ['studentAllInfo'],
  components:{
    PersonalInfo,
    ContactInfo,
    PassportDetails,
    EduHistory,
    VisaHistoryAndRefusal,
    PreviousStudyAbroad,
    TestScore,
    ReferenceAndWork,
    AdmissionPreference,
    BModal
  },
  data(){
    return {
      flag:{
        lead_converted: true,
        stages_completed: false,
        check_primary: false,
        show_stage_form: false,
        stages_submitted: true
      },
      list:{
        stages:[]
      },
      info:{
        active_stage_form:null
      }
    };
  },
  methods: {
    getDashBoardData: function () {
      this.$emit('get-dashboard-data', true)
    },
    check_lead_converted: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v2/student/ajax/check_lead_converted');

      try{
        var res=await jq.get(url);
        ref.flag.lead_converted=res.data.lead_converted;
        ref.flag.stages_submitted=res.data.stages_submitted;
        ref.list.stages = res.data.stages;

        ref.check_lead_steps_completed();

      }catch(err){
        ref.alert(ref.err_msg(err), 'error');
      }

    },
    toggle_stage_form: function(stage){
      console.log(stage);
      
      var ref=this;
      if(!ref.info.active_stage_form){
        ref.flag.show_stage_form=!ref.flag.show_stage_form;
      }else if(ref.info.active_stage_form.id==stage.id){
        ref.flag.show_stage_form=!ref.flag.show_stage_form;
      }
      
      
      if(ref.flag.show_stage_form){
        ref.info.active_stage_form=stage;
      }else{
        ref.info.active_stage_form=null;
      }

    },
    stageClose: function () {
      this.flag.show_stage_form = false
    },
    check_lead_steps_completed: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v2/student/ajax/check_lead_steps_completed');

      try{
        var res=await jq.get(url);

        if(res.data.all_step_completed && !res.data.steps_submitted_at && !res.data.file_number){

          ref.flag.stages_completed=true;

          if(!res.data.check_primary){
            ref.flag.check_primary=true;
          }

          /* 
          var url_sass = ref.url("api/v2/student/ajax/student_all_step_submitted");
          
          swal({
            title: "Are you sure? You want to submit your information as completed.",
            text: "Press Yes to confirm.",
            type: "info",
            showCancelButton: true,
            confirmButtonClass: "btn-success",
            cancelButtonClass: "btn-light",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: true
          },
            function (isConfirm) {
              if (isConfirm) {
                (async function () {
                  try {
                    var res = await jq.post(url_sass);
                    ref.alert(res.msg);
                  } catch (error) {
                    ref.alert(ref.err_msg(error), "error");
                  }
                })();
              }
            }
          ); */
        }
      }catch(error){
        console.log(error);
      }

    },
    submit_all_stages_info: async function(){

      var ref=this;
      var jq=ref.jq();
      var url_sass = ref.url("api/v2/student/ajax/student_all_step_submitted");
      console.log(ref.flag.check_primary);
      if(ref.flag.check_primary){
        this.$refs.enrollment_requirement_list_modal.show()
      }else{
        swal({
          title: "Are you sure, you want to submit your Application?",
          text: "Press Yes to confirm.",
          type: "info",
          showCancelButton: true,
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-light",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true
        },
          function (isConfirm) {
            if (isConfirm) {
              (async function () {
                try {
                  var res = await jq.post(url_sass);
                  ref.alert(res.msg);
                  ref.flag.stages_submitted=true;
                  ref.$store.commit('changeStudentStageStatus', true);
                } catch (error) {
                  ref.check_lead_steps_completed();
                  ref.alert(ref.err_msg(error), "error");
                }
              })();
            }
          }
        );
      }

    },
    education_primary_selection: async function(item){

      var ref=this;
      var jq=ref.jq();
      var url_sass = ref.url("api/v2/student/ajax/education_primary_selection");      
      swal({
        title: "Are you sure, you want to select as primary?",
        text: "Press Yes to confirm.",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url_sass,{id:item.id,lead_id:item.lead_id});
                ref.alert(res.msg);
                ref.flag.check_primary=false;
                ref.check_lead_steps_completed();
                ref.$refs.enrollment_requirement_list_modal.hide()
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );


    }

  },
  created: function(){
    this.setup_urls();
  },
  mounted: function(){
    feather.replace();
    this.check_lead_converted();
  }
};
</script>

<style>
#stages .complete_btn {
  padding: 0;
  font-size: 9px;
  font-size: 14px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  /*border-color: #fff;
  background-color: #fff;*/

  top: -3px;
  position: relative;
  color: #2e1153 !important;
  background: transparent !important;
  border-color: transparent;
}
#stages .list-group-item {
  padding: 0rem 0rem;
  border: none;
  margin: 3px;
}
#stages {
  /*background: #919fa9;*/
  background: #dedbf1;
  /*border: 1px solid #6c7a86;*/
  /*border: 1px solid #cac9d2;*/
  padding: 8px 0;
  height: 106px;
}
#stages .complete_li {
  /*color: #dcdcdc;
  background: #a1acb3;*/
  font-size: 12px;
  cursor: default;
  border-radius: 40px;
  cursor: pointer;

  /*background: #f3f3f3;*/
  background: #f5f4fd;
}
#stages .complete_li strong {
  top: 1px;
  position: relative;
}
#stages .incomplete_btn {
  padding: 0;
  font-size: 14px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  top: -3px;
  position: relative;
  color: #2e1153 !important;
  background: transparent !important;
  border-color: transparent;
}
#stages .incomplete_li {
  background: #fff;
  font-size: 12px;
  border-radius: 40px;
  /*box-shadow: 5px 5px 4px -4px #888888;*/
  cursor: pointer;
  /*border: 1px solid #cac9d2;*/
}
#stages .incomplete_li strong {
  top: 1px;
  position: relative;
}
#stages .incomplete_li:hover {
  background-color: #2e1153;
  color: #fff !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
#stages .incomplete_li:hover .incomplete_btn {
  background-color: #fff;
  color: #fff !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
#stages .btn.btn-inline {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.rate{
    border: none;
}
.input-group-addon:hover {
    background-color: #00a8ff;
    border: none;
    border-color: #00a8ff;
    color: #fff !important;
}
.dateNicon input {
    background: #fff !important;
    border-right: none;
}
.input-group-addon {
    background: #fff;
    padding: 0 .75rem;
}
.glyphicon{
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
}

.stage_form_header {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      border-radius: 2px;
      border: none;
      line-height: 1.2;

      background-image: linear-gradient(to right, #dedbf1 , #f5f4fd);
      padding: 7px 10px;
      border-radius: 4px;
      border: none;
  }
  .stage_form_header .right {
      width: 95%;
  }
  .stage_form_header .left {
      width: 40px;
      align-self: center;
  }
  .stage_form_header .left .fa, .stage_form_header .left .bx, .stage_form_header .left .font-icon, .stage_form_header .left .glyphicon {
      background: #fff;
      padding: 7px;
      border-radius: 100%;
      font-size: 14px;

      border: 1px solid #ceccda;
  }
  .stage_form_header .right .main_title {
      font-size: 13px; 
      font-weight: 600; 
      color: black;
      display: block;
  }
  .stage_form_header .right .sub_title {
      color: #343434;
      font-weight: 400;
  }

.cancel_button {
  background-color: #ffc107;
  border-width: 1px;
  border-color: #ffc107;
  color: #fff;
  border-radius: 30px;
  padding: 0 15px;
  height: 28px;
  font-size: 1.2rem;
  margin-right: 8px !important;
  font-weight: 600 !important;
}

.cancel_button:hover {
  background-color: #ffffff !important;
  border-color: #ffc107 !important;
  color: #ffc107 !important;
}

select.form-control:not([size]):not([multiple]) {
    height: calc(29px + 2px);
    padding: 0 10px;
}
.form_label {
    color: #868e96;
    font-size: 12px;
}
.form-control{
  font-size: 13px;
  padding: 5px 0.75rem !important;
}

.form_part {
    padding: 10px 10px 0px;
    border-radius: 4px;
}

.submit_button {
    background-color: #2e1153;
    border-width: 1px;
    border-color: #2e1153;
    color: #fff;
    border-radius: 30px;
    padding: 0 15px;
    height: 28px;
    font-size: 1.2rem;
    line-height: 2;
    font-weight: 600 !important;
}
.cancel_button {
    background-color: #ffc107 !important;
    border-width: 1px !important;
    border-color: #ffc107 !important;
    color: #fff !important;
    border-radius: 30px !important;
    padding: 0 15px !important;
    height: 28px !important;
    font-size: 1.2rem !important;
    margin-right: 8px !important;
    font-weight: 600 !important;
}


.student-full-profile-stages .form-control{
  border: solid 1px rgba(197, 214, 222, .7);
  height: inherit;
}
.student-full-profile-stages .form-control:focus{
  border: solid 1px rgba(197, 214, 222, .7) !important;
  box-shadow: none !important;
  transition: none;
}
.input-group-addon {
    background: #fff;
    padding: 0 .75rem;
    border-color: rgba(197, 214, 222, .7);
    font-size: 1rem;
    font-weight: 600;
    color: #6c7a86;
    padding-top: 4px;
    border: solid 1px rgba(197, 214, 222, .7);
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-left: none;
}
.radio_box label {
    font-size: 12px;
}

.radio_box label input:checked + span {
    background-color: #f5f4fd;
}
.radio_box label span {
    padding: 7px 16px;
    margin-top: 10px;
    border-radius: 30px;
    border: 1px solid #e9f0f3;
    width: 100%;
    color: rgb(57, 59, 62);
    font-size: 12px;
    font-weight: 600;
    display: -webkit-inline-box;
}
.radio_box label span:hover {
    background: #f5f4fd;
}
.radio_box label span:before {
    top: 2px;
    position: relative;
}
.radio_box label {
    width: 100%;
}

.flatpickr-input{
    background: #fff !important;
    border-right: none;
    min-height: 35px;
}

.btn-submit-stages{

  background: #2e1153 !important;
  color: #fff !important;
  border: transparent !important;
  border-radius: 30px !important;
  box-shadow: none;
  font-size: 0.875rem;
  border: 1px solid #cac9d2 !important;
  font-weight: 600;

}

.btn-submit-stages-disabled{

  background: #cbbedc !important;
  color: #fff !important;
  border: transparent !important;
  border-radius: 30px !important;
  box-shadow: none;
  font-size: 0.875rem;
  border: 1px solid #cac9d2 !important;
  font-weight: 600;
}

.submit_btn_sm {
    background: #2e1153 !important;
    border: 1px solid #2e1153 !important;
    color: #fff !important;
    padding: 3px 10px !important;
    cursor: pointer;
    float: right;
    margin: -2px -10px 0 0;
    font-weight: 600;
    border-radius: 20px;
    font-size: 11px;
    line-height: 1.3;
    letter-spacing: .2px;
    height: 20px;
}
</style>
