<template>
<section class="login">
    <!-- <div style="position: absolute;z-index:1;width: 100%;">
           <div class="error_msg"> 
                <div class="error_msg_inner">
                    <div class="left">
                        <i class="fa fa-cog bounce" style="font-size: 35px;color: #ffffff;"></i>
                    </div>
                    <div class="right">
                        <span style="margin-top: 20px;font-weight: bolder; color:#ffffff; font-size: 13px;">Our server will be undergoing essential maintenance on <b>December 25th between 5 AM and 12 PM (GMT)</b>. We apologize for any disruption this may cause and appreciate your understanding.</span>
                    </div>
                </div> 
            </div>
        </div> -->
    <!-- BEGIN: Content-->
    <div class="app-content content">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v2">
                    <div class="auth-inner row m-0">
                        <!-- Brand logo-->
                        <!-- <a class="brand-logo" href="javascript:void(0);">
                            <img :src="url('website/images/SAMS-CRM-logo (1).png')" width="225">
                        </a> -->
                        <!-- /Brand logo-->
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 login_cover_image">
                            <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"><img class="img-fluid" :src="url('static/vuexy/app-assets/images/pages/login-page.jpg')" alt="Login V2" /></div> -->
                            <!-- <img :src="url('website/images/maintenance.jpg')" alt="Login V2" /> -->
                            <img :src="url('new_login/img/login_back2.jpg')" alt="Login V2" />
                        </div>
                        <!-- /Left Text-->
                        <!-- Login-->
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5" id="sss">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto" style="padding: 0 29px !important;">
                                <button class="btn btn-outline-primary toast-basic-toggler mt-2" style="display:none">Toast</button>
                                <h2 class="card-title font-weight-bold mb-1">Welcome to  <br>Student Portal!</h2>
                                <p class="card-text mb-2 text-3xl">Please sign-in to your account.</p>
                                <div class="auth-login-form mt-2">
                                    <div class="form-group">
                                        <label class="form-label">Email</label>
                                        <input class="form-control" type="text" placeholder="Email Address" aria-describedby="login-email" autofocus="" tabindex="1" v-model="form.login.email" v-on:keyup.enter="login()"/>
                                    </div>
                                    <div class="form-group">
                                        <div class="d-flex justify-content-between">
                                            <label>Password</label>
                                            <router-link  to="/forgot-password">
                                                <small>Forgot Password?</small>
                                            </router-link>

                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input class="form-control form-control-merge" type="password" placeholder="Password" aria-describedby="login-password" tabindex="2" v-model="form.login.password"  v-on:keyup.enter="login()"/>
                                            <!-- <div class="input-group-append"><span class="input-group-text cursor-pointer"><i data-feather="eye"></i></span></div> -->
                                        </div>
                                    </div>
<!--                                     <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" id="remember-me" type="checkbox" tabindex="3" />
                                            <label class="custom-control-label" for="remember-me"> Remember Me</label>
                                        </div>
                                    </div> -->
                                    <button type="button" class="btn btn-primary btn-block" tabindex="4" v-on:click="login()">Sign in</button>
                                </div>
<!--                                 <p class="text-center mt-2"><span>New on our platform?</span><a href="a-re.html"><span>&nbsp;Create an account</span></a></p>
                                <div class="divider my-2">
                                    <div class="divider-text">or</div>
                                </div> -->
                            </div>
                                <p class="text-center copyright">
                                    <!-- <span>Copyright &copy; {{ dYear(new Date()) }} SAMS CRM. All rights reserved.</span> -->
                                </p>
                        </div>
                        <!-- /Login-->
                    </div>
                </div>
            </div>
        </div>
    </div>



</section>
</template>

<script>

export default {
    name: 'Login',
    metaInfo: {
        titleTemplate: "Welcome to student portal. Login to your account",
    },
    data: function(){
        return {
            form:{
                login:{
                    email:'',
                    password:''
                }
            }
        };
    },
    methods: {
        reset_forms: function(){
            var ref=this;
            ref.form.login.email='';
            ref.form.login.password='';
        },
        login: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v2/student/auth/login');
            ref.wait_me('.auth-login-form');

            var form_data=ref.form.login;

            try{

                ref.wait_me('.email-fields', 'hide');
                var res=await jq.post(url, form_data);
                ref.alert(res.msg);
                ref.reset_forms();
                localStorage.setItem('student_api_token', res.data.token);
                localStorage.setItem('base_url', ref.base_url);
                ref.$store.commit('setApiToken', res.data.token);
                ref.ajax_setup('student_api_token');

                ref.$store.commit('siteInfo', {
                    key: 'auth_user',
                    val: res.data.user
                });
                
                ref.$store.commit('siteInfo', {
                    key: 'countries',
                    val: res.data.countries
                });
                // ref.$store.commit('siteInfo', {
                //     key: 'permissions',
                //     val: res.data.permissions
                // });

                // ref.$store.commit('siteInfo', {
                //     key: 'university',
                //     val: res.data.university
                // });

                if (res.data.user) {
                    ref.$router.push('/dashboard');
                }


            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.auth-login-form', 'hide');
            }

        }
    },
    mounted: function(){
        var jq=this.jq();
        jq('body').addClass('blank-page');
    },
    created: function(){
        this.setup_urls();
        localStorage.removeItem('student_api_token');
    }
}
</script>


<style scoped>
@import "../../static/vuexy/app-assets/css/pages/page-auth.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/css/font-awesome.css";

.error_msg{
    background: #e90341;
    padding: 10px 10px;
    font-size: 0.845rem;
    font-weight: 500;
    margin-bottom: 16px; 
    line-height: 1.55; 
}

.error_msg_inner{
    width: fit-content !important;
    margin: 0 auto;
    display: flex;
    width: 100%;

}

.error_msg .left{
    width: 30px; 
    align-self: center;

}

.error_msg .right{
    width: calc(100% - 30px);
    padding-left: 10px;
    color: #e90341;  
    align-self: center;
}

.bounce { 
    -webkit-animation: spin 8s infinite linear;
    color: #e90341;
}

@-webkit-keyframes spin {
    0%  {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);}	
}

.login_cover_image {
    position: relative;
}

.login_cover_image img {
    position: absolute;
    left: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.copyright{
    position: absolute;
    bottom: 0;
    left: 90px;
    color:#d8d6de;
}


.auth-wrapper {
    font-family: "Montserrat", Helvetica, Arial, serif !important;
    color: #6e6b7b !important;
}
.auth-wrapper .card-title, #sss .card-text {
    font-family: "Montserrat", Helvetica, Arial, serif !important;
}
.auth-wrapper .card-text {
    font-size: 14px !important;
    margin-bottom: 24px !important;
}
.auth-wrapper label {
    font-size: 12px;
}
.auth-wrapper .form-control {
    font-size: 15px !important;
}
.auth-wrapper small {
    font-size: 12px !important;
}
.auth-wrapper .btn-primary {
    border-color: #2e1153 !important;
    background-color: #2e1153 !important;
    color: #fff !important;
    font-size: 14px;
    line-height: 1.2;
    border-radius: 0.358rem !important;
}
.auth-wrapper .btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #7367f0;
}
</style>
