<template>
  <section class="personal-info">
    <div
      id="form_lead_personal_info_edit" class="has-validation-callback"
    >

      <div class="row py-2 px-2">
        <div class="col-md-12">
          <div style="min-height: 570px">
            <div class="row">

              <div class="col-lg-6">
                <div class="stage_form_header">
                  <div class="left">
                    <i
                      class="fa fa-user"
                      aria-hidden="true"
                      style="padding: 5.5px 7.5px"
                    ></i>
                  </div>
                  <div class="right">
                    <span class="main_title">Personal Information</span>
                    <span class="sub_title">
                      Fill up the form according to your national document.
                    </span>
                  </div>
                </div>

                <div class="form_part" style="background: #f5f4fd">
                  <div class="form-row">
                    <div class="form-group col-lg-6">
                      <label class="form_label">Given Name
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="given_name"
                        id="given_name"
                        placeholder="Given Name"
                        v-model="form.personal_info.given_name"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label"
                        >Family Name<span class="text-danger"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="family_name"
                        id="family_name"
                        placeholder="Family Name"
                        v-model="form.personal_info.family_name"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label"
                        >Primary Email<span class="text-danger"
                          >*</span
                        ></label
                      >
                      <input
                        readonly
                        type="email"
                        class="form-control form-control-sm"
                        name="email"
                        id="email"
                        placeholder="Email"
                        v-model="form.personal_info.email"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <div class="row">
                        <div class="col-lg-8">
                          <label class="form_label"
                            >Date of Birth<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <div class="input-group dateNicon input-group-sm">
                            <!-- <input type="text" class="form-control date2 flatpickr-input" name="dob" id="dob_edit" placeholder="Select a Date" required="" value=" 08 Sep 2000 " readonly="readonly"> -->
                            <flat-pickr
                              id="dob"
                              v-model="form.personal_info.birthday"
                              :config="configs.flatpickr"
                              class="form-control date2 flatpickr-input"
                              placeholder="Select a Date"
                            />
                            <span class="input-group-addon">
                              <span class="glyphicon glyphicon-calendar" aria-hidden="true"></span>
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-4" v-if="form.personal_info.birthday">
                          <label class="form_label"> Age </label>
                          <small style="display: block;">
                            <span id="dob_edit_year" v-html="ageCount(form.personal_info.birthday)"></span>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label"
                        >Nationality<span class="text-danger">*</span></label
                      >

                      <!-- <select id="nationality" name="nationality" class="form-control" required="" data-validation="required"> </select>-->

                      <treeselect
                        id="territory_ids"
                        :options="list.countries"
                        placeholder="Select Nationality"
                        autocomplete="off"
                        v-model="form.personal_info.nationality"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="form_label"
                        >Gender<span class="text-danger">*</span></label
                      >
                      <div class="radio_box">
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="Male"
                            v-model="form.personal_info.gender"
                          />
                          <span>Male</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="Female"
                            v-model="form.personal_info.gender"
                          />
                          <span>Female</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="stage_form_header" style="margin: 24px 0 24px;">
                  <div class="left">
                    <i
                      class="fa fa-dollar"
                      aria-hidden="true"
                      style="padding: 7px 10px"
                    ></i>
                  </div>
                  <div class="right">
                    <span class="main_title"
                      >Dependent &amp; Funding Information</span
                    >
                    <span class="sub_title">
                      Provide your study funding information.
                    </span>
                  </div>
                </div>

                <div class="form_part" style="background: #f5f4fd">
                  <div class="form-row">
                    <div class="form-group col-lg-6 has-success">
                      <label class="form_label"
                        >Marital Status<span class="text-danger ml-1"
                          >*</span
                        ></label
                      >

                      <treeselect
                        id="marital_status"
                        :options="list.marital_statuses"
                        placeholder="Choose One.."
                        autocomplete="off"
                        v-model="form.personal_info.marital_status"
                      />

                    </div>
                    <div class="form-group col-lg-6 has-success">
                      <label class="form_label"
                        >Funding Status<span class="text-danger"
                          >*</span
                        ></label
                      >

                      <treeselect
                        id="funding_status"
                        :options="list.funding_statuses"
                        placeholder="Choose One.."
                        autocomplete="off"
                        v-model="form.personal_info.funding_status"
                      />

                    </div>
                    <div class="col-lg-12 dependent" v-if="form.personal_info.marital_status && form.personal_info.marital_status!='Single'">
                      <div class="form-row">
                        <div class="form-group col-lg-6">

                          <label class="form_label">
                            Applicant Status <span class="text-danger">*</span>
                          </label>

                          <treeselect
                            id="main_applicant"
                            :options="list.applicant_statuses"
                            placeholder="Choose One.."
                            autocomplete="off"
                            v-model="form.personal_info.main_applicant"
                          />
                        </div>

                        <div class="form-group col-lg-6">
                          <label class="form_label"
                            >Number of Dependent</label
                          >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            name="no_of_dependent"
                            min="0"
                            id="dependent"
                            placeholder="Number of Dependent"
                            v-model="form.personal_info.no_of_dependent"
                          />
                        </div>

                      </div>
                    </div>
                    <div class="col-lg-12 sponsored"  v-if="form.personal_info.funding_status && form.personal_info.funding_status==1">
                      <div class="form-row">
                        <div class="form-group col-lg-6">
                          <label class="form_label"
                            >Sponsored Details</label
                          >
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            name="sponsor"
                            id="sponsor"
                            placeholder="Sponsored Details"
                            v-model="form.personal_info.sponsor"
                          />
                        </div>

                        <div class="form-group col-lg-6">
                          <label class="form_label"
                            >Sponsorship Status</label
                          >

                          <treeselect
                            id="sponsorship_status"
                            :options="list.sponsorship_statuses"
                            placeholder="Choose One.."
                            autocomplete="off"
                            v-model="form.personal_info.sponsorship_status"
                          />

                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 selffunded"   v-if="form.personal_info.funding_status && form.personal_info.funding_status==2">
                      <div class="form-row">
                        <div class="form-group col-lg-6">
                          <label class="form_label"
                            >Self Funded Details</label
                          >
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            name="selfsponsor"
                            id="selfsponsor"
                            placeholder="Self Funded Details"
                            v-model="form.personal_info.selfsponsor"
                          />
                        </div>
                        <div class="form-group col-lg-6">

                          <label class="form_label">
                            Self Funded Status
                          </label>

                          <treeselect
                            id="selfsponsorstatus"
                            :options="list.self_sponsor_statuses"
                            placeholder="Choose One.."
                            autocomplete="off"
                            v-model="form.personal_info.selfsponsorstatus"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-lg-6">
                <div class="stage_form_header">
                  <div class="left">
                    <i
                      class="fa fa-map-marker"
                      aria-hidden="true"
                      style="padding: 7px 10px"
                    ></i>
                  </div>
                  <div class="right">
                    <span class="main_title">Present &amp; Permanent Address</span>
                    <span class="sub_title">
                      Provide your mailing address.
                    </span>
                  </div>
                </div>
                <div class="form_part" style="background: #f5f4fd">
                  <div
                    style="
                      border-bottom: 0.5px dashed #e3e8ec;
                      margin-bottom: 15px;
                      padding-bottom: 5px;
                    "
                  >
                    <div class="form-row">
                      <div class="form-group col-lg-12">
                        <label
                          class="form_label"
                          style="
                            font-weight: 600;
                            font-size: 13px;
                            color: #2e1153;
                          "
                          >Present Address</label
                        >
                        <textarea
                          rows="1"
                          class="form-control"
                          id="present_address"
                          name="present_address"
                          placeholder="Present Address"
                          v-model="form.personal_info.present_address"
                        ></textarea>
                      </div>
                      <div class="form-group col-lg-6">
                        <label class="form_label">City (Present)</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          name="city"
                          id="city"
                          placeholder="City"
                          v-model="form.personal_info.city"
                        />
                      </div>
                      <div class="form-group col-lg-6">
                        <label class="form_label"
                          >Zip Code (Present)</label
                        >
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          name="zip_code_present"
                          id="zip_code_present"
                          placeholder="Zip Code"
                          v-model="form.personal_info.zip_code_present"
                        />
                      </div>
                      <div class="form-group col-lg-6">
                        <label class="form_label">Country (Present)</label>
                        <treeselect
                          id="nationality_present"
                          :options="list.countries"
                          placeholder="Select Country"
                          autocomplete="off"
                          v-model="form.personal_info.nationality_present"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-lg-12">
                      <label
                        class="form_label"
                        style="
                          font-weight: 600;
                          font-size: 13px;
                          color: #2e1153;
                        "
                        >Permanent Address</label
                      >
                      <textarea
                        rows="1"
                        class="form-control"
                        id="permanent_address"
                        name="permanent_address"
                        placeholder="Permanent Address"
                        v-model="form.personal_info.permanent_address"
                      ></textarea>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label">City (Permanent)</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="permanentcity"
                        id="permanentcity"
                        placeholder="City"
                        v-model="form.personal_info.permanentcity"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label"
                        >Zip Code (Permanent)</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="zip_code_permanent"
                        id="zip_code_permanent"
                        placeholder="Zip Code"
                        v-model="form.personal_info.zip_code_permanent"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label">Country (Permanent)</label>
                      <treeselect
                        id="nationality_permanent"
                        :options="list.countries"
                        placeholder="Select Country"
                        autocomplete="off"
                        v-model="form.personal_info.nationality_permanent"
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="row"> 

              <div class="col text-right mt-2"
              >
                <button
                 @click="formClose()"
                  class="btn cancel_button mr-1 btn-cancel"
                  title="Refresh"
                  style="padding: 4px 8px !important;"
                >
                  <i class="fa fa-close" aria-hidden="true"></i>
                </button>

                <button class="btn submit_button submit_button_save" v-on:click="submit_personal_info()">
                  <span v-if="flag.step_completed">Save</span>
                  <span v-else> Save & Complete</span>
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!--.row-->

      <hr style="margin: 0; border-top: 25px solid #f2f3f5" />
    </div>
  </section>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import moment from 'moment';

export default {
  name: "PersonalInfo",
  components: {
    Treeselect,
  },
  data() {
    return {
      list: {
        countries: [],
        marital_statuses: [],
        funding_statuses: [],
        applicant_statuses:[],
        sponsorship_statuses:[],
        self_sponsor_statuses:[]
      },
      form: {
        personal_info: {
          family_name: "",
          given_name: "",
          nationality: null,
          birthday: "",
          email: "",
          gender: "Male",

          marital_status: null,
          funding_status: null,
          main_applicant: null,
          no_of_dependent: null,
          sponsorship_status: null,
          selfsponsor: null,
          selfsponsorstatus: null,

          present_address:'',
          city:'',
          zip_code_present:'',
          nationality_present: null,

          permanent_address:'',
          permanentcity:'',
          zip_code_permanent:'',
          nationality_permanent: null
        },
      },
      configs: {
        flatpickr: {
          dateFormat: "d M Y",
          maxDate: 'today'
        },
      },
      flag:{
        step_completed: false
      }
    };
  },
  methods: {
    formClose: function () {
        this.$emit('stage-close')
    },
    fetch_personal_info: async function () {

      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v2/student/ajax/full_profile_stage_personal_info");

      try {

        var res = await jq.get(url);

        ref.list.countries = res.data.countries;
        ref.list.marital_statuses = res.data.marital_statuses;
        ref.list.funding_statuses = res.data.funding_statuses;
        ref.list.applicant_statuses = res.data.applicant_statuses;
        ref.list.sponsorship_statuses = res.data.sponsorship_statuses;
        ref.list.self_sponsor_statuses = res.data.self_sponsor_statuses;
        ref.flag.step_completed = res.data.step_completed;

        ref.populate_p_info_form(res.data.lead);

      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }

    },
    populate_p_info_form: function (lead) {
      var form = this.form.personal_info;
      form.family_name = lead.family_name;
      form.given_name = lead.given_name;
      form.email = this.cn(lead, "lead_contact.email", "");
      form.birthday = lead.birthday ? moment(lead.birthday).format("DD MMM YYYY") : '';
      form.nationality = lead.nationalities_id;
      form.gender = lead.gender;

      form.marital_status=lead.marital_status;
      form.funding_status=lead.funding_status;
      form.main_applicant=lead.main_applicant;
      form.no_of_dependent=lead.no_of_dependent;
      form.sponsor=lead.sponsor;
      form.sponsorship_status=lead.sponsorship_status;
      form.selfsponsor=lead.selfsponsor;
      form.selfsponsorstatus=lead.selfsponsorstatus;

      form.present_address = lead.present_address;
      form.city = lead.city;
      form.zip_code_present = lead.zip_code_present;
      const contryPresentObj = this.list.countries.find(country => country.name == lead.nationality_present)
      if (contryPresentObj) {
        form.nationality_present = contryPresentObj.id;
      }

      form.permanent_address = lead.permanent_address;
      form.permanentcity = lead.permanentcity;
      form.zip_code_permanent = lead.zip_code_permanent;
      
      const contryPermanentObj = this.list.countries.find(country => country.name == lead.nationality_permanent)
      if (contryPermanentObj) {
        form.nationality_permanent = contryPermanentObj.id;
      }

    },
    submit_personal_info: async function(){

      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v2/student/ajax/save_full_profile_stage_personal_info");

      var form_data=ref.form.personal_info;

      try {

        ref.wait_me('#form_lead_personal_info_edit');
        var res = await jq.post(url, form_data);
        ref.alert(res.msg);
        ref.fetch_personal_info();

        if(!ref.flag.step_completed){
          this.$emit("save-and-completed", true);
        }

        this.$emit('dashboard-info-update', true)

      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }finally{
        ref.wait_me('#form_lead_personal_info_edit', 'hide');
      }

    }
  },
  created: function () {
    this.setup_urls();
    this.fetch_personal_info();
  },
};
</script>

<style scoped>
  .flatpickr-input {
    min-height: 29px;
}

</style>
