<template>
  <section>
      
    <student-full-profile-stages :studentAllInfo="studentAllInfo" v-on:get-dashboard-data="getDashBoardUpdatedData()"></student-full-profile-stages>


    <!-- <div 
      v-if="studentAllInfo.lead && (studentAllInfo.lead.lead_educational_history.length || studentAllInfo.lead.lead_previous_studies.length ||
      studentAllInfo.lead.lead_english_test.length || studentAllInfo.lead.lead_work_history.length || studentAllInfo.lead.references.length ||
      studentAllInfo.lead.passports.length || studentAllInfo.lead.lead_visa_history.length || studentAllInfo.lead.lead_visa_refusal.length)"
    class="card"> -->
    <div class="card" v-if="studentAllInfo.lead && (studentAllInfo.lead.lead_educational_history.length || studentAllInfo.lead.lead_previous_studies.length || studentAllInfo.lead.english_tests.length || studentAllInfo.lead.lead_work_history.length || studentAllInfo.lead.references.length || studentAllInfo.lead.passports.length || studentAllInfo.lead.lead_visa_history.length || studentAllInfo.lead.lead_visa_refusal.length || studentAllInfo.educational_documents.length || studentAllInfo.work_history_documents.length || studentAllInfo.english_test_documents.length || studentAllInfo.reference_documents.length || studentAllInfo.visa_history_documents.length || studentAllInfo.lead.lead_additional_files.length)">

      <div class="card-body p-0">
        
        <div class="tabs-section-nav tabs-section-nav-left remarks-tab">
          <ul class="nav" role="tablist">
            <li v-if="(studentAllInfo.student && studentAllInfo.student.steps_submitted_at) || (studentAllInfo.lead && studentAllInfo.lead.file_number)" class="nav-item" @click="setActive(0)">
            <!-- <li class="nav-item" @click="setActive(0)"> -->
              <a class="nav-link color_blue" :class="active_menu.index == 0 ? 'active' : ''">
                <span class="nav-link-in">
                  <i class="fa fa-university"></i>
                  <small>Application</small>
                </span>
              </a>
            </li>

            <!-- <li 
              v-if="(studentAllInfo.lead && (studentAllInfo.lead.lead_educational_history.length || studentAllInfo.lead.lead_previous_studies.length ||
              studentAllInfo.lead.lead_english_test.length || studentAllInfo.lead.lead_work_history.length || studentAllInfo.lead.references.length ||
              studentAllInfo.lead.passports.length || studentAllInfo.lead.lead_visa_history.length || studentAllInfo.lead.lead_visa_refusal.length)) || (studentAllInfo.educational_documents.length || studentAllInfo.work_history_documents.length ||
              studentAllInfo.reference_documents.length || studentAllInfo.visa_history_documents.length)"
              class="nav-item" @click="setActive(1)"> -->
           <li class="nav-item" @click="setActive(1)" v-if="studentAllInfo.lead.lead_educational_history.length || studentAllInfo.lead.lead_previous_studies.length || studentAllInfo.lead.english_tests.length || studentAllInfo.lead.lead_work_history.length || studentAllInfo.lead.references.length || studentAllInfo.lead.passports.length || studentAllInfo.lead.lead_visa_history.length || studentAllInfo.lead.lead_visa_refusal.length">
              <a class="nav-link color_blue" :class="active_menu.index == 1 ? 'active' : ''">
                <span class="nav-link-in load_profile_all_data">
                  <i class="fa fa-graduation-cap"></i>
                  <small>Profile</small>
                </span>
              </a>
            </li>
            <!-- <li 
              v-if="studentAllInfo.educational_documents.length || studentAllInfo.work_history_documents.length ||
              studentAllInfo.reference_documents.length || studentAllInfo.visa_history_documents.length" 
              class="nav-item" @click="setActive(2)"> -->
            <li class="nav-item" @click="setActive(2)" v-if="studentAllInfo.educational_documents.length || studentAllInfo.work_history_documents.length || studentAllInfo.english_test_documents.length || studentAllInfo.reference_documents.length || studentAllInfo.visa_history_documents.length || studentAllInfo.lead.lead_additional_files.length">
              <a class="nav-link color_blue" :class="active_menu.index == 2 ? 'active' : ''">
                <span class="nav-link-in">
                  <i class="fa fa-folder-open-o"></i>
                  <small>Documents</small>
                </span>
              </a>
            </li>

            <!-- <li class="nav-item" @click="setActive(3)">
              <a class="nav-link color_blue" :class="active_menu.index == 3 ? 'active' : ''">
                <span class="nav-link-in">
                  <i class="fa fa-folder"></i>
                  <small>Enrollment Doc</small>
                </span>
              </a>
            </li> -->

            <li class="nav-item" @click="setActive(4)" v-if="studentAllInfo.pendingRequirementsCount" >
              <a class="nav-link color_blue" :class="active_menu.index == 4 ? 'active' : ''">
                <span class="nav-link-in">
                  <!-- <i class="fa fa-list-ul mr-1" style="color: #FFC107 !important"></i> -->
                  <i class="fa fa-list-ul mr-1" style="color: #ff7f00 !important;"></i>
                  <i v-if="studentAllInfo.pendingRequirementsCount" class="fa fa-circle text-success" style="font-size: 14px; position: absolute; margin-left: 49px; margin-top: -4px;"></i>
                  <span v-if="studentAllInfo.pendingRequirementsCount" v-html="studentAllInfo.pendingRequirementsCount" style="position: absolute; margin-left: 52px; margin-top: -11px; color: #fff; font-size: 10px;"></span>
                  <small style="width: 100px;color: #ff7f00 !important;background: #dedbf1 !important;">Action Required</small>
                </span>
              </a>
            </li>

            <!-- <li class="nav-item" @click="setActive(5)">
              <a class="nav-link color_blue" :class="active_menu.index == 5 ? 'active' : ''">
                <span class="nav-link-in">
                  <i class="fa fa-tasks"></i>
                  <small>Tasks</small>
                </span>
              </a>
            </li> -->
          </ul>
        </div>


        <div class="tab_content_components">
            <application-content :studentAllInfo="studentAllInfo" v-show="active_menu.index==0" :base_url="base_url" :api_token="api_token" ref="application_content" v-on:get-dashboard-data="getDashBoardUpdatedData()"  v-on:get-enrollment-requirement-list-modal="getEnrollmentRequimentListModal" />
            <profile-content :studentAllInfo="studentAllInfo" v-show="active_menu.index==1" :base_url="base_url" :api_token="api_token" ref="profile_content" v-on:get-dashboard-data="getDashBoardUpdatedData()"/>
            <documents-content :studentAllInfo="studentAllInfo" v-show="active_menu.index==2" :base_url="base_url" :api_token="api_token" ref="documents_content" v-on:get-dashboard-data="getDashBoardUpdatedData()"/>
            <!-- <enrollment-doc-content :studentAllInfo="studentAllInfo" v-show="active_menu.index==3" :base_url="base_url" :api_token="api_token" ref="enrollment_content"/> -->
            <requirements-content :studentAllInfo="studentAllInfo" v-show="active_menu.index==4" :base_url="base_url" :api_token="api_token" ref="requirements_content" v-on:get-dashboard-data="getDashBoardUpdatedData()" v-on:open-file-view="global_viewFile"/>
            <!-- <tasks-content v-show="active_menu.index==5" :base_url="base_url" :api_token="api_token" ref="tasks_content"/> -->
        </div>

      </div>

      <div :class="global_imageViewerImageUrl ? 'image-viewer-open' : 'image-viewer'">
          <div class="row">
            <div class="col-md-12 text-right">
                <div style="width: 100%; position: relative; display: inline;">
                    <span class="fa fa-times pdfclose" @click="global_closeViewFile()"></span>
                    <iframe :src="global_imageViewerImageUrl" style="width: 91%; height: 90vh; background: #f2f3f5;"></iframe>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div v-else-if="studentAllInfo.pendingRequirementsCount" class="card">
      <div class="card-body p-0">
        <requirements-content :studentAllInfo="studentAllInfo" :base_url="base_url" :api_token="api_token" ref="requirements_content" v-on:get-dashboard-data="getDashBoardUpdatedData()" v-on:open-file-view="global_viewFile"/>
      </div>
    </div>
  </section>
</template>

<script>
import feather from "feather-icons";
import ApplicationContent from './ApplicationContent.vue';
import ProfileContent from './ProfileContent.vue';
import DocumentsContent from './DocumentsContent.vue';
// import EnrollmentDocContent from './EnrollmentDocContent.vue';
import RequirementsContent from './RequirementsContent.vue';
// import TasksContent from './TasksContent.vue';
// import VueApexCharts from 'vue-apexcharts';
// import { BDropdown, BDropdownItem } from 'bootstrap-vue';
// import Treeselect from '@riophae/vue-treeselect';

import StudentFullProfileStages from '../../src/components/SamsStudentPortal/StudentFullProfileStages.vue';

export default {
  name: "MainBodyContent",
  props: ['studentInfo', 'studentAllInfo'],
  components: {
    ApplicationContent,
    ProfileContent,
    DocumentsContent,
    StudentFullProfileStages,
    // EnrollmentDocContent,
    RequirementsContent,
    // TasksContent
    // VueApexCharts,
    // BDropdown, BDropdownItem,
    // Treeselect
  },
  data: () => {
    return {
        flag: {
            show_filter: false,
        },
        search: {
            intake_id: null,
        },
        active_menu: {
            index: 0,
        },
        enrollmentId: '',
        global_imageViewerImageUrl: ''
    };
  },
  computed: {
  },
  watch: {},
  methods: {
    getEnrollmentRequimentListModal: function (event) {
      this.$refs.requirements_content.openEnrollmentRequimentListModalByEnrollmentId(event.enrollmentId)
    },
    global_viewFile: function (event) {
      this.global_imageViewerImageUrl = event.file_url
    },
    getDashBoardUpdatedData: function () {
      this.$emit('get_student-data', true)
    },
    setActive: function (menuItem) {
        this.active_menu.index = menuItem
    },
    toggle_filter: function() {
      this.flag.show_filter = !this.flag.show_filter;
    },
    
    global_closeViewFile: function() {
      this.global_imageViewerImageUrl = ''
    }
    // dashboard_init_data: async function(){

    // 	var ref=this;
    // 	var jq=ref.jq();
    // 	var url=ref.url('api/v1/university/ajax/dashboard_init_data');

    // 	try{
    // 		const params = { intake_id: intake_id}
    // 		var res=await jq.get(url, params);
    // 		ref.info.total_agents=res.data.total_agents;
    // 	}catch(error){
    // 		console.log(error);
    // 	}

    // },
  },
  created: function() {
    this.setup_urls();
  },
  mounted: function() {
    feather.replace();
    // this.dashboard_init_data();
  },
};
</script>

<style>
.tabs-section-nav {
  border-top: unset;
}
.tabs-section-nav {
  border-top: unset;
}
.tabs-section-nav {
  border-top: unset;
}
.tabs-section-nav {
  border-top: unset;
}
.remarks-tab {
  background-color: #f2f3f5 !important;
}
.tabs-section-nav {
  border-color: #fff;
  overflow: hidden;
  border: none;
}
.tabs-section-nav {
  border-top: unset;
}
.tabs-section-nav {
  overflow: auto;
  width: 100%;
  text-align: center;
  font-size: 15px;
  border-top: solid 1px #d8e2e7;
}

.tabs-section-nav.tabs-section-nav-left .nav {
  padding: 10px;
  background-color: #f6f5fe;
  border-top: 1px solid #d8e2e7;
  border-bottom: 1px solid #d8e2e7;
}
.tabs-section-nav.tabs-section-nav-left .nav {
  padding: 15px 0;
}

.tabs-section-nav.tabs-section-nav-left .nav {
  border: none;
}
.tabs-section-nav.tabs-section-nav-left .nav {
  background-color: #f6f8fa;
  zoom: 1;
  border: solid 1px #d8e2e7;
  border-left: none;
  border-top: none;
}
/*.tabs-section-nav.tabs-section-nav-left .nav-item {
  width: 90px;
  margin-right: 10px;
}*/
.tabs-section-nav.tabs-section-nav-left .nav-item {
  margin-bottom: -1px;
}
.tabs-section-nav .nav-item {
  background: transparent !important;
}
.tabs-section-nav .nav-item {
  background-color: #f6f8fa;
}
.tabs-section-nav .nav-item {
  float: left;
  background: #f6f8fa;
  white-space: nowrap;
  padding: 0;
}
.tabs-section-nav .nav-item:first-child .nav-link {
  border-left-color: #d8e2e7;
}
.tabs-section-nav.tabs-section-nav-left .nav-link,
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  border-radius: 0 !important;
  background: transparent !important;
}
.tabs-section-nav.tabs-section-nav-left .nav-link,
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  border-radius: 5px;
}
.tabs-section-nav.tabs-section-nav-left .nav-link,
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  border: none;
}
.tabs-section-nav.tabs-section-nav-left .nav-link,
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  -webkit-transition: none;
  transition: none;
}
.tabs-section-nav .nav-link.active {
  border-left-color: #d8e2e7;
  border-right-color: #d8e2e7;
  background: #fff;
  color: #343434;
  border-bottom-color: #fff;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  border-bottom: solid #e3e8ec;
  color: #00a8ff;
  border-width: 2px;
  border-top: none;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  border-bottom: solid #e3e8ec;
  color: #00a8ff;
  border-width: 2px;
  border-top: none;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  border-bottom: solid #e3e8ec;
  color: #00a8ff;
  border-width: 2px;
  border-top: none;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  border-bottom: solid #e3e8ec;
  color: #00a8ff;
  border-width: 2px;
  border-top: none;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  padding-bottom: 8px;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  border-bottom: none !important;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  color: #00a8ff;
  border-top: none;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  border-bottom: solid #e3e8ec;
  color: #00a8ff;
  border-width: 2px;
  border-top: none;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  border-top-color: #00a8ff;
}
.tabs-section-nav.tabs-section-nav-left .nav-link,
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  border-radius: 0 !important;
  background: transparent !important;
}
.remarks-tab .active .nav-link-in {
  background: #dedbf1;
}
.tabs-section-nav.tabs-section-nav-left .nav-link,
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  border-radius: 5px;
}
.remarks-tab .active .nav-link-in {
  background-color: #f2f3f5;
}
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  padding-left: 18px;
  padding-right: 18px;
}
.tabs-section-nav.tabs-section-nav-left .nav-link,
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  border: none;
}
.tabs-section-nav.tabs-section-nav-left .nav-link,
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  -webkit-transition: none;
  transition: none;
}
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
  padding-right: 15px;
  padding-left: 15px;
}
.tabs-section-nav .nav-link-in {
  padding: 5px;
}
.tabs-section-nav .nav-link-in {
  line-height: 14px;
}
.tabs-section-nav .nav-link-in {
  padding: 5px;
}
.tabs-section-nav .nav-link-in {
  line-height: 14px;
}
.tabs-section-nav .nav-link-in {
  padding: 5px;
}
.tabs-section-nav .nav-link-in {
  line-height: 14px;
}
.tabs-section-nav .nav-link-in {
  padding: 5px;
}
.tabs-section-nav .nav-link-in {
  line-height: 14px;
}
.remarks-tab .nav-link-in {
  background-color: #f6f8fa;
}
.tabs-section-nav .nav-link-in {
  padding: 5px;
}
.tabs-section-nav .nav-link-in {
  line-height: 14px;
}
.tabs-section-nav .nav-link-in {
  display: block;
  padding: 10px;
  line-height: 28px;
  border-top: solid 4px transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.nav-link-in {
  background: #f6f8fa;
  color: #9e9fa9;
}
.nav-link-in {
  padding: none !important;
}
.nav-link-in {
  background-color: gainsboro;
  display: grid !important;
}
.tabs-section-nav .nav-link .nav-link-in small {
  background: #dedbf1 !important;
  border-radius: 20px;
  padding: 3px 10px;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.2px;
  width: 90px;
  margin-top: 4px;
  color: #2e1153 !important;
}
.tabs-section-nav a.color_blue .nav-link-in small,
.tabs-section-nav a.color_blue .nav-link-in .fa,
.tabs-section-nav a.color_blue .nav-link-in .font-icon {
  color: #625f8b;
}

.remarks-tab .nav-item .nav-link.active .nav-link-in i {
  color: #2e1153 !important;
}
.tabs-section-nav .nav-link.active .nav-link-in small {
  background: #2e1153 !important;
  color: #fff !important;
}

/*New popup*/
.bottom_popup_enroll_activity_list {
  background: #fff;
  padding: 16px 20px;
  height: 97.5vh;
}
.bottom_popup_enroll_activity_list .close_button_enroll_activity_list {
  background: #2e1153;
  padding: 6px 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  cursor: pointer;
  color: #fff;
  top: 25px;
  left: -40px;
  position: absolute;

  font-size: 0.8rem;
}
/*New popup*/

.custom_popup_enroll_activity_list {
  overflow: hidden;
  position: fixed;
  top: 62px;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  opacity: 0;
  -webkit-transition: opacity 0.15s linear, z-index 0.15;
  -o-transition: opacity 0.15s linear, z-index 0.15;
  transition: opacity 0.15s linear, z-index 0.15;
  z-index: -1;
  overflow-x: hidden;
  overflow-y: auto;
}

.popup_open_task_details {
  /*z-index: 99999;*/

  z-index: 999;
  opacity: 1;
  overflow: hidden;
}
.custom_popup_inner_enroll_activity_list {
  /*-webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);*/
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  display: inline-block;
  vertical-align: middle;
  width: 490px;
  /*margin: 30px auto;
  max-width: 97%;*/
  right: 0;
  position: absolute !important;
}
.popup_open_task_details .custom_popup_inner_enroll_activity_list {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  position: relative;
  z-index: 999;
}
.popup_open_task_details .popup_open_bg_overlay_enroll_activity_list {
  background: rgba(0, 0, 0, 0.6);
  /*background: rgb(255 250 250 / 60%);*/
  z-index: 99;
}
.popup_open_bg_overlay_enroll_activity_list {
  background: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 63px !important;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transition: background 0.15s linear;
  -o-transition: background 0.15s linear;
  transition: background 0.15s linear;
}

.bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .action_dropdown
  .dropdown-toggle {
  background: #dedbf1;
  padding: 4px 8px 2px;
  border-radius: 50%;
  font-size: 0.8rem;
  border: 1px solid transparent;
  color: #2e1153;
  cursor: pointer;
}
.bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .action_dropdown
  .dropdown-toggle:hover {
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  border: 1px solid #ff7f00;
}
.bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .action_dropdown
  .dropdown-toggle:after {
  display: none;
}
.bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .action_dropdown
  .dropdown-menu.show {
  margin-left: -55px;
  min-width: auto;
  padding: 0;
  margin-top: 3px;
}
.bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .action_dropdown
  .dropdown-menu.show
  .dropdown-item {
  background: #dedbf1;
  color: #2e1153;
  font-size: 12px;
  border: none;
}
.bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .action_dropdown
  .dropdown-menu.show
  .dropdown-item:hover {
  background: #2e1153;
  color: #fff !important;
}
.bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .action_dropdown
  .dropdown-menu.show
  .activate {
  background: #2e1153;
  color: #fff;
}
.bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .action_dropdown
  .dropdown-menu.show
  .dropdown-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .action_dropdown
  .dropdown-menu.show
  .dropdown-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*Notification list design*/
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item_header {
  background: #dedbf1;
  padding: 7px 16px;
  margin-bottom: 15px;
  border-radius: 30px;
  position: relative;
  font-size: 12px;
  font-weight: 700;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item {
  display: flex;
  background: #fff;
  padding: 7px 16px;
  margin-top: 10px;
  border-radius: 30px;
  border: 1px solid #e9f0f3;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item:hover {
  background: #f5f4fd;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .left {
  width: calc(100% - 50px);
  align-self: center;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item
  .right {
  width: 50px;
  align-self: center;
  text-align: -webkit-right;
}
/*Notification list design*/

.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_item {
  display: flex;
  background: fff;
  padding: 7px 16px;
  margin-top: 10px;
  border-radius: 30px;
  border: 1px solid #e9f0f3;
  align-items: center;
  font-size: 13px;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_item:hover {
  background: #f5f4fd;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_item
  strong {
  color: rgb(57, 59, 62);
  font-size: 12px;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_item
  .fa {
  background: #f1f0fb;
  padding: 5px 6px;
  border-radius: 50%;
  border: 1px solid #ceccda;
  margin: 0 5px 0 -5px;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_item
  a {
  font-size: 12px;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_item
  span
  small {
  color: rgb(134, 142, 150);
  font-size: 12px !important;
  /*margin: 0 4px;*/
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_item:first-child {
  margin-top: 5px;
}

/*.custom_popup_enroll_activity_list .custom_popup_inner_enroll_activity_list .bottom_popup_enroll_activity_list .details_content_view{
    height: calc(100vh - 65px);
    position: relative;
}*/
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .submit_button {
  position: fixed;
  right: 25px;
  bottom: 65px;
}
.custom_popup_enroll_activity_list
  .custom_popup_inner_enroll_activity_list
  .bottom_popup_enroll_activity_list
  .details_content_view
  .single_radius_list_item_header
  .list_counter {
  background: #ffffff;
  font-weight: 700;
  font-size: 11px !important;
  border-radius: 10px;
  padding: 3px 12px !important;
  border: 1px solid #d4d2df;
  color: #2e1153;
  /* top: -1px; */
  /* position: relative; */
  float: right;
  margin-top: -1px;
}
.remark_crm_urm .card {
  border: 1px solid #dedbf1;
  padding: 16px;
}
.remark_crm_urm .radius_content {
  height: 219px;
  position: relative;
}
.remark_crm_urm .title_radius {
  background: #dedbf1;
  padding: 7px 16px;
  margin-top: 10px;
  border-radius: 30px;
  position: relative;
  font-size: 12px;
  font-weight: 700;
  width: inherit;
  left: 0;
  top: -10px;
}
.remark_crm_urm .radius_content .no_info_msg {
  text-align: center;
  margin: 0px;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}
.remark_crm_urm .single_radius_item {
  padding: 7px 0;
  margin-top: 10px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #e9f0f3;
  display: flex;
  width: 100%;
}
/*.remark_crm_urm .single_radius_item:hover {
    background: #f5f4fd;
}*/
.remark_crm_urm .single_radius_item:first-child {
  margin-top: 0;
}
.remark_crm_urm .single_radius_item:last-child {
  border-bottom: none;
}
.remark_crm_urm .single_radius_item strong {
  color: rgb(57, 59, 62);
  font-size: 12px;
}
.remark_crm_urm .single_radius_item .left {
  width: calc(100% - 25px);
}
.remark_crm_urm .single_radius_item .right {
  width: 25px;
  align-self: center;
}
.list_table .institute_info .left .fa {
  background: #f1f0fb;
  padding: 10px;
  border-radius: 100%;
  font-size: 14px;
  color: #2e1153;
}

.list_table .institute_info {
  display: flex;
}

.list_table .institute_details {
  display: flex;
  width: 100%;
}
.list_table .institute_details .left {
  width: 25px;
}
.list_table .institute_details .right {
  width: calc(100% - 25px);
}
.list_table .institute_details .left img {
  height: 20px;
  width: 20px;
  position: relative;
  top: 8px;
}

/*.enrollment-box  */
.box-typical-header .add_more_drop .dropdown-toggle:after {
  display: none;
}
/*.documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle{
        color: #c3c3c3;
        cursor: pointer;
        border: .5px solid #d8e2e7;
        border-radius: 50%;
        padding: 3px 5px;
        font-size: .9rem;
        padding: 3px 5px;
    }*/
.box-typical-header .add_more_drop .dropdown-menu.show {
  /*margin-left: -97px;*/
  min-width: auto;
  padding: 0;

  z-index: 2;
  top: -5px !important;
  left: 60px !important;
}
.box-typical-header .add_more_drop .dropdown-menu.show .dropdown-item {
  background: #dedbf1;
  color: #2e1153;
  font-size: 12px;
  border: none;
}
.box-typical-header .add_more_drop .dropdown-menu.show .dropdown-item:hover {
  background: #2e1153;
  color: #fff !important;
}
.box-typical-header .add_more_drop .dropdown-menu.show .activate {
  background: #2e1153;
  color: #fff;
}
.box-typical-header
  .add_more_drop
  .dropdown-menu.show
  .dropdown-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.box-typical-header
  .add_more_drop
  .dropdown-menu.show
  .dropdown-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.box-typical-header .add_more_drop .dropdown-toggle {
  background: #dedbf1;
  padding: 4px 8px 2px;
  border-radius: 50%;
  font-size: 0.8rem;
  border: 1px solid transparent;
  color: #2e1153;
  cursor: pointer;
}
.box-typical-header .add_more_drop .dropdown-toggle:hover {
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  border: 1px solid #ff7f00;
}
.box-typical-header .add_more_drop {
  z-index: auto !important;
}
.box-typical-header .add_more_drop {
  margin: 0 4px;
}
.box-typical-header .add_more_drop:last-child {
  margin-right: 0;
}

.enrollment_third_row_icons {
  color: #868e96;
  font-size: 16px;
  line-height: 1.4;
  margin: 0 4px;
}
.enrollment_third_row_icons:hover {
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.my_list .dropdown-toggle,
.my_list .btn.dropdown-toggle {
  padding: 3px 5px !important;
}
.box-typical-header {
  border: none;
}
.box_2nd {
  border-bottom-color: #fff !important;
  margin: 3px 5px;
}
#enrollment_action_btn .show {
  top: 5px !important;
}
#course_verification_btn .show {
  top: 5px !important;
}
.add_child_enrollment:hover {
  color: #00a8ff !important;
}
.crate_chain:hover {
  color: #00a8ff !important;
}
.delete_enroll_btn:hover {
  color: #00a8ff !important;
}

.enrollment_tab .tab-pane {
  height: 149px !important;
}

.enroll-appointment:hover,
.enroll-notify:hover,
.enroll-task:hover,
.call-start:hover {
  color: #00a8ff;
}
.box_2nd {
  margin: 0px;
}
.icon-box {
  padding: 0px;
}
.lg_icon {
  font-size: 14px;
}
.tabs-section-nav .nav-link.active .nav-link-in {
  padding-bottom: 8px;
}
.collapsed_div:after {
  font-family: "FontAwesome";
  content: "\f068";
  float: left;
  background: gray;
  color: #fff;
  font-size: 10px;
  border-radius: 50%;
  padding: 0px 3px;
  margin-top: 3px;
  margin-right: 5px;
}
.collapsed_div.collapsed:after {
  content: "\f067";
}

#add_progression_div {
  position: relative;
  margin-bottom: 25px;
  margin-top: 14px;
  /*margin-left: 7px;*/
  z-index: 1;
}
#add_progression_div_title {
  padding: 6px 12px;
  border: 1px solid lightgray;
  z-index: 1;
  background: #fff;
  border-radius: 3px;
}

#add_progression_div_line {
  border-bottom: 1px solid lightgray;
  width: 100%;
  position: ;
  margin-top: -10px;
  overflow: hidden;
  z-index: -3;
}

/*.add_req{
        color: #FFC107;
    }*/
.add_req:hover,
.app_req:hover {
  color: #00a8ff !important;
}

.action_btn {
  /* position: absolute;*/
  top: -2px;
  background: #fff;
  border: 1px solid #2e58a6;
  padding: 3.3px 0px 1px 0px;
  z-index: 1;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
  right: 25px;
  height: 20px;
  width: 20px;
}
.action_btn .fa-pencil {
  font-size: 10px;
}
.email_btn {
  position: absolute;
  right: 5px;
  top: 3px;
  background: #fff;
  border: 1px solid #2e58a6;
  padding: 3px 0px 0px 0px;
  z-index: 1;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.create_comms_btn {
  position: absolute;
  right: 35px;
  top: 3px;
  background: #fff;
  border: 1px solid #2e58a6;
  padding: 1.5px 0px 1px 0px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.create_comms_btn .glyphicon-transfer {
  font-size: 11px !important;
  top: -2px;
}
.doc_btn {
  position: absolute;
  right: 54px;
  top: 5px;
  background: #fff;
  border: 1px solid #2e58a6;
  padding: 0.1px 0px 0px 4px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.req_btn {
  position: absolute;
  right: 75px;
  top: 5px;
  background: #fff;
  border: 1px solid #2e58a6;
  padding: 0px 0px 0px 3.5px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.action_btn:hover,
.email_btn:hover,
.create_comms_btn:hover,
.doc_btn:hover,
.req_btn:hover {
  background: #2e58a6;
}
.req_btn .fa-warning:hover {
  /*color: #fff !important;*/
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.req_btn .fa-warning {
  color: #ffc107 !important;
}
.doc_btn .lg_icon_active:hover {
  /*color: #fff !important;*/
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.doc_btn .lg_icon_active {
  color: #00a8ff !important;
}
.doc_btn .lg_icon:hover {
  /*color: #fff !important;*/
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.doc_btn .lg_icon {
  color: #adb7be !important;
}
.create_comms_btn .glyphicon-transfer:hover {
  /*color: #fff !important;*/
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.action_btn .fa-pencil:hover {
  /*color: #fff !important;*/
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.email_btn .fa-envelope:hover {
  /*color: #fff !important;*/
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.dropdown.dropdown-typical a.dropdown-toggle:after {
  display: none;
}

#enroll_badge {
  border-color: transparent;
  background-color: rgba(11, 1, 70, 0.2);
  color: rgba(11, 1, 70, 1);
  padding: 0.5rem 0.7rem !important;
  border-radius: 30px;
  font-size: 14px !important;
}

.action_btn .fa-plus:hover {
  /*color: #fff !important;*/
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.action_btn .fa-list-ul:hover {
  color: #fff !important;
}

.action_btn .fa-plus {
  color: #625f8b !important;
}
.doc_btn .lg_icon_active {
  color: #625f8b !important;
}
.create_comms_btn .glyphicon-transfer {
  color: #625f8b !important;
}
.action_btn .fa-pencil {
  color: #625f8b !important;
}
.email_btn .fa-envelope {
  color: #625f8b !important;
}
#add_progression_div_title {
  padding: 6px 12px;
  border: 1px solid lightgray;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(183, 180, 212, 1) 0%,
    rgba(183, 180, 212, 1) 0%,
    #fff 75%
  );
  border-radius: 3px;
}
.verrify_popup_edit_btn {
  float: right;
  cursor: pointer;
}

/*Circle button*/
/*.circle_btn_box{
        display: inline-flex;
    }
    .circle_btn {
        width: 20px;
        height: 20px;
        color: #625f8b;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin: 0 5px;
        position: relative;
        background: #fff;
        border: 1px solid #2e58a6;
    }
    .circle_btn .fa {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 4.5px;
        font-size: .6rem;
    }
    .circle_btn:hover {
        background: #2e58a6;
        color: #f79322 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #f79322;
    }
    .circle_dot_btn{
        border: none;
        color: red;
    }
    .circle_dot_btn:hover{
        background: #fff;
        color: red !important;
        border: none;
    }
    .square_p_btn div {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 32%;
        font-size: .7rem;
        font-weight: 700;
    }
    .square_p_btn{
        border: none;
        color: #fff;
        background: #00a8ff;
        border-radius: .25rem;
    }
    .square_p_btn:hover{
        background: #00a8ff;
        border: none;
        color: #fff !important;
    }
    .circle_drop_btn .dropdown-toggle::after{
        display: none;
    }
    .circle_drop_btn .dropdown-menu.show{
        text-align-last: start;
    }*/
/*Circle button*/

/* New */
.content_header {
  display: flex;
  width: 100%;
  line-height: 1.2;
  margin-left: 13px;
}

.content_header .left {
  width: 40px;
  align-self: center;
  margin-top: -3px;
}

.content_header .left .fa,
.content_header .left .bx,
.content_header .left .font-icon {
  padding: 7px 6px;
  background: #f1f0fb;
  border-color: transparent;
  color: #2e1153;
  border-radius: 100%;
  font-size: 14px;
  border: 1px solid #ceccda;
}

.content_header .right .main_title {
  font-size: 18px;
  font-weight: 600;
  display: block;
  /*color: #2e1153;*/
}

.sams_activity_scroll {
  overflow: auto;
}
.sams_activity_scroll::-webkit-scrollbar {
  width: 7px;
}
.sams_activity_scroll::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
}
.sams_activity_scroll::-webkit-scrollbar-thumb {
  /*background: #00a8ff;*/
  background: linear-gradient(
    90deg,
    rgba(183, 180, 212, 1) 0%,
    rgb(165 164 181) 0%,
    #fff 75%
  );
  border-radius: 5px;
}

.enroll_right_btns .action_dropdown .fa {
  color: #2e1153;
}
.action_dropdown {
    display: inline;
}
.action_dropdown .dropdown-toggle {
    background: #dedbf1;
    padding: 4px 8px;
    border-radius: 50%;
    font-size: .63rem;
    border: 1px solid transparent;
    color: #2e1153;
    cursor: pointer;
}
.tabs-section-nav.tabs-section-nav-left .nav {
    padding: 10px;
}
.tabs-section-nav .nav{
  background: #fff !important;
  border-right: none !important;
}
.tabs-section-nav.tabs-section-nav-left .nav-link{
  padding: 0;
}
.tabs-section-nav.tabs-section-nav-left .nav-link-in{
  padding: 7px 10px 7px 10px !important;
}
    
</style>
