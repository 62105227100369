<template>
<section class="personal-info">


   <div class="row py-2 px-2">
      <div class="col-md-12">
         <div style="min-height: 570px;">
            <div class="row">
               <div class="col-lg-6">
                  <div class="stage_form_header stage_form_header_with_yes_no_radio">
                     <div class="left">
                        <i class="fa fa-info" aria-hidden="true" style="padding: 6px 11px;"></i>
                     </div>
                     <div class="right">
                        <div class="with_yes_no_radio_inner">
                           <div class="with_yes_no_radio_inner_left">
                              <span class="main_title">Do You have any Reference?</span>
                           </div>
                           <div class="with_yes_no_radio_inner_right">
                              <div class="radio_box" style="place-content: flex-end;">
                                 <label>
                                 <input
                                    type="radio"
                                    class="toggle_div toggle_div_ref"
                                    name="has_reference"
                                    :value="1"
                                    v-model="form.has_reference.status"
                                    v-on:change="update_has_reference()"
                                 />
                                 <span>Yes</span>
                                 </label>
                                 <label>
                                 <input
                                    type="radio"
                                    class="toggle_div toggle_div_ref"
                                    name="has_reference"
                                    :value="0"
                                    v-model="form.has_reference.status"
                                    v-on:change="update_has_reference()"
                                 />
                                 <span>No</span>
                                 </label>
                              </div>
                              <button class="btn submit_button ref_add ml-25" style="margin-top:12px"  v-if="form.has_reference.status" v-on:click="toggle_reference_form()">
                              Add
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div v-if="flag.show_reference_form">
                     <div class="form_part" style="background: #f5f4fd;" id="wait_me_reference">
                        <div class="row">
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Name<span class="color-red ml-1">*</span></label>
                              <input type="text" id="name" class="form-control" v-model="form.reference.name" value="" placeholder="Enter Name">
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Reference Source<span class="color-red ml-1">*</span></label>
                              <select v-model="form.reference.reference_source" id="reference_source" class="form-control form-control-sm">
                                 <option value="" disabled="" selected="">Select Source</option>
                                 <option value="Education"> Education </option>
                                 <option value="Work"> Work </option>
                                 <option value="Other"> Other </option>
                              </select>
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Designation<span class="color-red ml-1">*</span></label>
                              <input type="text" id="designation" class="form-control" v-model="form.reference.designation" value="" placeholder="Enter Designation">
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Company<span class="color-red ml-1">*</span></label>
                              <input type="text" id="organization" class="form-control" v-model="form.reference.organization" value="" placeholder="Enter Organization Name">
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Department<span class="color-red ml-1">*</span></label>
                              <input type="text" id="department" class="form-control" v-model="form.reference.department" value="" placeholder="Enter Department Name">
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Address</label>
                              <textarea class="form-control" v-model="form.reference.address" id="address" rows="2" placeholder="Enter Address"></textarea>
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">City</label>
                              <input type="text" id="city" class="form-control" v-model="form.reference.city" value="" placeholder="Enter City">
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Zip Code</label>
                              <input type="text" id="zip_code" class="form-control" v-model="form.reference.zip_code" value="" placeholder="Enter Zip Code">
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Country</label>
                              <treeselect
                                 :options="list.countries"
                                 placeholder="Select Country"
                                 autocomplete="off"
                                 v-model="form.reference.country"
                              />
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Email<span class="color-red ml-1">*</span></label>
                              <input type="email" id="email" class="form-control" v-model="form.reference.email" value="" placeholder="Enter Email">
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Mobile Country</label>

                              <treeselect
                                 :options="list.countries_code"
                                 placeholder="Select Mobile Country"
                                 autocomplete="off"
                                 v-model="form.reference.mobile_number_country_id"
                              />
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Mobile Number</label>
                              <input type="text" id="mobile_number" class="form-control" v-model="form.reference.mobile_number" value="" placeholder="Enter Mobile Number">
                           </div>
                        </div>
                     </div>
                     <div class="text-right mt-3 visa-btn">
                        <button @click="formClose()" class="btn cancel_button mr-1 btn-cancel" style="padding: 4px 8px !important;">
                        <i class="fa fa-close" aria-hidden="true"></i>
                        </button>
                        <button type="submit" class="btn submit_button" v-on:click="add_reference()">
                        Save
                        </button>
                     </div>
                  </div>


                  
               </div>
               <div class="col-lg-6">
                  <div class="stage_form_header stage_form_header_with_yes_no_radio">
                     <div class="left">
                        <i class="fa fa-info" aria-hidden="true" style="padding: 6px 11px;"></i>
                     </div>
                     <div class="right">
                        <div class="with_yes_no_radio_inner">
                           <div class="with_yes_no_radio_inner_left">
                              <span class="main_title">Do You have any Work Experience?</span>
                           </div>
                           <div class="with_yes_no_radio_inner_right">
                              <div class="radio_box" style="place-content: flex-end;">
                                 <label>
                                 <input
                                    type="radio"
                                    class="toggle_div toggle_div_work"
                                    name="has_work"
                                    :value="1"
                                    v-model="form.has_work.status"
                                    v-on:change="update_has_work()"
                                 />
                                 <span>Yes</span>
                                 </label>
                                 <label>
                                 <input
                                    type="radio"
                                    class="toggle_div toggle_div_work"
                                    name="has_work"
                                    :value="0"
                                    v-model="form.has_work.status"
                                    v-on:change="update_has_work()"
                                 />
                                 <span>No</span>
                                 </label>
                              </div>
                              <button class="btn submit_button work_add ml-25" style="margin-top:12px" v-if="form.has_work.status" v-on:click="toggle_work_form()">
                              Add
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div  v-if="flag.show_work_form">
                     <div class="form_part" style="background: #f5f4fd;">
                        <div class="row">
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Company Name</label>
                              <input type="text" id="company_name" class="form-control" v-model="form.work.company_name" value="" placeholder="Company Name">
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Designation</label>
                              <input type="text" id="designation" class="form-control" v-model="form.work.designation" value="" placeholder="Designation">
                           </div>
                           <div class="form-group col-lg-7">
                              <strong style="font-size: small;">Currently working in that company?</strong>
                           </div>
                           <div class="form-group col-lg-5">
                              <div class="radio_box">
                                 <label>
                                 <input
                                    type="radio"
                                    class="toggle_div toggle_div_work"
                                    name="has_work"
                                    :value="true"
                                    v-model="form.work.status"
                                 />
                                 <span>Yes</span>
                                 </label>
                                 <label>
                                 <input
                                    type="radio"
                                    class="toggle_div toggle_div_work"
                                    name="has_work"
                                    :value="false"
                                    v-model="form.work.status"
                                 />
                                 <span>No</span>
                                 </label>
                              </div>
                           </div>
                           <div class="form-group col-lg-6">
                              <label class="form_label mb-1">Start Date</label>
                              <div class="input-group dateNicon">
                                 <flat-pickr
                                 id="dob"
                                 v-model="form.work.start_date"
                                 :config="configs.start_date"
                                 class="form-control date2 flatpickr-input"
                                 placeholder="Select a Date"
                                 />
                                 <span class="input-group-text">
                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                 </span>
                              </div>
                           </div>
                           <div class="form-group col-lg-6" v-if="form.currently_working.status">
                              <label class="form_label mb-1">End Date</label>
                              <div class="input-group dateNicon">
                                 <flat-pickr
                                 id="dob"
                                 v-model="form.work.end_date"
                                 :config="flatePickerEndDateConfig"
                                 class="form-control date2 flatpickr-input"
                                 placeholder="Select a Date"
                                 />
                                 <span class="input-group-text">
                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="text-right mt-3 visa-btn">
                        <button @click="formClose()" class="btn cancel_button mr-1 btn-cancel" style="padding: 4px 8px;">
                          <i class="fa fa-close" aria-hidden="true"></i>
                        </button>
                        <button type="submit" class="btn submit_button"  v-on:click="add_work()">
                        Save
                        </button>
                     </div>
                  </div>
               </div>
            </div>



            <div class="row mt-1 reference_work_list_part">
               <div class="col-md-6">
                  <div id="lead_reference">
                     <div class="scrolled_content_part_outer">
                        <div class="title_in_borders">List of References</div>
                        <div class="scrolled_content_part">
                           <div class="single_part"  v-for="(row, index) in list.references" :key="index">
                              <div class="left">
                                 <i class="fa fa-user-plus"></i>
                              </div>
                              <div class="right">
                                 <span class="progress-lbl mr-1" style="font-size: 12px; font-weight: 600; color: black;" v-html="row.name"> </span> (<span style="font-weight: 400; color: #646565;"  v-html="row.designation"></span>)
                                 <br>
                                 <span style="color: #343434;font-weight: 400;"  v-html="row.organization"></span> •
                                 <span class="common_badge"  v-html="row.department"></span>
                                 <br>
                                <span class="" style="color: #757272;font-weight: 400;" v-if="row.mobile_number">
                                  {{ row.mobile_number_country ? '+' + row.mobile_number_country.calling_code : '' }} {{ row.mobile_number }}
                                </span>
                                <span v-if="row.mobile_number"> • </span>
                                 <i class="fa fa-envelope-o mr-1" v-if="row.email"></i> <span style="color: #757272;font-weight: 400;"   v-html="row.email"></span>
                                 
                              </div>
                              <div class="action_dropdown">                                            
                                <!-- <b-dropdown size="sm" variant="link" class="text-dark" toggle-class="text-decoration-none" no-caret>
                                    <template #button-content>
                                          <span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
                                    </template>
                                    <b-dropdown-item href="javascript:void(0)" @click="toggle_reference_form(row)">
                                          <i v-tooltip="'Edit Agent Details'" class="fa fa-pencil mr-50"></i> Edit
                                    </b-dropdown-item>
                                    <b-dropdown-item href="javascript:void(0)" @click="delete_reference(row, index)">
                                          <i v-tooltip="'Delete Agent'" class="fa fa-trash-o text-danger mr-50"></i> Delete
                                    </b-dropdown-item>
                                </b-dropdown> -->
                                 <div class="d-flex">                                            
                                    <i v-tooltip="'Edit'" @click="toggle_reference_form(row)" class="fa fa-pencil action_button mr-50"></i>
                                    <i v-tooltip="'Delete'" @click="delete_reference(row, index)" class="fa fa-trash-o action_button"></i>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div id="work_experience">
                     <div class="scrolled_content_part_outer">
                        <div class="title_in_borders">List of Works</div>
                        <div class="scrolled_content_part">
                           <div class="single_part"  v-for="(row, index) in list.work_histories" :key="index">
                              <div class="left">
                                 <i class="fa fa-briefcase"></i>
                              </div>
                              <div class="right">
                                 <span class="progress-lbl mr-1" style="font-size: 12px; font-weight: 600; color: black;"  v-html="row.company_name"></span>
                                 <span style="font-size: 11px; color: #868e96;" v-html="row.start_date"></span> - <span v-if="row.status">Present</span><span v-else style="font-size: 11px; color: #868e96;" v-html="row.end_date"></span>•
                                 <span style="font-size: 11px; color: #868e96;"> {{ ageFullCount(row.start_date) }}</span> <br>
                                 <span style="color: #343434;font-weight: 400;"   v-html="row.designation">
                                 </span>
                              </div>
                               <div class="action_dropdown">                                            
                                <!-- <b-dropdown size="sm" variant="link" class="text-dark" toggle-class="text-decoration-none" no-caret>
                                    <template #button-content>
                                          <span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
                                    </template>
                                    <b-dropdown-item href="javascript:void(0)" @click="toggle_work_form(row)">
                                          <i v-tooltip="'Edit Agent Details'" class="fa fa-pencil mr-50"></i> Edit
                                    </b-dropdown-item>
                                    <b-dropdown-item href="javascript:void(0)" @click="delete_work(row, index)">
                                          <i v-tooltip="'Delete Agent'" class="fa fa-trash-o text-danger mr-50"></i> Delete
                                    </b-dropdown-item>
                                </b-dropdown> -->
                                 <div class="d-flex">                                            
                                    <i v-tooltip="'Edit'" @click="toggle_work_form(row)" class="fa fa-pencil action_button mr-50"></i>
                                    <i v-tooltip="'Delete'" @click="delete_work(row, index)" class="fa fa-trash-o action_button"></i>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <button class="btn submit_button passport_submit_complete complete_step pull-right"
              style="bottom: 0; position: absolute; right: 15px"
              v-if="!flag.step_completed"
              v-on:click="complete_this_step()">
              Save &amp; Complete
            </button>
         </div>
      </div>
   </div>


</section>
</template>

<script>
import swal from "bootstrap-sweetalert";
import Treeselect from "@riophae/vue-treeselect";
// import { BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
  name: "ReferenceAndWork",
  components: {
      Treeselect, 
      // BDropdown, BDropdownItem
  },
  data() {
    return {
      flag: {
        show_reference_form: false,
        show_work_form: false,
        currently_working: true,
        step_completed: false
      },
      form: {
        has_reference: {
          status: 0,
        },
        has_work: {
          status: 0,
        },
        currently_working: {
          status: true,
        },
        reference: {
            id: null,
            name: "",
            reference_source: "",
            designation: "",
            organization: "",
            department: "",
            address: "",
            city: "",
            zip_code: "",
            country: null,
            email: "",
            mobile_number_country_id:null,
            mobile_number: "",
        },
        work: {
            id: null,
            company_name: "",
            designation: "",
            start_date: "",
            end_date: "",
            status: 0,
        },
      },
      configs: {
        start_date: {
          dateFormat: "d M Y",
          maxDate: "today",
        },
        end_date: {
          dateFormat: "d M Y",
          // minDate: "today",
          maxDate: "today",
        },
      },
      list: {
        countries: [],
        countries_code: [],
        references: [],
        work_histories: [],
      },
    };
  },
  computed: {
     flatePickerEndDateConfig: function () {
        if (this.form.work.start_date) {
           const date = {
               dateFormat: "d M Y",
               maxDate: "today",
               minDate: this.form.work.start_date
            }
            return date 
        } else {
            const date = {
               dateFormat: "d M Y",
               maxDate: "today",
            }
            return date
        }
     }
  },
  methods: {
    formClose: function () {
        this.$emit('stage-close')
    },
    init: async function () {
      var ref = this;
      var jq = ref.jq();

      var url = ref.url(
        "api/v2/student/ajax/full_profile_stage"
      );

      try {
        var res = await jq.get(url);
        ref.form.has_reference.status = res.data.has_reference;
        ref.form.has_work.status = res.data.has_work;
        ref.flag.step_completed = res.data.step_completed;
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      }

    },
    update_has_reference: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/update_lead_has_reference_status");
      var jq = ref.jq();

      swal(
        {
          title: "Are you sure?",
          text: "Press Yes to confirm.",
          type: "info",
          showCancelButton: true,
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-light",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, ref.form.has_reference);
                ref.alert(res.msg);
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");

                if (ref.form.has_reference.status == 1) {
                  ref.form.has_reference.status = 0;
                } else {
                  ref.form.has_reference.status = 1;
                }
              }
            })();
          } else {
            if (ref.form.has_reference.status == 1) {
              ref.form.has_reference.status = 0;
            } else {
              ref.form.has_reference.status = 1;
            }
          }
        }
      );
    },
    update_has_work: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/update_lead_has_work_status");
      var jq = ref.jq();

      swal(
        {
          title: "Are you sure?",
          text: "Press Yes to confirm.",
          type: "info",
          showCancelButton: true,
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-light",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, ref.form.has_work);
                ref.alert(res.msg);
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");

                if (ref.form.has_work.status == 1) {
                  ref.form.has_work.status = 0;
                } else {
                  ref.form.has_work.status = 1;
                }
              }
            })();
          } else {
            if (ref.form.has_work.status == 1) {
              ref.form.has_work.status = 0;
            } else {
              ref.form.has_work.status = 1;
            }
          }
        }
      );
    },
    toggle_currently_working: function () {
      var ref = this;

      ref.flag.currently_working = !ref.flag.currently_working;

    },
    toggle_reference_form: function (row = null) {
      var ref = this;

      if (row) {
        ref.flag.show_reference_form = true;
      } else {
        ref.flag.show_reference_form = !ref.flag.show_reference_form;
      }

      if(ref.flag.show_reference_form){

        if(row){
          ref.form.reference=ref.clone(row);
          ref.form.reference.name=ref.row.name;
          ref.form.reference.reference_source=ref.row.reference_source;
          ref.form.reference.designation=ref.row.designation;
          ref.form.reference.organization=ref.row.organization;
          ref.form.reference.department=ref.row.department;
          ref.form.reference.address=ref.row.address;
          ref.form.reference.city=ref.row.city;
          ref.form.reference.zip_code=ref.row.zip_code;
          ref.form.reference.country=ref.row.country;
          ref.form.reference.email=ref.row.email;
          ref.form.reference.mobile_number_country_id=ref.row.mobile_number_country_id;
          ref.form.reference.mobile_number=ref.row.mobile_number;
        }else ref.reset_reference_form();

      }else ref.reset_reference_form();
    },
    toggle_work_form: function (row = null) {
      var ref = this;

      if (row) {
        ref.flag.show_work_form = true;
      } else {
        ref.flag.show_work_form = !ref.flag.show_work_form;
      }

      if(ref.flag.show_work_form){

        if(row){
          ref.form.work=ref.clone(row);
          ref.form.work.id=row.id;
          ref.form.work.company_name=row.company_name;
          ref.form.work.designation=row.designation;
          ref.form.work.start_date=ref.dDate(row.issue_date);
          ref.form.work.end_date=ref.dDate(row.expire_date);
        }else ref.reset_work_form();

      }else ref.reset_work_form();
    },
    add_work: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/add_work");
      var jq = ref.jq();

      try {
        ref.wait_me("#wait_me_reference");
        var res = await jq.post(url, this.form.work);
        ref.alert(res.msg);
        ref.toggle_work_form();
        ref.reset_work_form();
        ref.fetch_reference_and_work_data();
        this.$emit('dashboard-info-update', true)

      } catch (error) {
        ref.alert(ref.err_msg(error), "error");
      } finally {
        ref.wait_me("#wait_me_reference", "hide");
      }
    },
    add_reference: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/add_reference");
      var jq = ref.jq();

      try {

        ref.wait_me("#wait_me_reference");
        var res = await jq.post(url, ref.form.reference);
        ref.alert(res.msg);
        ref.toggle_reference_form();
        ref.reset_reference_form();
        ref.fetch_reference_and_work_data();
        this.$emit('dashboard-info-update', true)

      } catch (error) {
        ref.alert(ref.err_msg(error), "error");
      } finally {
        ref.wait_me("#wait_me_reference", "hide");
      }
    },
    reset_work_form: function () {
      var form = this.form.work;
      form.id = null;
      form.company_name = "";
      form.designation = "";
      form.start_date = "";
      form.end_date = "";
    },
    reset_reference_form: function () {
      var form = this.form.reference;

      form.id = null;
      form.name = "";
      form.reference_source = "";
      form.designation = "";
      form.organization = "";
      form.department = "";
      form.address = "";
      form.city = "";
      form.zip_code = "";
      form.country = null;
      form.email = "";
      form.mobile_number_country_id = null;
      form.mobile_number = "";
    },
    fetch_reference_and_work_data: async function () {

      var ref = this;
      var jq = ref.jq();

      var url = ref.url(
        "api/v2/student/ajax/reference_and_work_data"
      );

      try {
        var res = await jq.get(url);
        ref.list.countries = res.data.countries;
        ref.list.countries_code = res.data.countries_code;
        ref.list.references = res.data.references;
        if (res.data.work_histories.length) {
          ref.list.work_histories = res.data.work_histories.map(item => {
            item.status = item.status == 1 ? true : false
            return Object.assign(item)
          })
        }

        this.form.currently_working.status
      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }
    },
    toggle_menu: function(row){

      this.list.passports.forEach(function(each){
        if(row.id!=each.id) each.show_menu=false;
      });

      row.show_menu=!row.show_menu;

    },
    complete_this_step: async function(){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/lead_reference_and_work_step_completed");
      var jq = ref.jq();

      swal({
        // title: "Are you sure? You want to submit your reference and work information as completed.",
        title: "Are you sure?",
        text: "Press Yes to confirm.",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url);
                ref.alert(res.msg);
                ref.init();

                ref.$emit("save-and-completed", true);
                
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    },
    delete_reference: function(row, row_index){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/delete_reference");
      var jq = ref.jq();

      swal({
        title: "Are you sure? You want to delete this related information",
        text: "Press Yes to confirm.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, row);
                ref.alert(res.msg);
                ref.remove_row(ref.list.references, row_index);
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    },
    delete_work: function(row, row_index){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/delete_work");
      var jq = ref.jq();

      swal({
        title: "Are you sure? You want to delete this related information",
        text: "Press Yes to confirm.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, row);
                ref.alert(res.msg);
                ref.remove_row(ref.list.work_histories, row_index);
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    }
  },
  created: function () {
    this.setup_urls();
    this.init();
    this.fetch_reference_and_work_data();
  },
};
</script>

<style scoped>

      .aft_calender {
      position: absolute;
      right: 22px;
      }
      .input-group-addon:hover {
      background-color: #00a8ff;
      border: none;
      border-color: #00a8ff;
      color: #fff !important;
      }
      .dateNicon input {
      background: #fff !important;
      border-right: none;
      }
      .input-group-addon {
      background: #fff;
      padding: 0 .75rem;
      }
      .glyphicon{
      position: relative;
      top: 1px;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      font-size: 15px;
      }
      .reference_work_list_part .scrolled_content_part {
      /*padding: 20px 10px;*/
      padding: 0;
      }
      .reference_work_list_part .scrolled_content_part .single_part {
      display: flex;
      width: 100%;
      padding: 7px 16px;
      margin-top: 10px;
      border-radius: .25rem;
      border: 1px solid #e9f0f3;
      align-items: center;
      }
      .reference_work_list_part .scrolled_content_part .single_part:last-child {
      /*border-bottom: none;*/
      }
      .reference_work_list_part .scrolled_content_part .single_part .left {
      width: 50px;
      }
      .reference_work_list_part .scrolled_content_part .single_part h3 {
      font-size: 11px; 
      font-weight: bold; 
      color: rgb(98, 95, 139); 
      margin-bottom: 6px;
      }
      .reference_work_list_part .scrolled_content_part .single_part .right{
      line-height: 1.2;
      width: calc(100% - 50px);
      }
      .reference_work_list_part .scrolled_content_part .single_part .left i {
      background: #f1f0fb;
      padding: 10px;
      border-radius: 100%;
      font-size: 14px;
      color: #2e1153;
      }
      .info_middle {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      }
      .reference_work_list_part .scrolled_content_part_outer{
      position: relative;
      /*border: 1px solid #d8e2e7;*/
      border-radius: .25rem;
      margin-top: 10px;
      }
      .reference_work_list_part .scrolled_content_part_outer .title_in_borders {
      /*background: white;
      width: fit-content;
      padding: 5px;
      font-size: 13px;
      font-weight: 600;
      top: -14px;
      position: absolute;
      left: 12px;*/
      background: #dedbf1;
      padding: 7px 16px;
      margin-top: 10px;
      border-radius: 30px;
      position: relative;
      font-size: 12px;
      font-weight: 700;
      width: inherit;
      left: 0;
      top: -10px;
      }
      .stage_form_header_with_yes_no_radio{
      background: #fff !important;
      border: 1px solid #dedbf1 !important;
      }
      .stage_form_header .with_yes_no_radio_inner{
      display: flex; 
      width: 100%;
      }
      .stage_form_header .with_yes_no_radio_inner .with_yes_no_radio_inner_left{
      width: calc(100% - 200px);
      align-self: center;
      }
      .stage_form_header .with_yes_no_radio_inner .with_yes_no_radio_inner_right{
      width: 200px;
      display: flex;
      }
      .action_dropdown .dropdown-toggle{
      background: #dedbf1;
      padding: 4px 9px 3px;
      border-radius: 50%;
      font-size: 13px;
      border: 1px solid transparent;
      color: #826aa0;
      cursor: pointer;
      }
      .action_dropdown .dropdown-toggle:hover {
      color: #ff7f00 !important;
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      border: 1px solid #ff7f00;
      }
      .action_dropdown .dropdown-toggle:after{
      display: none;
      }
      .action_dropdown .dropdown-menu.show{
      margin-left: -55px;
      min-width: auto;
      padding: 0;
      margin-top: 3px;
      }
      .action_dropdown .dropdown-menu.show .dropdown-item{
      background: #dedbf1;
      color: #2e1153;
      font-size: 12px;
      border: none;
      padding: 4px 12px;
      }
      .action_dropdown .dropdown-menu.show .dropdown-item:hover{
      background: #2e1153;
      color: #fff !important;
      }
      .action_dropdown .dropdown-menu.show .activate{
      background: #2e1153;
      color: #fff;
      }
      .action_dropdown .dropdown-menu.show .dropdown-item:first-child{
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      }
      .action_dropdown .dropdown-menu.show .dropdown-item:last-child{
      border-bottom-left-radius: .25rem;
      border-bottom-right-radius: .25rem;
      }
      .scrolled_content_part .single_part:hover {
      background: #f5f4fd;
      }

</style>
