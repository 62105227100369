<template>
<section class="dashboard">
  <main-layout>
      <div class="container" style="margin-top: 150px">
        <div class="row">
          <div class="col">
            <h1 class="text-center text-primary">
              Logging Out...
            </h1>
          </div>
        </div>
      </div>
  </main-layout>
</section>
</template>

<script>
import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';

export default{
	name: 'Logout',
	components:{
		MainLayout
	},
	data:()=>{
		return {};
	},
	methods:{
		logout: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v2/student/auth/logout');

      try{
          await jq.post(url);

          localStorage.removeItem('student_api_token');
          localStorage.removeItem('base_url');
          localStorage.removeItem('setApiToken');
          ref.$store.commit('siteInfo', {
                key: 'auth_user',
                val: ''
            });
          ref.$router.push('/');
      }catch(err){
          console.log(err);
      }

      // localStorage.removeItem('student_api_token');
      // localStorage.removeItem('base_url');
      // localStorage.removeItem('setApiToken');
      // ref.$router.push('/');
		}
	},
  created: function () {
    this.setup_urls();
  },
	mounted: function(){
		feather.replace();
    this.logout();
	}
}
</script>
