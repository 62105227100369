<template>
  <section class="root">
    <div class="collaps_contact_info_post">
      <div class="row py-2 px-2">
        <div class="col-md-12">
          <div style="min-height: 570px">
            <div class="row">

              <div class="col-lg-6">
                <div class="stage_form_header">
                  <div class="left">
                    <i
                      class="fa fa-phone"
                      aria-hidden="true"
                      style="padding: 7px 8.5px"
                    ></i>
                  </div>
                  <div class="right">
                    <span class="main_title">Contact Information</span>
                    <span class="sub_title">
                      Provide your contact details for academic communication.
                    </span>
                  </div>
                </div>
                <div class="form_part" style="background: #f5f4fd">
                  <div class="form-row">
                    <div class="form-group col-lg-6">
                      <label class="form_label">
                        Primary Email
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        readonly
                        type="email"
                        class="form-control form-control-sm"
                        name="email"
                        value="nishat2000nishat@gmail.com"
                        id="email"
                        placeholder="example@mail.com"
                        v-model="form.contact_info.email"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label">Alternative Email</label>
                      <input
                        type="email"
                        class="form-control form-control-sm"
                        name="alt_email"
                        id="alt_email"
                        placeholder="example@mail.com"
                        v-model="form.contact_info.alt_email"
                      />
                    </div>
                    <div class="col-lg-12">
                      <div class="form-row">
                        <div class="form-group col-lg-6">
                          <label class="form_label">
                            Mobile No<span class="text-danger">*</span>
                          </label>

                          <treeselect
                            id="mobile_country_id"
                            :options="list.countries"
                            placeholder="Select Country"
                            autocomplete="off"
                            v-model="form.contact_info.mobile_country_id"
                          />

                        </div>

                        <div class="form-group col-lg-6">
                          <label class="form_label">&nbsp;</label>
                          <input
                            type="text"
                            class="form-control from-control-sm"
                            name="mobile"
                            id="mobile"
                            placeholder="Mobile number without country code"
                            v-model="form.contact_info.mobile"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-row">
                        <div class="form-group col-lg-6">
                          <label class="form_label"
                            >Alternative Mobile No</label
                          >

                          <treeselect
                            id="alt_mobile_country_id"
                            :options="list.countries"
                            placeholder="Select Country"
                            autocomplete="off"
                            v-model="form.contact_info.alt_mobile_country_id"
                          />

                        </div>
                        <div class="form-group col-lg-6">
                          <label class="form_label">&nbsp;</label>
                          <input
                            type="text"
                            class="form-control"
                            name="alt_mobile"
                            id="alt_mobile"
                            placeholder="Mobile number without country code"
                            v-model="form.contact_info.alt_mobile"
                          />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div class="stage_form_header mt-2">
                  <div class="left">
                    <i
                      class="fa fa-volume-control-phone"
                      aria-hidden="true"
                      style="padding: 7px 8.5px"
                    ></i>
                  </div>
                  <div class="right">
                    <span class="main_title">Emergency Contact</span>
                    <span class="sub_title">
                      Provide contact details for emergency situations.
                    </span>
                  </div>
                </div>
                <div class="form_part mt-2" style="background: #f5f4fd">
                  <div class="form-row">
                    <div class="form-group col-lg-6">
                      <label class="form_label"
                        >Emergency Contact Name</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="emr_name"
                        placeholder="Contact Name"
                        v-model="form.contact_info.emr_name"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label"
                        >Emergency Contact Email</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="emr_email"
                        placeholder="Contact Email"
                        v-model="form.contact_info.emr_email"
                      />
                    </div>
                    <div class="col-lg-12">
                      <div class="form-row">

                        <div class="form-group col-lg-6">
                          <label class="form_label"
                            >Emergency Contact Mobile</label
                          >

                          <treeselect
                            id="emr_mobile_country_id"
                            :options="list.countries"
                            placeholder="Select Country"
                            autocomplete="off"
                            v-model="form.contact_info.emr_mobile_country_id"
                          />

                        </div>

                        <div class="form-group col-lg-6">
                          <label class="form_label">&nbsp;</label>
                          <input
                            type="text"
                            class="form-control"
                            name="emr_mobile"
                            placeholder="Contact Mobile"
                            v-model="form.contact_info.emr_mobile"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label">Emergency Contact Relation</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="emr_relation"
                        placeholder="Contact Relation"
                        v-model="form.contact_info.emr_relation"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="stage_form_header">
                  <div class="left">
                    <i
                      class="fa fa-globe"
                      aria-hidden="true"
                      style="padding: 7px 8.5px"
                    ></i>
                  </div>
                  <div class="right">
                    <span class="main_title">Social Contact</span>
                    <span class="sub_title">
                      Fill up Social contact for getting benefit from Quick
                      Contacts.
                    </span>
                  </div>
                </div>
                <div class="form_part" style="background: #f5f4fd">
                  <div class="social_contact_div&quot;">
                    <div class="row">
                      <div class="form-group col-lg-12">
                        <label class="form_label">Skype</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-text">
                            <i class="fa fa-skype"></i>
                          </div>
                          <input readonly
                            type="text"
                            class="form-control"
                            name="skype"
                            id="skype"
                            placeholder="Please write Skype ID"
                            v-model="form.contact_info.skype"
                          />
                        </div>
                      </div>
                      <div class="form-group col-lg-12">
                        <label class="form_label">
                          Whatsapp
                          <i
                            class="fa fa-info-circle ml-50"
                            v-tooltip="'Please include country code and omit \'+\'  and \'00\' before the country code.'"
                            style="top: 1px; position: relative; color: #c9c0b0"
                          ></i>
                        </label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-text">
                            <i class="fa fa-whatsapp"></i>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            name="whatsapp"
                            id="whatsapp"
                            placeholder="Please follow this format i.e. 441142725444"
                            v-model="form.contact_info.whatsapp"
                          />
                        </div>
                      </div>
                      <div class="form-group col-lg-12">
                        <label class="form_label">Instagram</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-text">
                            <i class="fa fa-instagram"></i>
                          </div>
                          <input readonly
                            type="text"
                            class="form-control"
                            name="instagram"
                            id="instagram"
                            placeholder="Please write Instagram user ID only"
                            v-model="form.contact_info.instagram"
                          />
                        </div>
                      </div>
                      <div class="form-group col-lg-12">
                        <label class="form_label">Viber</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-text">
                            <i class="fa fa-volume-control-phone"></i>
                          </div>
                          <input readonly
                            type="text"
                            class="form-control"
                            name="viber"
                            id="viber"
                            placeholder="Please follow this format i.e. 441142725444"
                            v-model="form.contact_info.viber"
                          />
                        </div>
                      </div>
                      <div class="form-group col-lg-12">
                        <label class="form_label">Facebook</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-text">
                            <i class="fa fa-facebook"></i>
                          </div>
                          <input readonly
                            type="text"
                            class="form-control"
                            name="facebook"
                            id="facebook"
                            placeholder="Please write Facebook user ID only"
                            v-model="form.contact_info.facebook"
                          />
                        </div>
                      </div>
                      <div class="form-group col-lg-12">
                        <label class="form_label">Telegram</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-text">
                            <i class="fa fa-paper-plane"></i>
                          </div>
                          <input readonly
                            type="text"
                            class="form-control"
                            name="telegram"
                            id="telegram"
                            placeholder="Telegram Number"
                            v-model="form.contact_info.telegram"
                          />
                        </div>
                      </div>
                      <div class="form-group col-lg-12">
                        <label class="form_label">Twitter</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-text">
                            <i class="fa fa-twitter"></i>
                          </div>
                          <input readonly
                            type="text"
                            class="form-control"
                            name="twitter"
                            id="twitter"
                            placeholder="Please write Twitter user ID only"
                            v-model="form.contact_info.twitter"
                          />
                        </div>
                      </div>
                      <div class="form-group col-lg-12">
                        <label class="form_label">Google Hangout</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-text">
                            <i class="fa fa-google"></i>
                          </div>
                          <input readonly
                            type="text"
                            class="form-control"
                            name="goggle_hang"
                            id="goggle_hang"
                            placeholder="Please write the Hangout ID"
                            v-model="form.contact_info.goggle_hang"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col text-right mt-2"
              >
                <button @click="formClose()"
                  class="btn cancel_button mr-1 btn-cancel"
                  title="Refresh"
                  style="padding: 4px 8px !important;"
                >
                  <i class="fa fa-close" aria-hidden="true"></i>
                </button>

                <button class="btn submit_button submit_button_save" v-on:click="submit_contact_info()">
                  <span v-if="flag.step_completed">Save</span>
                  <span v-else> Save & Complete</span>
                </button>

              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  </section>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";

export default {
  name: 'ContactInfo',
  components: {
    Treeselect
  },
  data(){
    return {
      list:{
        countries: [],
        contact_preferences:[]
      },
      timeList: [],
      flag:{
        step_completed: false
      },
      form:{
        contact_info:{
          email:'',
          alt_email:'',
          mobile:'',
          mobile_country_id: null,
          alt_mobile:'',
          alt_mobile_country_id: null,
          start:'',
          end:'',
          contact_preference: null,
          emr_name:'',
          emr_email:'',
          emr_mobile:'',
          emr_mobile_country_id:null,
          emr_relation:'',
          skype:'',
          whatsapp:'',
          instagram:'',
          viber:'',
          facebook:'',
          telegram:'',
          twitter:'',
          goggle_hang:''
        }
      },
      configs: {
        flatpickr: {
          enableTime: true,
          noCalendar: true,
          dateFormat: "G:i K"
        },
      }
    }
  },
  computed: {
      closing_hour_time_list: function(){
         const timeIndex = this.timeList.findIndex(item => item.id == this.form.contact_info.start)
         return this.timeList.filter((item, index) => index > timeIndex)
      },
  },
  methods:{
    formClose: function () {
        this.$emit('stage-close')
    },
    init: async function(){

      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v2/student/ajax/full_profile_stage_contact_info");

      try {

        var res = await jq.get(url);

        ref.list.countries = res.data.countries;
        ref.list.contact_preferences = res.data.contact_preferences;
        ref.flag.step_completed=res.data.step_completed;
        this.timeList = res.data.times

        var data=res.data;

        ref.populate_c_info_form(data.lead, data.contact_time, data.social_contacts);

      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }
    },
    populate_c_info_form: function (lead, contact_time, s_contacts) {

      var form = this.form.contact_info;

      form.email = this.cn(lead, "lead_contact.email", "");
      form.alt_email = this.cn(lead, "lead_contact.alt_email", "");
      form.mobile_country_id = this.cn(lead, "lead_contact.mobile_country_id", null);
      form.mobile = this.cn(lead, "lead_contact.mobile", '');
      form.alt_mobile_country_id = this.cn(lead, "lead_contact.alt_mobile_country_id", null);
      form.alt_mobile = this.cn(lead, "lead_contact.alt_mobile", null);

      form.start = this.cn(contact_time, "start_time", '');
      form.end = this.cn(contact_time, "end_time", '');

      form.contact_preference=JSON.parse(this.cn(lead, "lead_contact.contact_preference", '[]'));

      form.emr_name = this.cn(lead, "lead_contact.emr_name", '');
      form.emr_email = this.cn(lead, "lead_contact.emr_email", '');
      form.emr_mobile_country_id = this.cn(lead, "lead_contact.emr_mobile_country_id", null);
      form.emr_mobile = this.cn(lead, "lead_contact.emr_mobile", '');
      form.emr_relation = this.cn(lead, "lead_contact.emr_relation", '');

      form.skype=this.cn(s_contacts, 'skype', '');
      form.whatsapp=this.cn(s_contacts, 'whatsapp', '');
      form.instagram=this.cn(s_contacts, 'instagram', '');
      form.viber=this.cn(s_contacts, 'viber', '');
      form.facebook=this.cn(s_contacts, 'facebook', '');
      form.telegram=this.cn(s_contacts, 'telegram', '');
      form.twitter=this.cn(s_contacts, 'twitter', '');
      form.goggle_hang=this.cn(s_contacts, 'goggle_hang', '');
      

    },
    submit_contact_info:async function(){

      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v2/student/ajax/save_full_profile_stage_contact_info");

      var form_data=ref.form.contact_info;

      try {

        ref.wait_me('.collaps_contact_info_post');
        var res = await jq.post(url, form_data);
        ref.alert(res.msg);
        ref.init();

        if(!ref.flag.step_completed){
          this.$emit("save-and-completed", true);
        }
        this.$emit('dashboard-info-update', true)

      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }finally{
        ref.wait_me('.collaps_contact_info_post', 'hide');
      }

    }
  },
  created: function(){
    this.setup_urls();
    this.init();
  }
};
</script>

<style scoped>
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #2e1153 !important;
  border: 1px solid #2e1153 !important;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #2e1153 !important;
  color: #fff !important;
}
</style>
