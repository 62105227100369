<template>
    <div class="row py-2 px-2">
        <div class="col-lg-12">

            <div class="ad_pref_part p-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-lg-10">
                                <div class="content_header ml-0">
                                    <div class="left">
                                    <i class="fa fa-university" style="padding: 7px 6px;"></i>
                                    </div>
                                    <div class="right">
                                    <span class="main_title">Admission Preference</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col text-right">
                                <button v-if="listData.length <=3" class="submit_button" @click="openAdmissionPreferenceModal()"> Add New </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 create_admission_preference">
                        <div class="ad_pref_item_header">
                            <div class="destination">
                                Destination
                            </div>
                            <div class="second">
                                University Details
                            </div>
                            <div class="third">
                                Course Information
                            </div>
                            <div class="fourth">
                                Status
                            </div>

                            <div class="last">
                                Action
                            </div>
                        </div>

                        <div class="scrolled_content_part" v-if="listData && listData.length">
                            <div class="single_ad_pref_item" v-for="(item, index) in listData" :key="index">
                                <div class="destination">
                                    <div class="destination_left" v-if="item.country">
                                        <img :src="item.country.flag_url" alt="Flag" v-tooltip="item.country.name" />
                                    </div>
                                    <div class="destination_info">
                                        <strong v-if="item.country" v-html="item.country.name"></strong>

                                        <div class="visa_country">
                                            <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'yes'" v-tooltip="'Visa Required'" class="label_badge_radius" style="background: #e7f3ea; color: #0ba52e; padding: 1px 10px; line-height: 15px;margin-top: 0px; margin-right: 3px; display: inline-block; border: 1px solid #fff;">
                                                Yes
                                            </span>
                                            <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'no'" v-tooltip="'Visa Required'" class="label_badge_radius" style="background: #f3dadc; color: #de1528; padding: 1px 10px; line-height: 15px;margin-top: 0px; margin-right: 3px; display: inline-block; border: 1px solid #fff;">
                                                No
                                            </span>
                                            
                                            <span v-if="item.lead_need_visa && item.lead_need_visa.visa_type" v-html="item.lead_need_visa.visa_type.name" v-tooltip="'Visa Type'"></span>

                                            <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'yes' && item.lead_need_visa.country_info"> • </span>
                                            <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'yes' && item.lead_need_visa.country_info" v-html="item.lead_need_visa.country_info.name" v-tooltip="'Country of Application'"></span>
                                            
                                            <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'no' && item.lead_need_visa.expiry_date"> • </span>
                                            <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'no' && item.lead_need_visa.expiry_date" v-html="dDate(item.lead_need_visa.expiry_date)" v-tooltip="'Visa Expiry Date'"></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="left">
                                    <div class="uni_details">
                                        <div class="inner_left" v-if="item.university">
                                            <i class="fa fa fa-university"></i>
                                        </div>
                                        <div class="inner_right">
                                            <strong v-if="item.university" v-html="item.university.name"></strong> <br />
                                            <span class="label_badge_radius" v-if="item.level" v-html="item.level.name"></span>
                                            <span v-if="item.level && item.start_date"> • </span>
                                            <span class="label_badge_radius" v-if="item.start_date" v-html="dMonth(item.start_date)"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="middle">
                                    <span v-html="item.subject"></span><br />
                                    
                                    <span v-if="item.priority == 1" class="label_badge_radius" style="background: #d2f9ea; color: #009845;">1st Choice</span>
                                    <span v-else-if="item.priority == 3" class="label_badge_radius" style="background: #ffedcc; color: orange;">2nd Choice</span>
                                    <span v-else-if="item.priority == 2" class="label_badge_radius" style="background: #dbdbdb; color: #686767;">3rd Choice</span>
                                </div>
                                <div class="status_column">
                                    <i v-if="item.pre_status" class="fa fa-check-circle" data-toggle="tooltip" data-title="Converted" style="font-size: 17px; color: #2e1153;"></i>
                                </div>

                                <div class="right">
                                    <div class="btn-group btn-group-sm vue-dropdown" style="float: none;">
                                        <div v-if="!item.university" style="display: flex; justify-content: flex-end;">
                                            <span @click="editData(item)" class="btn pull-right submit_btn_sm mr-25 enroll-edit" style="margin-top: 1px;">Update</span>
                                        </div>

                                        <b-dropdown size="sm" variant="link" class="text-dark action_dropdown drop_fix" toggle-class="text-decoration-none" no-caret>
                                            <template #button-content>
                                                <span><i class="fa fa-ellipsis-v dropdown-toggle" id="edit_btn" aria-hidden="true"></i></span>
                                            </template>
                                            <b-dropdown-item href="javascript:void(0)" @click="editData(item)">
                                                <i class="fa fa-pencil mr-50"></i> Edit
                                            </b-dropdown-item>
                                            <b-dropdown-item href="javascript:void(0)" @click="deleteData(item)">
                                                <i class="fa fa-trash-o text-danger mr-50"></i> Delete
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="scrolled_content_part">
                            <div class="alert mb-1" role="alert" style="border-color: #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                                <span>No Admission Preference available.</span>
                            </div>
                        </div>
                    </div>
                </div>

            <div class="row">
                <div class="col-lg-12 text-right">
                    <button class="btn submit_button passport_submit_complete complete_step mt-2"
                        v-if="!flag.step_completed"
                        v-on:click="complete_this_step()">
                        Save &amp; Complete
                    </button>
                </div>
            </div>
            </div>

            <b-modal ref="admission_preference_modal" size="lg" centered hide-footer>
                <!-- <template #modal-title>
                    <span class="text-primary"> {{ form.admission_preference.enrollment_id ? 'Edit' : 'Add New' }} Admission Preference</span>
                </template> -->

                <template #modal-header>
                      <h5 class="modal_title">{{ form.admission_preference.enrollment_id ? 'Edit' : 'Add New' }} Admission Preference</h5>
                      <button type="button" @click="$refs.admission_preference_modal.hide()">
                        <i class="font-icon-close-2"></i>
                    </button>
                </template>

                <div class="wait_me_insert_form">
                    <div class="form_part" style="background: #f5f4fd; padding-bottom: 10px;">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div id="enrolment_form" class="box-white" style="margin-bottom: 0px;padding: 15px 15px 16px;margin: 5px;border-radius: 0.25rem;border: none;background: #fff;">
                                        <div class="row">
                                            <input type="hidden" name="lead_id" value="29055" />
                                            <div class="form-group col-lg-6">
                                                <label class="form_label">Study Destination<span class="text-danger">*</span></label>
                                                <treeselect id="destination_id" :options="countryList" v-model="form.admission_preference.destination_id" placeholder="Select Study Destination" autocomplete="off"/>
                                            </div>
                                            <!-- <div class="form-group col-lg-6">
                                                <label class="form_label">Institute Type<span class="text-danger"></span></label>
                                                <treeselect id="institute_type" :options="instituteTypeList" v-model="form.admission_preference.institute_type" placeholder="Select Institute Type" autocomplete="off"/>
                                            </div> -->
                                            <!-- <div class="form-group col-lg-6">
                                                <label class="form_label">Pathway Providers(if any)<span class="text-danger"></span></label>
                                                <treeselect id="uni_group_id" :disabled="checkPathwayProvider" :options="universityGroupTreeSelectList" v-model="form.admission_preference.uni_group_id" placeholder="Select Pathway Provider" autocomplete="off"/>
                                            </div> -->
                                            <div class="form-group col-lg-6">
                                                <label class="form_label">Institute Name<span class="text-danger"></span></label>
                                                <treeselect id="institute_id" :options="instituteNameTreeSelectList" v-model="form.admission_preference.institute_id" placeholder="Select Institute" autocomplete="off"/>
                                            </div>
                                            <div class="form-group col-lg-6">
                                                <label class="form_label">Level<span class="text-danger"></span></label>
                                                <treeselect id="level_id" :options="levelList" v-model="form.admission_preference.level_id" placeholder="Select level" autocomplete="off"/>
                                            </div>
                                            <div class="form-group col-lg-6">
                                                <label class="form_label">Preferred Department<span class="text-danger"></span></label>
                                                <treeselect id="course_group_id" :options="coursegroupList" v-model="form.admission_preference.course_group_id" placeholder="Select Preferred Department" autocomplete="off"/>
                                            </div>
                                            <div class="form-group col-lg-12">
                                                <label class="form_label mb-1">Course Title </label>
                                                <input type="text" class="form-control" v-model="form.admission_preference.subject" id="subject" placeholder="Subject" />
                                            </div>
                                            <div class="form-group col-lg-6 hidden" id="level_div">
                                                <div class="row">
                                                    <div class="form-group col-lg-6">
                                                        <label class="form_label mb-1">Supervisor 1st</label>
                                                        <input type="text" class="form-control" name="supervisor1" id="supervisor1" placeholder="Supervisor" />
                                                    </div>
                                                    <div class="form-group col-lg-6">
                                                        <label class="form_label mb-1">Supervisor 2nd</label>
                                                        <input type="text" class="form-control" name="supervisor2" id="supervisor2" placeholder="Supervisor" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-lg-6 mb-0">
                                                <div class="row">
                                                    <div class="form-group col-lg-6">
                                                        <label class="form_label">Start Year<span class="text-danger"></span></label>
                                                        <treeselect id="year" :options="yearList" v-model="form.admission_preference.year" placeholder="Select Start Year" autocomplete="off"/>
                                                    </div>
                                                    <div class="form-group col-lg-6">
                                                        <label class="form_label">Start Month<span class="text-danger"></span></label>
                                                        <treeselect id="month" :options="treeselectMonthList" v-model="form.admission_preference.month" placeholder="Select Start Month" autocomplete="off"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-lg-6">
                                                <label class="form_label">Enrollment Priority</label>
                                                <treeselect id="enrollement_priority_id" :options="enrollmentPriorityList" v-model="form.admission_preference.enrollement_priority_id" placeholder="Select Enrollment Priority" autocomplete="off"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 px-0" id="lead_need_visa" v-show="!form.admission_preference.immigration_destination_exist">
                                    <div class="row pt-3">
                                        <div class="col-lg-12">
                                            <div class="" style="background: #fff; margin: 0 15px; padding: 15px; border-radius: .25rem;">
                                                <div class="form_part pb-3" style="padding: 15px 15px 5px; background: #f5f4fd; border-radius: .25rem;">
                                                    <div class="form_upper_title"><i class="fa fa-flag mr-2" aria-hidden="true"></i>Immigration Status</div>
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div class="form_subtitle">Do you need visa to Study?</div>
                                                        </div>

                                                        <div class="col-md-4 text-right">
                                                            <div class="radio_box" style="justify-content: right; font-size: 12px;">
                                                                <label>
                                                                    <input 
                                                                    name="leads_need_visa" class="leads_need_visa" id="yes" type="radio" v-model="form.admission_preference.leads_need_visa" value="yes" v-on:change="leads_need_visa_change()" />
                                                                    <span>Yes</span>
                                                                </label>
                                                                <label>
                                                                    <input 
                                                                    name="leads_need_visa" class="leads_need_visa" id="no" type="radio" v-model="form.admission_preference.leads_need_visa" value="no" v-on:change="leads_need_visa_change()"/>
                                                                    <span>No</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <!-- {{ form.admission_preference.leads_need_visa }} -->
                                                    </div>
                                                    <div class="form-row" v-if="form.admission_preference.leads_need_visa == 'yes' && !hasPassport">
                                                        <div class="col-md-12">
                                                            <div class="alert alert-danger p-1 font-size-13">Please update passport details.</div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-1" id="yes_need_visa" v-if="form.admission_preference.leads_need_visa">                                                        
                                                        <div class="form-group col-lg-6">
                                                            <label class="form_label" for="visa_type">Visa Type <span class="text-danger">*</span></label>
                                                            <treeselect id="visa_type" :options="visaTypeList" v-model="form.admission_preference.visa_type" placeholder="Select Visa Type" autocomplete="off"/>
                                                        </div>
                                                        <div class="form-group col-lg-6" v-if="form.admission_preference.leads_need_visa == 'yes'">
                                                            <label class="form_label" for="country">Country you are Appling from <span class="text-danger">*</span></label>
                                                            <treeselect id="country" :options="countryAllList" v-model="form.admission_preference.country" placeholder="Select Country" autocomplete="off"/>
                                                        </div>
                                                        <div class="form-group col-lg-6" v-else>
                                                            <label class="form_label">Visa Expiry Date <span class="text-danger">*</span></label>
                                                            <div class="input-group input-group-sm dateNicon">
                                                                <flat-pickr
                                                                id="expire_date"
                                                                v-model="form.admission_preference.expire_date"
                                                                :config="configs.flatpickr_expire_date"
                                                                class="form-control date2 flatpickr-input"
                                                                placeholder="Select Expiry Date"
                                                                />
                                                                <span class="input-group-text">
                                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--.row-->
                        </div>
                        <div class="text-right mt-1 pr-1">
                            <button class="btn cancel_button mr-1 btn-cancel" @click="formClose()" style="padding: 4px 8px !important;">
                                Cancel
                            </button>
                            <button type="submit" v-if="form.admission_preference.enrollment_id" @click="updateData()" class="submit_button">Update</button>
                            <button type="submit" v-else @click="saveData()" class="submit_button">Save</button>
                        </div>
                </div>
            </b-modal>
        </div>
    </div>

</template>

<script>
import swal from "bootstrap-sweetalert";
import Treeselect from "@riophae/vue-treeselect";
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import moment from 'moment';
import { BModal } from 'bootstrap-vue';

export default {
  name: "AdmissionPreference",
  components: {
      Treeselect,
      BModal,
      BDropdown, BDropdownItem
  },
  data() {
    return {
        flag: {
            step_completed: null
        },
        hasPassport: true,
        needVisaToStudy: false,
        form: {
            admission_preference: {
                lead_id: '',
                enrollment_id: '',
                destination_id: null,
                institute_type: null,
                uni_group_id: null,
                level_id: null,
                course_group_id: null,
                institute_id: null,
                subject: '',
                year: null,
                month: null,
                enrollement_priority_id: null,
                leads_need_visa: '',
                visa_type: null,
                country: null,
                expire_date: null,
                immigration_destination_exist: true,
            },
        },
        configs: {
            flatpickr_expire_date: {
                dateFormat: "d M Y",
                minDate: "today",
            },
        },
        leadList: [],
        levelList: [],
        coursegroupList: [],
        universityGroupList: [],
        yearList: [],
        monthList: [],
        instituteTypeList: [],
        countryList: [],
        universityList: [],
        groupRelatedUniversityList: [],
        visaTypeList: [],
        listData: [],
        enrollmentPriorityList: [
            {
                id: '1',
                label: '1st Choice'
            },
            {
                id: '3',
                label: '2nd Choice'
            },
            {
                id: '2',
                label: '3rd Choice'
            }
        ],
    };
  },
  watch: {
      'form.admission_preference.institute_type': function () {
          if (this.form.admission_preference.institute_type != 'pathway') {
              this.form.admission_preference.uni_group_id = null
          }
      },
      'form.admission_preference.destination_id': function (destinationId) {
        console.log('ok')
          if (destinationId) {
            
            this.immigrationStatuCheck()
            
          }
      },
      'form.admission_preference.leads_need_visa': async function (leadsNeedVisa) {
          if (leadsNeedVisa) {
            var ref = this;
            var jq = ref.jq();

            var url = ref.url(
                "api/v2/student/ajax/full_profile_stage_passport_details"
            );

            try {
                var res = await jq.get(url);
                ref.hasPassport = res.data.has_passport;
            } catch (err) {
                ref.alert(ref.err_msg(err), "error");
            }
          }
      },
  },
  computed: {
      checkPathwayProvider: function () {
          const institute_type = this.form.admission_preference.institute_type
          if (this.form.admission_preference.destination_id && institute_type == 'pathway') {
              return false
          } else {
            return true
          }
      },
      instituteNameTreeSelectList: function () {
          const destinationId = this.form.admission_preference.destination_id
          if (destinationId) {
              const destinationWiseUniversityList = this.universityList.filter(item => item.country_id == destinationId)
              if (destinationWiseUniversityList) {
                return destinationWiseUniversityList.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })
              } else {
                  return ''
              }
          } else {
              return ''
          }
      },
      instituteNameTreeSelectList2: function () {
          let dataList = []
            if (this.form.admission_preference.institute_type) {
                if (this.form.admission_preference.institute_type != 'pathway') {
                    dataList = this.universityList.filter(item => item.uni_type == this.form.admission_preference.institute_type)
                } else if (this.form.admission_preference.institute_type == 'pathway') {
                    const uni_group_id = this.form.admission_preference.uni_group_id
                    if (uni_group_id) {
                        const groupRelatedList = this.groupRelatedUniversityList.filter(item => item.group_id == uni_group_id)
                        dataList = groupRelatedList.map(data => {
                            const itemObj = this.universityList.find(item => item.id == data.university_id)
                            const objData = {
                                id: itemObj ? itemObj.id : '',
                                label: itemObj ? itemObj.name : ''
                            }
                            return Object.assign(objData)
                        })
                    }
                }
            }else {
                dataList = []
            }
            return dataList
      },
      universityGroupTreeSelectList: function () {
          let dataList = []
          if (this.form.admission_preference.destination_id) {
              const localList = this.universityGroupList.filter(item => item.country_id == this.form.admission_preference.destination_id)
              if (this.form.admission_preference.institute_type == 'pathway') {
                  dataList = localList
              } else {
                  dataList = []
              }
          } else {
              dataList = this.universityGroupList
          }
          return dataList
      },
      countryAllList: function () {
          return this.$store.state.site.countries
      },
      treeselectMonthList: function () {
        let date = new Date();
        let longMonth = date.toLocaleString('en', { month: 'long' });

        const currentMonthIndex = this.monthList.findIndex(item => item.label == longMonth)
        // console.log('currentMonthIndex', currentMonthIndex)
        
        const year = this.form.admission_preference.year
        if (year) {
            if (year == date.getFullYear()) {
                const customMonths = []
                this.monthList.map((item, index) => {
                    if (index >= currentMonthIndex) {
                        const element = {
                            id: item.id,
                            label: item.label
                        }
                        customMonths.push(element)
                    }
                })
                return customMonths
            } else {
                return this.monthList
            }
        } else {
            const customMonths = []
            this.monthList.map((item, index) => {
                if (index >= currentMonthIndex) {
                    const element = {
                        id: item.id,
                        label: item.label
                    }
                    customMonths.push(element)
                }
            })
            return customMonths
        }

      }
  },
  methods: {
    openAdmissionPreferenceModal: function () {
        this.resetFormData()
        this.$refs.admission_preference_modal.show()
    },
    closeAdmissionPreferenceModal: function () {
        this.resetFormData()
        this.$refs.admission_preference_modal.hide()
    },
    leads_need_visa_change: function(){
        console.log(this.form.admission_preference.leads_need_visa);
        this.form.admission_preference.leads_need_visa = this.form.admission_preference.leads_need_visa
    },
    editData: function (item) {
        console.log(item);
        this.resetFormData()
        
        let startDate ='';
        if(item.start_date){
            startDate = item.start_date.split('-')
        }
        let instituteType 
        if (item.group_id) {
            instituteType = 'pathway'
        } else {
            instituteType = item.university ? item.university.uni_type : ''
        }
        this.form.admission_preference.destination_id=item.destination_id
        this.form.admission_preference.lead_id=item.lead_id
        this.form.admission_preference.enrollment_id=item.id
        this.form.admission_preference.institute_type=instituteType
        this.form.admission_preference.uni_group_id=item.group_id
        this.form.admission_preference.level_id=item.level_id
        this.form.admission_preference.course_group_id=item.course_group_id
        this.form.admission_preference.institute_id=item.university_id
        this.form.admission_preference.subject=item.subject
        this.form.admission_preference.enrollement_priority_id=item.priority
        this.form.admission_preference.year=startDate ? startDate[0] : ''
        this.form.admission_preference.month=startDate ? startDate[1] : ''
        this.form.admission_preference.immigration_destination_exist=false

        // this.form.admission_preference = {
        //     lead_id: item.lead_id,
        //     enrollment_id: item.id,
        //     institute_type: instituteType,
        //     uni_group_id: item.group_id,
        //     level_id: item.level_id,
        //     course_group_id: item.course_group_id,
        //     institute_id: item.university_id,
        //     subject: item.subject,
        //     year: startDate ? startDate[0] : '',
        //     month: startDate ? startDate[1] : '',
        //     enrollement_priority_id: item.priority,
        //     immigration_destination_exist: false
        // }

        if (item.lead_need_visa) {
            // this.form.admission_preference.leads_need_visa = item.lead_need_visa.need_visa ? '1' : '0'
            this.form.admission_preference.leads_need_visa = item.lead_need_visa.need_visa 
            this.form.admission_preference.visa_type = item.lead_need_visa.visa_type ? item.lead_need_visa.visa_type.id : ''
            console.log('this.form.admission_preference.visa_type', this.form.admission_preference.visa_type)
            this.form.admission_preference.country = item.lead_need_visa.country
            this.form.admission_preference.expire_date = item.lead_need_visa.expiry_date ? moment(item.lead_need_visa.expiry_date).format("DD MMM YYYY") : ''
        }
        this.$refs.admission_preference_modal.show()
    },
    formClose: function () {
        // this.$emit('stage-close')
        this.$refs.admission_preference_modal.hide()
        this.resetFormData()
    },
    resetFormData: function () {
        this.form.admission_preference = {
            lead_id: '',
            enrollment_id: '',
            destination_id: null,
            institute_type: null,
            uni_group_id: null,
            level_id: null,
            course_group_id: null,
            institute_id: null,
            subject: '',
            year: null,
            month: null,
            enrollement_priority_id: null,
            leads_need_visa: '',
            visa_type: null,
            country: null,
            expire_date: null,
            immigration_destination_exist: true,
        }
    },
    initialData: async function () {
      var ref = this;
      var jq = ref.jq();

      try {
        var res = await jq.get(ref.url("api/v2/student/ajax/admission_preference_initial_data"));
        this.listData = res.data.admission_pref_list

        if (res.data.lead && res.data.lead.length) {
            this.leadList = res.data.lead.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }
        
        if (res.data.levels && res.data.levels.length) {
            this.levelList = res.data.levels.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }

        if (res.data.course_groups && res.data.course_groups.length) {
            this.coursegroupList = res.data.course_groups.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }

        if (res.data.institute_types && res.data.institute_types.length) {
            this.instituteTypeList = res.data.institute_types.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }

        if (res.data.universityGroup && res.data.universityGroup.length) {
            this.universityGroupList = res.data.universityGroup.map(item => {
                const customData = {
                    id: item.id,
                    label: item.name
                }
                return Object.assign(item, customData)
            })
        }

        if (res.data.market_countries && res.data.market_countries.length) {
            this.countryList = res.data.market_countries.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }

        if (res.data.universities && res.data.universities.length) {
            this.universityList = res.data.universities.map(item => {
                const customData = {
                    id: item.id,
                    label: item.name
                }
                return Object.assign(customData, item)
            })
        }

        if (res.data.group_related_universities) {
            this.groupRelatedUniversityList = res.data.group_related_universities.map(item => {
                const customData = {
                    id: item.id,
                    label: item.name
                }
                return Object.assign(customData, item)
            })
        }

        if (res.data.years && res.data.years.length) {
            this.yearList = res.data.years.map(item => {
                return {
                    id: item,
                    label: item
                }
            })
        }

        if (res.data.months) {
            // let date = new Date();
            // let longMonth = date.toLocaleString('en', { month: 'long' });
            
            const monthArray = Object.entries(res.data.months)
            // const currentMonthIndex = monthArray.findIndex(item => item[1] == longMonth)

            // for (let index = currentMonthIndex; index < monthArray.length; index++) {
            //     const element = {
            //         id: index,
            //         label: monthArray[index][1]
            //     }
            //     this.monthList.push(element)
            // }
            this.monthList = monthArray.map(item => {
                return {
                    id: item[0],
                    label: item[1]
                }
            })
        }

        if (res.data.visa_types && res.data.visa_types.length) {
            this.visaTypeList = res.data.visa_types.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }
        ref.flag.step_completed = res.data.step_completed;
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      }

    },
    immigrationStatuCheck: async function () {
        var ref = this;
        var jq = ref.jq();

        try {
            const params = Object.assign({}, this.form.admission_preference)
            var res = await jq.get(ref.url("api/v2/student/ajax/check_lead_immigration_destination"), params);
            ref.form.admission_preference.immigration_destination_exist = false
            if(res.data.exist){
                ref.form.admission_preference.leads_need_visa=res.data.lead_need_visa.need_visa
                ref.form.admission_preference.visa_type=res.data.lead_need_visa.visa_type
                ref.form.admission_preference.country=res.data.lead_need_visa.country
                ref.form.admission_preference.expire_date=res.data.lead_need_visa.expiry_date ? moment(res.data.lead_need_visa.expiry_date).format("DD MMM YYYY") : ''
            }else{
                ref.form.admission_preference.leads_need_visa=''
                ref.form.admission_preference.visa_type=''
                ref.form.admission_preference.country=''
                ref.form.admission_preference.expire_date=''
            }
        } catch (err) {
            ref.alert(ref.err_msg(err), "error");
        }
    },
    saveData: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/save_admission_preference_data");
      var jq = ref.jq();

      try {

        ref.wait_me(".create_admission_preference");
        var res = await jq.post(url, ref.form.admission_preference);
        ref.alert(res.msg);
        ref.resetFormData();
        ref.initialData();
        ref.closeAdmissionPreferenceModal()
        this.$emit('dashboard-info-update', true)

      } catch (error) {
        ref.alert(ref.err_msg(error), "error");
      } finally {
        ref.wait_me(".create_admission_preference", "hide");
      }
    },
    updateData: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/save_admission_preference_data");
      var jq = ref.jq();

      try {

        ref.wait_me(".create_admission_preference");
        const updateData = Object.assign(ref.form.admission_preference)
        var res = await jq.post(url, updateData);
        ref.alert(res.msg);
        ref.resetFormData();
        ref.initialData();
        ref.closeAdmissionPreferenceModal()
        this.$emit('dashboard-info-update', true)

      } catch (error) {
        ref.alert(ref.err_msg(error), "error");
      } finally {
        ref.wait_me(".create_admission_preference", "hide");
      }
    },
    deleteData: function(item){

      var ref = this;
      var jq = ref.jq();

      swal({
        title: "Are you sure? You want to delete this admission preference data",
        text: "Press Yes to confirm.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(ref.url("api/v2/student/ajax/delete_admission_preference_data"), item);
                ref.alert(res.msg);
                ref.initialData();
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    },
    complete_this_step: async function(){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/lead_admission_preference_step_completed");
      var jq = ref.jq();

      swal({
        // title: "Are you sure? You want to submit your admission preference information as completed.",
        title: "Are you sure?",
        text: "Press Yes to confirm.",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url);
                ref.alert(res.msg);
                ref.initialData();

                ref.$emit("save-and-completed", true);
                
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    }
  },
  created: function () {
    this.setup_urls();
    this.initialData();
  },
};
</script>

<style>
.pref_form_switch_part .apply_switch .swraper.light .stoggler.on{
    background: #17092a;
}
.pref_form_switch_part .apply_switch .swraper.light .stoggler{
    height: 17px;
}
.pref_form_switch_part .apply_switch .swraper.light .stoggler .sblob{
    height: 13px !important;
    width: 13px !important;
}
.pref_form_switch_part .swraper.light .stoggler .slabel-on, .swraper.light .stoggler .slabel-off{
    margin-top: -1px;
}


.scrolled_content_part {
padding: 0;
}
.scrolled_content_part::-webkit-scrollbar {
width: 7px;
}
.scrolled_content_part::-webkit-scrollbar-track {
background: #fff;
border-radius: 5px;
}
.scrolled_content_part::-webkit-scrollbar-thumb {
background: linear-gradient(90deg, rgba(183,180,212,1) 0%, rgb(165 164 181) 0%, #fff 75%);
border-radius: 5px;
}
.scrolled_content_part .single_part {
display: flex;
width: 100%;
padding: 7px 16px;
margin-top: 10px;
border-radius: .25rem;
border: 1px solid #e9f0f3;
align-items: center;
}

.scrolled_content_part .single_part .left {
width: 100%;
position: relative;
}
.scrolled_content_part .single_part .left h3, .scrolled_content_part .single_part .right h3 {
font-size: 11px;
font-weight: bold;
color: rgb(98, 95, 139);
margin-bottom: 6px;
}
.scrolled_content_part .single_part .left .first_part{
display: flex;
width: 100%;
}
.scrolled_content_part .single_part .left .first_part .first_part_left img{
margin-right: 8px;
width: 28px;
height: 28px;
border-radius: 100%;
}
.scrolled_content_part .single_part .left .first_part .first_part_right{
line-height: 1.2;
width: 95%;
}
.scrolled_content_part .single_part .left .first_part .first_part_left{
width: 40px;
align-self: center;
}
.scrolled_content_part .single_part .left .first_part .first_part_right .edu_level_label{
color: rgb(0 168 255);
border-radius: 20px;
padding: 1px 10px;
font-size: 11px;
font-weight: 400;
line-height: 1.3;
background: #e4f2f9;
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
}
.tiny_circle_dot {
width: 15px;
height: 15px;
line-height: 15px;
border-radius: 100%;
font-size: 10px;
color: #fff;
text-align: center;
    display: inline-block;
}
.no_dot{
background: #635858;
}
.yes_dot{
background: cornflowerblue;
}
.sams_apply_dot{
background: #2e1153;
font-size: 8px;
}
.first_choice_dot{
background: #b9f3dc;
color: #009845;
font-size: 10px;
}
.second_choice_dot{
background: #ffe4b3;
color: orange;
font-size: 10px;
}
.info_middle {
margin: 0;
position: absolute;
top: 50%;
left: 50%;
margin-right: -50%;
transform: translate(-50%, -50%);
font-size: 12px;
}

.destination_bordered_box{
text-align-last: start;
margin: 10px 0 20px;
border: 1px solid #d8e2e7;
padding: 15px;
border-radius: .25rem;
position: relative;
}
.title_in_border{
background: white;
width: fit-content;
padding: 5px;
font-size: 13px;
font-weight: 600;
top: -14px;
position: absolute;
left: 12px;
}
.destination_bordered_box .img_box img:first-child{
margin-left: -2px;
}

.scrolled_content_part_outer{
    position: relative;
    border-radius: .25rem;
    margin-top: 10px;
}
.scrolled_content_part_outer .title_in_border {

    background: #dedbf1;
    padding: 7px 16px;
    margin-top: 10px;
    border-radius: 30px;
    position: relative;
    font-size: 12px;
    font-weight: 700;
    width: inherit;
    left: 0;
    top: -10px;
}
.scrolled_content_part_outer .label_badge_light {
    border-radius: 20px;
    padding: 1px 10px;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.3;
    /*background: #e0ddf2;*/
    letter-spacing: .2px;
    color: black;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: linear-gradient(to right, #dedbf1 , #f5f4fd);
}
.action_dropdown .dropdown-toggle{
    background: #dedbf1;
    padding: 4px 9px 3px;
    border-radius: 50%;
    font-size: 13px;
    border: 1px solid transparent;
    color: #826aa0;
    cursor: pointer;
}
.action_dropdown .dropdown-toggle:hover {
    color: #ff7f00 !important;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    border: 1px solid #ff7f00;
}
.action_dropdown .dropdown-toggle:after{
    display: none;
}
.action_dropdown .dropdown-menu.show{
    margin-left: -55px;
    min-width: auto;
    padding: 0;
    margin-top: 3px;
}
.action_dropdown .dropdown-menu.show .dropdown-item{
    background: #dedbf1;
    color: #2e1153;
    font-size: 12px;
    border: none;
    padding: 4px 12px;
}
.action_dropdown .dropdown-menu.show .dropdown-item:hover{
    background: #2e1153;
    color: #fff !important;
}
.action_dropdown .dropdown-menu.show .activate{
    background: #2e1153;
    color: #fff;
}
.action_dropdown .dropdown-menu.show .dropdown-item:first-child{
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.action_dropdown .dropdown-menu.show .dropdown-item:last-child{
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.scrolled_content_part .single_part:hover {
    background: #f5f4fd;
}

.input-group-addon:hover {
    background-color: #00a8ff !important;
    border: none !important;
    border-color: #00a8ff !important;
    color: #fff !important;
}
.dateNicon input {
    background: #fff !important;
    border-right: none;
}
.input-group-addon {
    background: #fff;
    padding: 0 .75rem;
}
.glyphicon{
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
}

.form_upper_title {
    border-bottom: 1px solid #2e1153;
    padding: 2px 0px;
    border-top: none;
    border-bottom-right-radius: 0;
    margin: 8px 0 15px;
    font-size: 14px;
    font-weight: 600;
    color: #2e1153;
}
.form_subtitle {
    font-size: 13px;
    font-weight: 500;
    color: #2e1153;
}




    .ad_pref_part .single_ad_pref_item{
        display: flex;
        padding: 7px 16px;
        margin-top: 10px;
        border-radius: 30px;

        position: relative;
        border: 1px solid #e9f0f3;

        justify-content: space-between;
    }
    .ad_pref_part .single_ad_pref_item:hover{
        background: #f5f4fd;
    }
    .ad_pref_part .single_ad_pref_item .left{
        /*width: calc(100% - 695px);*/
        align-self: center;
        width: 300px;
    }
    .ad_pref_part .single_ad_pref_item .left strong{
        color: rgb(57, 59, 62);
        font-size: 12px;
    }
    .ad_pref_part .single_ad_pref_item .middle{
        width: 200px;
        align-self: center;

        margin-right: 25px;
    }
    .ad_pref_part .single_ad_pref_item .status_column{
        width: 50px;
        align-self: center;
        text-align: center;
    }
    .ad_pref_part .single_ad_pref_item .right{
        width: 125px;
        align-self: center;
        text-align: -webkit-right;

        /*margin-right: 25px;*/
    }
    .ad_pref_part .single_ad_pref_item .right .btn-secondary{
        /*background-color: #f5f4fd;
        border-color: #f5f4fd;
        color: #868e96 !important;*/
        background: transparent !important;
        border-color: transparent !important;

        padding: 0;
    }
    .ad_pref_part .single_ad_pref_item .right .btn-secondary:hover{
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    }
    .ad_pref_part .single_ad_pref_item .right .btn-secondary .ad_pref_round_action{
        margin-right: 0;

        background: #dedbf1;
        padding: 4px 8px 2px;
        border-radius: 50%;
        font-size: .8rem;
        border: 1px solid transparent;
        color: #2e1153;
        cursor: pointer;
    }
    .ad_pref_part .single_ad_pref_item .right .btn-secondary .ad_pref_round_action:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .ad_pref_part .single_ad_pref_item .destination{
        width: 295px;
        align-self: center;
        display: flex;
    }
    .ad_pref_part .single_ad_pref_item .destination_left{
        width: 30px;
        align-self: center;
    }
    .ad_pref_part .single_ad_pref_item .destination .destination_left img{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-left: -2px;
    }
    .ad_pref_part .single_ad_pref_item .destination_info{
        width: calc(100% - 20px);
    }
    .ad_pref_part .single_ad_pref_item .destination_info .visa_country{
        font-size: 11px;
        color: #868e96;
    }
    .ad_pref_part .single_ad_pref_item .right .rounded_icon_bar{
        width: fit-content;
        overflow: auto;
        border-radius: .25rem;
        /*margin: 0 auto;*/
        display: flex;
    }
    .ad_pref_part .single_ad_pref_item .right .rounded_icon_bar a {
        text-align: center;
        padding: 0;
        color: #c3c3c3;
        font-size: 16px;
        margin: 0 3px;
        border-bottom: none;
        top: 0;

        display: block;
    }
    .ad_pref_part .single_ad_pref_item .right .rounded_icon_bar .fa{
        font-size: .9rem;
        padding: 3px 5px;
    }
    .ad_pref_part .single_ad_pref_item .right .rounded_icon_bar .btn_round{
        border: .5px solid #d8e2e7;
        border-radius: 50%;
        /*width: 25px;
        height: 25px;*/
    }
    .ad_pref_part .single_ad_pref_item .right .rounded_icon_bar .btn_round:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .ad_pref_part .single_ad_pref_item .right .rounded_icon_bar img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-left: 3px;
    }
    .ad_pref_part .single_ad_pref_item .label_badge_radius {
        background-image: linear-gradient(to right, rgb(46 17 83 / 19%), rgb(245, 244, 253));
        color: rgb(46, 17, 83);
        border-radius: 20px;
        padding: 1px 10px;
        font-size: 11px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: .2px;
    }

    .ad_pref_part .single_ad_pref_item .yes_no_drop{
        top: 12px;
        right: 15px;
        position: absolute;
    }
    .ad_pref_part .single_ad_pref_item .ad_pref_more_drop .dropdown-toggle:after{
        display: none;
    }
    .ad_pref_part .single_ad_pref_item .yes_no_drop .dropdown-toggle{
        color: #c3c3c3;
        cursor: pointer;
        border: .5px solid #d8e2e7;
        border-radius: 50%;
        padding: 3px 5px;
        font-size: .9rem;
        padding: 3px 5px;
    }
    .ad_pref_part .single_ad_pref_item .ad_pref_more_drop .dropdown-menu.show{
        margin-left: -97px;
        min-width: auto;
        padding: 0;

        z-index: 2;
    }
    .ad_pref_part .single_ad_pref_item .ad_pref_more_drop .dropdown-menu.show .dropdown-item{
        background: #dedbf1;
        color: #2e1153;
        font-size: 12px;
        border: none;
    }
    .ad_pref_part .single_ad_pref_item .ad_pref_more_drop .dropdown-menu.show .dropdown-item:hover{
        background: #2e1153;
        color: #fff !important;
    }
    .ad_pref_part .single_ad_pref_item .ad_pref_more_drop .dropdown-menu.show .activate{
        background: #2e1153;
        color: #fff;
    }
    .ad_pref_part .single_ad_pref_item .ad_pref_more_drop .dropdown-menu.show .dropdown-item:first-child{
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
    .ad_pref_part .single_ad_pref_item .ad_pref_more_drop .dropdown-menu.show .dropdown-item:last-child{
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }

    .ad_pref_part .ad_pref_item_header .destination {
        width: 295px;
        align-self: center;
    }
    .ad_pref_part .ad_pref_item_header .second {
        /*width: calc(100% - 695px);*/
        align-self: center;
        width: 300px;
    }
    .ad_pref_part .ad_pref_item_header .third {
        width: 200px;
        align-self: center;
        margin-right: 25px;
    }
    .ad_pref_part .ad_pref_item_header .fourth {
        width: 50px;
        align-self: center;
        text-align: center;
    }
    .ad_pref_part .ad_pref_item_header .last {
        width: 125px;
        align-self: center;
        text-align: -webkit-right;
        /*margin-right: 25px;*/
    }
    .ad_pref_part .ad_pref_item_header {
        display: flex;
        background: #dedbf1;
        padding: 7px 16px;
        margin-top: 10px;
        border-radius: 30px;
        position: relative;
        font-size: 12px;
        font-weight: 700;

        justify-content: space-between;
    }

    /* .ad_pref_part .single_ad_pref_item .destination_info strong{
        font-size: .782rem;
    } */
    .ad_pref_part .single_ad_pref_item .left .uni_details {
        display: flex;
        width: 100%;
    }
    .ad_pref_part .single_ad_pref_item .left .uni_details .inner_left {
        width: 33px;
        align-self: center;
    }
    .ad_pref_part .single_ad_pref_item .left .uni_details .inner_right {
        width: calc(100% - 33px);
    }
    .ad_pref_part .single_ad_pref_item .left .uni_details .inner_left .fa-paper-plane {
        color: #fff;
        background: #2e1153;
        border-radius: 100%;
        font-size: .68rem;
        padding: 5px 6px 5px 4px;
        /*border: 1px solid #ceccda;*/
    }
    .ad_pref_part .app_limit_div{
        background: #f5f4fd;
        border: 1px solid #dedbf1;
        font-size: 13px;
        padding: 5px 10px;
        border-radius: .25rem;
        width: fit-content;
        color: black;
    }

    .ad_pref_part .single_ad_pref_item .left .uni_details .inner_left .fa-university {
        color: #2e1153;
        background: #f1f0fb;
        border-radius: 100%;
        padding: 5px;
        /*border: 1px solid #ceccda;*/
    }
/*#edit_btn {
    background: #dedbf1 !important;
    padding: 6px 8px 8px 8px !important;
    height: 20px !important; 
    width: 20px !important; 
    border-radius: 100%;
    margin-top: -7px;
}*/
.drop_fix .dropdown-toggle-no-caret{
    padding: 0 !important;
    border: none !important;
}
.drop_fix #edit_btn{
    font-size: 13px;
    color: #826aa0;
    padding: 4px 9px 3px;
}
.drop_fix .dropdown-menu.show{
    margin-left: -20px;
    margin-top: 15px;
}
</style>
