<template>
  <div v-if="studentAllInfo.lead" style="height: auto !important;">
    <div class="box-typical box-typical-padding mb-0 p-0" id="crm_profile_div" style="background: #fff; border: none;">
            <!-- <div class="row" v-if="studentAllInfo.lead && studentAllInfo.lead.file_number"> -->
                

            <!-- <div> -->
                <!-- <hr style="margin: 0 -16px; height: auto;" v-if="adminssionPreferences && adminssionPreferences.length"/> -->

                <div class="ad_pref_part">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-lg-10 col-8">
                                    <div class="content_header ml-0">
                                        <div class="left">
                                        <i class="fa fa-university" style="padding: 7px;"></i>
                                        </div>
                                        <div class="right">
                                        <span class="main_title">Admission Preference</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-4 text-right">
                                    <button v-if="listData.length <=3 && !studentAllInfo.lead.file_number && !studentAllInfo.student.steps_submitted_at" class="submit_button" @click="openAdmissionPreferenceModal()"> Add New </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 create_admission_preference mb-2">
                            <div class="ad_pref_item_header" style="margin-top: 15px;">
                                <div class="destination">
                                    Destination
                                </div>
                                <div class="second">
                                    University Details
                                </div>
                                <div class="third">
                                    Course Information
                                </div>
                                <!-- <div class="fourth" v-if="!studentAllInfo.lead.file_number && !studentAllInfo.student.steps_submitted_at">
                                    Status
                                </div> -->
                                <div class="fourth">
                                    Status
                                </div>

                                <div class="last">
                                    Action
                                </div>
                            </div>

                            <div class="scrolled_content_part" v-if="adminssionPreferences && adminssionPreferences.length">
                                <div class="single_ad_pref_item" v-for="(item, index) in adminssionPreferences" :key="index">
                                    <div class="destination">
                                        <div class="destination_left" v-if="item.country">
                                            <img :src="item.country.flag_url" alt="Flag" v-tooltip="item.country.name" />
                                        </div>
                                        <div class="destination_info">
                                            <strong v-if="item.country" v-html="item.country.name"></strong>

                                            <div class="visa_country">
                                                <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'yes'" v-tooltip="'Visa Required'" class="label_badge_radius" style="background: #e7f3ea; color: #0ba52e; padding: 1px 10px; line-height: 15px;margin-top: 0px;margin-right: 3px;  display: inline-block; border: 1px solid #fff;">
                                                    Yes
                                                </span>
                                                <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'no'" v-tooltip="'Visa Required'" class="label_badge_radius" style="background: #f3dadc; color: #de1528; padding: 1px 10px; line-height: 15px;margin-top: 0px;margin-right: 3px;  display: inline-block; border: 1px solid #fff;">
                                                    No
                                                </span>
                                                
                                                <span v-if="item.lead_need_visa && item.lead_need_visa.visa_type" v-html="item.lead_need_visa.visa_type.name" v-tooltip="'Visa Type'"></span>

                                                <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'yes' && item.lead_need_visa.country_info"> • </span>
                                                <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'yes' && item.lead_need_visa.country_info" v-html="item.lead_need_visa.country_info.name" v-tooltip="'Country of Application'"></span>

                                                <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'no' && item.lead_need_visa.expiry_date"> • </span>
                                                <span v-if="item.lead_need_visa && item.lead_need_visa.need_visa == 'no' && item.lead_need_visa.expiry_date" v-html="dDate(item.lead_need_visa.expiry_date)" v-tooltip="'Visa Expiry Date'"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="left">
                                        <div class="uni_details">
                                            <div class="inner_left" v-if="item.university">
                                                <i class="fa fa fa-university"></i>
                                            </div>
                                            <div class="inner_right">
                                                <strong v-if="item.university" v-html="item.university.name"></strong> <br />
                                                <span class="label_badge_radius" v-if="item.level" v-html="item.level.name"></span>
                                                <span v-if="item.level && item.start_date"> • </span>
                                                <span class="label_badge_radius" v-if="item.start_date" v-html="dMonth(item.start_date)"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="middle">
                                        <span v-html="item.subject"></span><br />
                                        
                                        <span v-if="item.priority == 1" class="label_badge_radius" style="background: #d2f9ea; color: #009845;">1st Choice</span>
                                        <span v-else-if="item.priority == 3" class="label_badge_radius" style="background: #ffedcc; color: orange;">2nd Choice</span>
                                        <span v-else-if="item.priority == 2" class="label_badge_radius" style="background: #dbdbdb; color: #686767;">3rd Choice</span>
                                    </div>
                                    <div class="status_column">
                                        <i v-if="item.pre_status" class="fa fa-check-circle" v-tooltip="'Converted'" style="font-size: 17px; color: #2e1153;"></i>
                                    </div>

                                    <div class="right">
                                        <div v-if="!studentAllInfo.lead.file_number && !studentAllInfo.student.steps_submitted_at" class="btn-group btn-group-sm vue-dropdown" style="float: none;">
                                            <div v-if="!item.university" style="display: flex; justify-content: flex-end;">
                                                <span @click="editData(item)" class="btn pull-right submit_btn_sm mr-25 enroll-edit" style="margin-top: 1px;">Update</span>
                                            </div>

                                            <b-dropdown size="sm" variant="link" class="text-dark action_dropdown drop_fix" toggle-class="text-decoration-none" no-caret>
                                                <template #button-content>
                                                    <span><i class="fa fa-ellipsis-v dropdown-toggle" id="edit_btn" aria-hidden="true"></i></span>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0)" @click="editData(item)">
                                                    <i class="fa fa-pencil mr-50"></i> Edit
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0)" @click="deleteData(item)">
                                                    <i class="fa fa-trash-o text-danger mr-50"></i> Delete
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="scrolled_content_part">
                                <div class="alert mb-1" role="alert" style="border-color: #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                                    <span>No Admission Preference available.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin: 0; border-top: 25px solid rgb(248 248 248);" />

                <b-modal ref="admission_preference_modal" size="lg" centered hide-footer>
                    <!-- <template #modal-title>
                        <span class="text-primary"> {{ form.admission_preference.enrollment_id ? 'Edit' : 'Add New' }} Admission Preference</span>
                    </template> -->

                    <template #modal-header>
                      <h5 class="modal_title">{{ form.admission_preference.enrollment_id ? 'Edit' : 'Add New' }} Admission Preference</h5>
                      <button type="button" @click="$refs.admission_preference_modal.hide()">
                            <i class="font-icon-close-2"></i>
                        </button>
                    </template>

                    <div class="wait_me_insert_form">
                        <div class="form_part" style="background: #f5f4fd; padding-bottom: 10px;">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div id="enrolment_form" class="box-white" style="margin-bottom: 0px;padding: 15px 15px 16px;margin: 5px;border-radius: 0.25rem;border: none;background: #fff;">
                                            <div class="row">
                                                <input type="hidden" name="lead_id" value="29055" />
                                                <div class="form-group col-lg-6">
                                                    <label class="form_label">Study Destination<span class="text-danger">*</span></label>
                                                    <treeselect id="destination_id" :options="countryList" v-model="form.admission_preference.destination_id" placeholder="Select Study Destination" autocomplete="off"/>
                                                </div>
                                                <!-- <div class="form-group col-lg-6">
                                                    <label class="form_label">Institute Type<span class="text-danger"></span></label>
                                                    <treeselect id="institute_type" :options="instituteTypeList" v-model="form.admission_preference.institute_type" placeholder="Select Institute Type" autocomplete="off"/>
                                                </div> -->
                                                <!-- <div class="form-group col-lg-6">
                                                    <label class="form_label">Pathway Providers(if any)<span class="text-danger"></span></label>
                                                    <treeselect id="uni_group_id" :disabled="checkPathwayProvider" :options="universityGroupTreeSelectList" v-model="form.admission_preference.uni_group_id" placeholder="Select Pathway Provider" autocomplete="off"/>
                                                </div> -->
                                                <div class="form-group col-lg-6">
                                                    <label class="form_label">Institute Name<span class="text-danger"></span></label>
                                                    <treeselect id="institute_id" :options="instituteNameTreeSelectList" v-model="form.admission_preference.institute_id" placeholder="Select Institute" autocomplete="off"/>
                                                </div>
                                                <div class="form-group col-lg-6">
                                                    <label class="form_label">Level<span class="text-danger"></span></label>
                                                    <treeselect id="level_id" :options="levelList" v-model="form.admission_preference.level_id" placeholder="Select level" autocomplete="off"/>
                                                </div>
                                                <div class="form-group col-lg-6">
                                                    <label class="form_label">Preferred Department<span class="text-danger"></span></label>
                                                    <treeselect id="course_group_id" :options="coursegroupList" v-model="form.admission_preference.course_group_id" placeholder="Select Preferred Department" autocomplete="off"/>
                                                </div>
                                                <div class="form-group col-lg-12">
                                                    <label class="form_label mb-1">Course Title </label>
                                                    <input type="text" class="form-control" v-model="form.admission_preference.subject" id="subject" placeholder="Subject" />
                                                </div>
                                                <div class="form-group col-lg-6 hidden" id="level_div">
                                                    <div class="row">
                                                        <div class="form-group col-lg-6">
                                                            <label class="form_label mb-1">Supervisor 1st</label>
                                                            <input type="text" class="form-control" name="supervisor1" id="supervisor1" placeholder="Supervisor" />
                                                        </div>
                                                        <div class="form-group col-lg-6">
                                                            <label class="form_label mb-1">Supervisor 2nd</label>
                                                            <input type="text" class="form-control" name="supervisor2" id="supervisor2" placeholder="Supervisor" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-lg-6 mb-0">
                                                    <div class="row">
                                                        <div class="form-group col-lg-6">
                                                            <label class="form_label">Start Year<span class="text-danger"></span></label>
                                                            <treeselect id="year" :options="yearList" v-model="form.admission_preference.year" placeholder="Select Start Year" autocomplete="off"/>
                                                        </div>
                                                        <div class="form-group col-lg-6">
                                                            <label class="form_label">Start Month<span class="text-danger"></span></label>
                                                            <treeselect id="month" :options="treeselectMonthList" v-model="form.admission_preference.month" placeholder="Select Start Month" autocomplete="off"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-lg-6">
                                                    <label class="form_label">Enrollment Priority</label>
                                                    <treeselect id="enrollement_priority_id" :options="enrollmentPriorityList" v-model="form.admission_preference.enrollement_priority_id" placeholder="Select Enrollment Priority" autocomplete="off"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 px-0" id="lead_need_visa" v-show="!form.admission_preference.immigration_destination_exist">
                                        <div class="row pt-3">
                                            <div class="col-lg-12">
                                                <div class="" style="background: #fff; margin: 0 15px; padding: 15px; border-radius: .25rem;">
                                                    <div class="form_part pb-3" style="padding: 15px 15px 5px; background: #f5f4fd; border-radius: .25rem;">
                                                        <div class="form_upper_title"><i class="fa fa-flag mr-2" aria-hidden="true"></i>Immigration Status</div>
                                                        <div class="row">
                                                            <div class="col-md-8">
                                                                <div class="form_subtitle">Does this Applicant need visa to study?</div>
                                                            </div>

                                                            <div class="col-md-4 text-right">
                                                                <div class="radio_box" style="justify-content: right; font-size: 12px;">
                                                                    <label>
                                                                        <input class="leads_need_visa" id="yes" type="radio" v-model="form.admission_preference.leads_need_visa" value="yes" />
                                                                        <span>Yes</span>
                                                                    </label>
                                                                    <label>
                                                                        <input class="leads_need_visa" id="no" type="radio" v-model="form.admission_preference.leads_need_visa" value="no" />
                                                                        <span>No</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <!-- {{ form.admission_preference.leads_need_visa }} -->
                                                        </div>
                                                        <div class="form-row" v-if="form.admission_preference.leads_need_visa == 'yes' && !hasPassport">
                                                            <div class="col-md-12">
                                                                <div class="alert alert-danger p-1 font-size-13">Please update passport details.</div>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-1" id="yes_need_visa" v-if="form.admission_preference.leads_need_visa">                                                        
                                                            <div class="form-group col-lg-6">
                                                                <label class="form_label" for="visa_type">Visa Type <span class="text-danger">*</span></label>
                                                                <treeselect id="visa_type" :options="visaTypeList" v-model="form.admission_preference.visa_type" placeholder="Select Visa Type" autocomplete="off"/>
                                                            </div>
                                                            <div class="form-group col-lg-6" v-if="form.admission_preference.leads_need_visa == 'yes'">
                                                                <label class="form_label" for="country">Country of Application <span class="text-danger">*</span></label>
                                                                <treeselect id="country" :options="countryAllList" v-model="form.admission_preference.country" placeholder="Select Country" autocomplete="off"/>
                                                            </div>
                                                            <div class="form-group col-lg-6" v-else>
                                                                <label class="form_label">Visa Expiry Date <span class="text-danger">*</span></label>
                                                                <div class="input-group input-group-sm dateNicon">
                                                                    <flat-pickr
                                                                    id="expire_date"
                                                                    v-model="form.admission_preference.expire_date"
                                                                    :config="configs.flatpickr_expire_date"
                                                                    class="form-control date2 flatpickr-input"
                                                                    placeholder="Select Expiry Date"
                                                                    />
                                                                    <span class="input-group-text">
                                                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--.row-->
                            </div>
                            <div class="text-right mt-1 pr-1">
                                <button class="btn cancel_button mr-1 btn-cancel" @click="formClose()" style="padding: 4px 8px !important;">
                                    Cancel
                                </button>
                                <button type="submit" v-if="form.admission_preference.enrollment_id" @click="updateData()" class="submit_button">Update</button>
                                <button type="submit" v-else @click="saveData()" class="submit_button">Save</button>
                            </div>
                    </div>
                </b-modal>
            <!-- </div> -->
            <div class="row" v-if="convertedApplications.length">
                <div class="col-md-12">
                    <div class="row" style="background: #f8f8f8;">
                        <div class="col-md-5">
                            <span class="badge badge-primary" style="border-color: transparent; background-color: rgba(11, 1, 70, 0.2) !important; color: rgb(11, 1, 70); padding: 8px 11px !important; border-radius: 30px; font-size: 14px !important;">
                                Total Application
                                <span class="badge badge-success" style="background: rgb(46, 17, 83); font-weight: 200; font-size: 11px !important; border-radius: 50%; padding: 3px 6px !important;margin-left: 8px;">
                                {{ convertedApplications.length }}
                                </span>
                            </span>
                            <!-- <span class="badge badge-primary" style="border: 0.5px solid #C4C2D2; background-color: #FFF !important; color: rgb(11, 1, 70); padding: 8px 11px !important; border-radius: 30px; font-size: 14px !important;margin-left: 10px;">
                                Visa Letter Received
                                <span class="badge badge-success" style="background: #00008B; font-weight: 200; font-size: 11px !important; border-radius: 50%; padding: 3px 6px !important;margin-left: 8px;">
                                {{ convertedApplications.length }}
                                </span>
                            </span> -->
                            <!-- <div class="content_header">
                                <div class="left">
                                    <i class="fa fa-university" style="padding: 7px;"></i>
                                </div>
                                <div class="right">
                                    <span class="main_title">Enrollment</span>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-md-7 text-right"></div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <section class="box-typical" style="background: #f8f8f8; margin-bottom: 0; border: solid 0px #d8e2e7; padding: 4px 4px 8px; border-radius: 0.25rem; margin-top: 0px;">
                        <div class="enrollments">
                            <div class="enrollment-box " v-for="(adminssionPreference, index) in convertedApplications" :key="index" style="background: #fff; margin-top: 10px; padding: 5px; border-radius: 3px; margin-bottom: 18px;">
                                <div class="row">
                                    <div class="col-md-8 pr-0 col-12 enrollment_left_responsive">
                                        <div style="border: 1px solid #dedbf1; border-radius: 0.25rem;">
                                        <div class="box-typical-header" style="background: #fff;border:0px;border-bottom: .5px dashed #e3e8ec; border-top-left-radius: .25rem; border-top-right-radius: .25rem; min-height: 55px;">
                                            <div class=" d-flex enrollment_header">
                                                <div class="tbl-cell tbl-cell-action-bordered" style="padding: 5px !important; width: 50px; align-self: center; text-align: center;">
                                                    <img class="img-responsive" style="height: 30px; border-radius: 50%; width: 30px;"
                                                    v-if="adminssionPreference.country"
                                                    :src="adminssionPreference.country.flag_url"
                                                    alt="Flag"
                                                    />
                                                </div>
                                                <div class="tbl-cell tbl-cell-action-bordered text-left" style="padding:5px !important; width: 50%;">
                                                    <b v-if="adminssionPreference.university" v-html="adminssionPreference.university.name"></b>
                                                    <br />
                                                    <span class="label_badge_radius" v-if="adminssionPreference.level" v-html="adminssionPreference.level.name"></span>
                                                </div>
                                                <div class="tbl-cell tbl-cell-action-bordered text-left" style="padding:5px !important;width: 15%;">
                                                    <small class="text-muted">Start Date</small>
                                                    <br>
                                                    <b style="font-size: 12px;" v-if="adminssionPreference.start_date" v-html="dMonth(adminssionPreference.start_date)"></b>
                                                </div>
                                                <div class="tbl-cell tbl-cell-action-bordered text-left" style="padding:5px !important;width: 20%;">
                                                    <small class="text-muted">Application Status</small>
                                                    <br>
                                                    <strong style="font-size: 12px;" v-if="adminssionPreference.app_status" v-html="adminssionPreference.application_status==1 || adminssionPreference.application_status==2?'In Process':adminssionPreference.app_status.name"></strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-typical-header border_2" style="background: #fff;border:0px;border-bottom: .5px dashed #e3e8ec; min-height: 55px; padding-left: 0;">
                                            <div class=" d-flex">
                                            <div class="tbl-cell tbl-cell-action-bordered" style="padding:5px !important;width: 50px;">
                                                &nbsp;
                                            </div>
                                            <div class="tbl-cell tbl-cell-action-bordered text-left" style="width: 50%; padding: 5px;">
                                                <small class="text-muted">Course Title</small>
                                                <strong style="font-size: 12px; display: block;" v-html="' ' + adminssionPreference.subject"></strong>
                                            </div>
                                            <div class="tbl-cell tbl-cell-action-bordered text-left" style="width: 15%; padding: 5px;">
                                                <small class="text-muted">University Ref</small>
                                                <strong style="font-size: 12px; display: block;">
                                                    <span v-if="adminssionPreference.uni_ref_no" v-html="' ' + adminssionPreference.uni_ref_no"></span>
                                                </strong>
                                            </div>
                                            <div class="tbl-cell tbl-cell-action-bordered text-left" style="width: 20%; padding: 5px;">
                                                <small class="text-muted">Student Decision </small>
                                                <strong style="font-size: 12px; display: block;" class="tooltips hand" v-if="adminssionPreference.student_decision" v-html="adminssionPreference.student_decision.name"></strong>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="box-typical-header border_2" style="background: #fff;border:0px; min-height: 40px; padding-left: 0;">
                                            <div class="tbl-cell tbl-cell-action-bordered" style="width: 63%; padding-left: 50px !important;">
                                                <ul class="d-flex my_list" style="list-style: none;padding:0px;margin-top:15px;">
                                                    <li style="margin-left: 5px;" v-if="(adminssionPreference.application_status==11 ||adminssionPreference.application_status==12 || adminssionPreference.application_status==13 || adminssionPreference.application_status==14 || adminssionPreference.application_status==4) && checkEnrollmentCondition(adminssionPreference.id)">
                                                        <span class="text-muted">
                                                        <i class="fa fa-bars" style="margin-right:6px"></i><span v-if="adminssionPreference.application_status==11"  style="margin-right: 5px;">Conditions </span>   
                                                        <span v-for="(condition, index) in studentAllInfo.enrollment_conditions.filter(data => data.enrollment_id==adminssionPreference.id)" :key="index">
                                                        <i v-if="condition.status" v-tooltip="condition.condition_text" style="color: #28a745;margin-right: 7px;" class="fa fa-circle text-success"></i>
                                                        <i v-else  v-tooltip="condition.condition_text" style="color: #ddeff9;margin-right: 7px;" class="fa fa-circle"></i>
                                                        </span>
                                                        </span>
                                                    </li>


                                                    <li style="margin-left: 5px;" v-if="(adminssionPreference.application_status==7 ||adminssionPreference.application_status==8 || adminssionPreference.application_status==21) && adminssionPreference.rejected_reason">
                                                    <span class="text-muted"><i class="fa fa-exclamation-triangle text-danger" style="color: #ffc107 !important;"></i> <span v-html="adminssionPreference.rejected_reason"></span></span>
                                                    </li>	
                                                    <li style="margin-left: 5px;" v-if="adminssionPreference.application_status==14  && adminssionPreference.cas_number">
                                                    <span class="text-muted">CAS Number: <span v-html="adminssionPreference.cas_number"></span></span>
                                                    </li>											
                                                </ul>
                                            </div>

                                        </div>

                                        <div class="box-typical-header box_2nd" style="height: auto; border-bottom-left-radius: 0.25rem; border-bottom-right-radius: 0.25rem; background: #f5f4fd; /*padding-top: 0;*/ padding: 5px 0; padding-left: 55px; padding-right: 25px;">
                                            <!-- <div class="tbl-row" style="width: 100%;">
                                                <div class="tbl-cell tbl-cell-action-bordered" style="width: 40%; padding-left: 55px;">
                                                    <div class="d-inline">
                                                        <label class="label_badge_radius m-50" :class="adminssionPreference.lead_need_visa.need_visa == 'yes' ? 'badge-light-green' : 'badge-light-red'">
                                                            {{ adminssionPreference.lead_need_visa.need_visa == 'yes' ? 'Visa Required' : 'Visa Not Required' }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div style="display: flex; width: 100%;">
                                                <div style="width: 50%;">
                                                    <div class="d-inline" v-if="adminssionPreference.lead_need_visa && adminssionPreference.lead_need_visa.need_visa" >
                                                        <label class="label_badge_radius mt-50" :class="adminssionPreference.lead_need_visa.need_visa == 'yes' ? 'badge-light-green' : 'badge-light-red'" style="padding: 1px 10px;padding: 1px 10px; line-height: 15px; display: inline-block; border: 1px solid #fff;">
                                                            {{ adminssionPreference.lead_need_visa.need_visa == 'yes' ? 'Visa Required' : 'Visa Not Required' }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div style="width: 50%;">
                                                    <div class="float-right" style="display: flex; align-items: center;">
                                                        <div v-if="checkEnrollmentRequirement(adminssionPreference.id)" @click="openEnrollmentRequimentListModal(adminssionPreference.id)" class="add_more_drop m-50 position-relative">
                                                            <i v-tooltip="'Enrollment Requirements List'" class="fa fa-list-ul dropdown-toggle"></i>
                                                            <span v-if="enrollmentRequirementCount(adminssionPreference.id)" v-html="enrollmentRequirementCount(adminssionPreference.id)" style="position: absolute;color: #fff;font-size: 10px;background: #dc3545;padding:0px 4px 0px 4px;border-radius: 50%;top: -4px;right: -4px;width: 13px;height: 13px;"></span>
                                                        </div>
                                                        <div v-if="checkEnrollmentDocument(adminssionPreference.id)"  @click="openEnrollmentDocumentModal(adminssionPreference.id)" class="add_more_drop m-50 position-relative">
                                                            <i v-tooltip="'Enrollment Documents List'" class="fa fa-folder dropdown-toggle"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-lg-push-4 col-md-12 col-12 enrollment_right_responsive">
                                        <section class="box-typical enrollment_tab" style="margin: 0px; border: 1px solid #f2f3f5;">
                                            <div class="bhoechie-tab-content active sams_activity_scroll" id="sams_apply25954" style="background: #f5f4fd; padding: 10px; height: 182px;">
                                                <div class="single_remark" v-for="(activity, index) in adminssionPreference.enroll_activity" :key="index" style="background: #fff; padding: 5px 7px; border: none; border-radius: 4px; margin-bottom: 15px;">
                                                <div class="left">
                                                    <h3 style="font-size: 11px !important; font-weight: bold !important; color: black; margin-bottom: 2px;">
                                                    <small class="text-muted" style="display: inline-block; font-size: smaller;">
                                                        <!-- <i class="fa fa-clock-o ml-1" data-title="25 Apr 2022 . 09:17 AM"
                                                        style="display: inline;font-size: larger;vertical-align: text-top;color: #b8b8b8;"></i> -->
                                                        <span class="text-muted" style="font-size: 12px; color: #868e96!important;">
                                                          <span v-html="dDate(activity.created_at)"></span> <i class="fa fa-circle" style="display: inline;font-size: 3px;color:#d4d1d1"></i> <span v-html="dTime(activity.created_at)"></span>
                                                          <!-- <span v-html="dDateTime(activity.created_at)"></span> -->
                                                        </span>
                                                    </small>
                                                    </h3>
                                                    <span style="color: #343434;font-weight: 400; font-size: 12.2px;">
                                                        <!-- <strong style="font-size: 12px;">
                                                            Agent
                                                        </strong> -->
                                                        <span v-html="activity.summary"></span>
                                                        <!-- <strong style="font-size: 12px;">updated</strong> -->
                                                    </span>
                                                </div>
                                                <div class="right"></div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>

                                
                                    <!-- For Child Enrollment -->
                                    <!-- For Child Enrollment -->
                                    <!-- For Child Enrollment -->
                                <div class="row" v-if="adminssionPreference.child_enrollment.length">
                                    <div class="col-md-12 enrollment_prog_responsive" v-for="(child_enrollment, chenr) in adminssionPreference.child_enrollment" :key="chenr">
                                        <div class="box-typical-header" style="background: #f5f4fd;border:0px;border-radius: .25rem; margin-top: 5px;">
                                          <div class=" d-flex enrollment_header">
                                            <div class="tbl-cell tbl-cell-action-bordered" style="width: 50px; padding: 5px; align-self: center;">
                                              <div class="tooltips" title="" style="background: #2e1153; width: 30px; height: 30px; padding: 6px; border-radius: 50%; color: #fff; font-weight: bold; margin: 0 auto; text-align: center;" data-original-title="Progression">P</div>
                                            </div>
                                            <div class="tbl-cell tbl-cell-action-bordered text-left" style="padding:5px !important; width: 25%;">
                                              <b v-if="child_enrollment.university" v-html="child_enrollment.university.name"></b> &nbsp; <span>
                                                <br>
                                                <span class="label_badge_radius" v-if="child_enrollment.level" v-html="child_enrollment.level.name"></span>
                                              </span>
                                            </div>
                                            <div class="tbl-cell tbl-cell-action-bordered text-left" style="padding:5px !important; width: 25%;">
                                              <small class="text-muted">Course Title</small><br>
                                              <strong style="font-size: 12px; " v-html="' ' + child_enrollment.subject"></strong>
                                            </div>
                                            <div class="tbl-cell tbl-cell-action-bordered text-left" style="padding:5px !important; width: 15%;">
                                              <small class="text-muted">University Ref</small><br>
                                              <strong style="font-size: 12px;">
                                                <span v-if="child_enrollment.uni_ref_no" v-html="' ' + child_enrollment.uni_ref_no"></span>
                                              </strong>
                                            </div>
                                            <div class="tbl-cell tbl-cell-action-bordered text-left" style="padding:5px !important;width: 15%;">
                                              <small class="text-muted">Start Date</small><br>
                                              <b style="font-size: 12px;" v-if="child_enrollment.start_date" v-html="dMonth(child_enrollment.start_date)"></b>
                                            </div>
                                            <div class="tbl-cell tbl-cell-action-bordered text-left" style="padding:5px !important;width: 16%;">
                                              <small class="text-muted">Application Status</small><br>
                                              <strong style="font-size: 12px;" v-if="child_enrollment.app_status" v-html="child_enrollment.application_status==1 || child_enrollment.application_status==2?'In Process':child_enrollment.app_status.name"></strong>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <hr style="margin: 0; border-top: 25px solid rgb(248 248 248);" />
            </div>

            <b-modal ref="enrollment_document_modal" size="lg" centered hide-footer>
                <template #modal-header>
                    <h5 class="modal_title">Enrollment Documents</h5>
                    <button type="button" @click="$refs.enrollment_document_modal.hide()">
                        <i class="font-icon-close-2"></i>
                    </button>
                </template>
                <div class="wait_me_insert_form">
                    <div class="form_part" style="background: #f5f4fd; padding-bottom: 10px;">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="single_radius_list_item_header">Documents</div>
                                <div class="single_radius_list_item" v-for="(row, index) in enrollment_documents" :key="index">
                                    <div class="left" style="line-height: 1.1;">
                                        <strong>{{row.text}}</strong>
                                        <span v-if="row.text">&bull;</span>
                                        <span class="label_badge_radius" style="background: #e0eae2 !important; color: #2fa948;">{{row.document_type}}</span>
                                        &bull;
                                        <span style="color: rgb(134, 142, 150); font-size: 12px;">{{row.show_date}} • {{row.show_time}}</span>
                                    </div>
                                    <div class="right">
                                        <div class="round_btn_groups">
                                            <div class="add_more_drop tooltips" v-if="row.file_url">
                                                <i v-tooltip="'View File'" @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px; font-size: 13px;"></i>
                                              </div>
                                            <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                                                <div class="add_more_drop tooltips" title="" data-original-title="Download">
                                                    <i class="fa fa-paperclip dropdown-toggle" style="padding: 4px 5px 3px;"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>

            <div :class="imageViewerImageUrl ? 'image-viewer-open' : 'image-viewer'">
                  <div class="row">
                    <div class="col-md-12 text-right">
                        <div style="width: 100%; position: relative; display: inline;">
                            <span class="fa fa-times pdfclose" @click="closeViewFile()"></span>
                            <iframe :src="imageViewerImageUrl" style="width: 91%; height: 100vh; background: #f2f3f5;"></iframe>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import feather from "feather-icons";
// import VueApexCharts from 'vue-apexcharts';
// import { BDropdown, BDropdownItem } from 'bootstrap-vue';
// import Treeselect from '@riophae/vue-treeselect';
import swal from "bootstrap-sweetalert";
import Treeselect from "@riophae/vue-treeselect";
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import moment from 'moment';
import { BModal } from 'bootstrap-vue';

export default {
  name: "ApplicationContent",
  props: ['studentAllInfo'],
  components: {
    // VueApexCharts,
    // BDropdown, BDropdownItem,
    // Treeselect
    Treeselect,
    BModal,
    BDropdown, BDropdownItem
  },
  data: () => {
    return {
        flag: {
            show_filter: false,
        },
        search: {
            intake_id: null,
        },
        
        hasPassport: true,
        needVisaToStudy: false,
        form: {
            admission_preference: {
                lead_id: '',
                enrollment_id: '',
                destination_id: null,
                institute_type: null,
                uni_group_id: null,
                level_id: null,
                course_group_id: null,
                institute_id: null,
                subject: '',
                year: null,
                month: null,
                enrollement_priority_id: null,
                leads_need_visa: '',
                visa_type: null,
                country: null,
                expire_date: null,
                immigration_destination_exist: true,
            },
        },
        configs: {
            flatpickr_expire_date: {
                dateFormat: "d M Y",
                minDate: "today",
            },
        },
        leadList: [],
        enrollment_documents: [],
        levelList: [],
        coursegroupList: [],
        universityGroupList: [],
        yearList: [],
        monthList: [],
        instituteTypeList: [],
        countryList: [],
        universityList: [],
        groupRelatedUniversityList: [],
        visaTypeList: [],
        listData: [],
        enrollmentPriorityList: [
            {
                id: '1',
                label: '1st Choice'
            },
            {
                id: '3',
                label: '2nd Choice'
            },
            {
                id: '2',
                label: '3rd Choice'
            }
        ],
        imageViewerImageUrl: ''
    };
  },
  computed: {
      otherApplications: function () {
          let dataList = []
          if (this.studentAllInfo.lead) {
              if (this.studentAllInfo.enrollments && this.studentAllInfo.enrollments.length) {
                  this.studentAllInfo.enrollments.map(item => {
                      if (!item.is_primary) {
                          dataList.push(item)
                      }
                  })
              }
          }
          return dataList
      },
      checkPathwayProvider: function () {
          const institute_type = this.form.admission_preference.institute_type
          if (this.form.admission_preference.destination_id && institute_type == 'pathway') {
              return false
          } else {
            return true
          }
      },
      instituteNameTreeSelectList: function () {
          const destinationId = this.form.admission_preference.destination_id
          if (destinationId) {
              const destinationWiseUniversityList = this.universityList.filter(item => item.country_id == destinationId)
              if (destinationWiseUniversityList) {
                return destinationWiseUniversityList.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })
              } else {
                  return ''
              }
          } else {
              return ''
          }
      },
      instituteNameTreeSelectList2: function () {
          let dataList = []
            if (this.form.admission_preference.institute_type) {
                if (this.form.admission_preference.institute_type != 'pathway') {
                    dataList = this.universityList.filter(item => item.uni_type == this.form.admission_preference.institute_type)
                } else if (this.form.admission_preference.institute_type == 'pathway') {
                    const uni_group_id = this.form.admission_preference.uni_group_id
                    if (uni_group_id) {
                        const groupRelatedList = this.groupRelatedUniversityList.filter(item => item.group_id == uni_group_id)
                        dataList = groupRelatedList.map(data => {
                            const itemObj = this.universityList.find(item => item.id == data.university_id)
                            const objData = {
                                id: itemObj ? itemObj.id : '',
                                label: itemObj ? itemObj.name : ''
                            }
                            return Object.assign(objData)
                        })
                    }
                }
            }else {
                dataList = []
            }
            return dataList
      },
      universityGroupTreeSelectList: function () {
          let dataList = []
          if (this.form.admission_preference.destination_id) {
              const localList = this.universityGroupList.filter(item => item.country_id == this.form.admission_preference.destination_id)
              if (this.form.admission_preference.institute_type == 'pathway') {
                  dataList = localList
              } else {
                  dataList = []
              }
          } else {
              dataList = this.universityGroupList
          }
          return dataList
      },
      countryAllList: function () {
          return this.$store.state.site.countries
      },
      treeselectMonthList: function () {
        let date = new Date();
        let longMonth = date.toLocaleString('en', { month: 'long' });

        const currentMonthIndex = this.monthList.findIndex(item => item.label == longMonth)
        // console.log('currentMonthIndex', currentMonthIndex)
        
        const year = this.form.admission_preference.year
        if (year) {
            if (year == date.getFullYear()) {
                const customMonths = []
                this.monthList.map((item, index) => {
                    if (index >= currentMonthIndex) {
                        const element = {
                            id: item.id,
                            label: item.label
                        }
                        customMonths.push(element)
                    }
                })
                return customMonths
            } else {
                return this.monthList
            }
        } else {
            const customMonths = []
            this.monthList.map((item, index) => {
                if (index >= currentMonthIndex) {
                    const element = {
                        id: item.id,
                        label: item.label
                    }
                    customMonths.push(element)
                }
            })
            return customMonths
        }

      },
    //   adminssionPreference: function () {
    //       let enrollmentItem = ''
    //       if (this.studentAllInfo.lead) {
    //           if (this.studentAllInfo.enrollments && this.studentAllInfo.enrollments.length) {
    //               const dataItem = this.studentAllInfo.enrollments.find(item => item.is_primary == 1)
    //               if (dataItem) {
    //                   enrollmentItem = dataItem
    //               } else {
    //                   enrollmentItem = this.studentAllInfo.enrollments[0]
    //               }
    //           }
    //       }
    //       return enrollmentItem
    //   },
      adminssionPreferences: function () {
          if (this.studentAllInfo.lead) {
              if (this.studentAllInfo.enrollments) {
                return this.studentAllInfo.enrollments.filter(item => item.pre_status == 0)
              } else {
                  return []
              }
          } else {
              return []
          }
      },
      convertedApplications: function () {
          if (this.studentAllInfo.lead) {
              if (this.studentAllInfo.enrollments) {
                return this.studentAllInfo.enrollments.filter(item => item.pre_status == 1)
              } else {
                  return []
              }
          } else {
              return []
          }
      }
  },
  watch: {
    'form.admission_preference.destination_id': function (destinationId) {
          if (destinationId) {
            this.immigrationStatuCheck()
          }
      },
      
      'form.admission_preference.leads_need_visa': async function (leadsNeedVisa) {
          if (leadsNeedVisa) {
            var ref = this;
            var jq = ref.jq();

            var url = ref.url(
                "api/v2/student/ajax/full_profile_stage_passport_details"
            );

            try {
                var res = await jq.get(url);
                ref.hasPassport = res.data.has_passport;
            } catch (err) {
                ref.alert(ref.err_msg(err), "error");
            }
          }
      },
  },
  methods: {
    toggle_filter: function() {
      this.flag.show_filter = !this.flag.show_filter;
    },
    viewFile: function(item) {
      // window.open(item.file_url)
      this.imageViewerImageUrl = item.file_url
    },
    closeViewFile: function() {
      // window.open(item.file_url)
      this.imageViewerImageUrl = ''
    },
    openEnrollmentRequimentListModal: function(enrollment_Id) {
        this.$emit('get-enrollment-requirement-list-modal', {
            enrollmentId: enrollment_Id,
        })
    },
    checkEnrollmentRequirement: function(enrollmentId) {
        if (this.studentAllInfo.enrollment_requirements) {
            const enrollmentObj = this.studentAllInfo.enrollment_requirements.find(item => item.id == enrollmentId)
            return enrollmentObj ? true : false
        } else {
            return false
        }
    },
    checkEnrollmentDocument: function(enrollmentId) {
        if (this.studentAllInfo.enrollment_documents) {
            const enrollmentObj = this.studentAllInfo.enrollment_documents.find(item => item.enrollment_id == enrollmentId)
            return enrollmentObj ? true : false
        } else {
            return false
        }
    },
    checkEnrollmentCondition: function(enrollmentId) {
        if (this.studentAllInfo.enrollment_conditions) {
            const enrollmentObj = this.studentAllInfo.enrollment_conditions.find(item => item.enrollment_id == enrollmentId)
            return enrollmentObj ? true : false
        } else {
            return false
        }
    },
    enrollmentRequirementCount: function(enrollmentId) {
        if (this.studentAllInfo.enrollment_requirements) {
            const enrollmentObj = this.studentAllInfo.enrollment_requirements.find(item => item.id == enrollmentId)
            
            if (enrollmentObj) {
                return enrollmentObj.student_requirements.filter(row => !row.completed).length
            } else {
                return 0
            }
        } else {
            return 0
        }
    },
    openAdmissionPreferenceModal: function () {
        this.resetFormData()
        this.$refs.admission_preference_modal.show()
    },
    closeAdmissionPreferenceModal: function () {
        this.resetFormData()
        this.$refs.admission_preference_modal.hide()
    },
    openEnrollmentDocumentModal: function (id) {
        let data_list =[];
        if (this.studentAllInfo.lead) {
            if (this.studentAllInfo.enrollment_documents && this.studentAllInfo.enrollment_documents.length) {
                this.studentAllInfo.enrollment_documents.map(item => {
                    if (item.enrollment_id ==id) {
                        data_list.push(item)
                    }
                })
            }
        }
        this.enrollment_documents = data_list
        this.$refs.enrollment_document_modal.show()
    },
    closeEnrollmentDocumentModal: function () {
        this.$refs.enrollment_document_modal.hide()
    },
    editData: function (item) {
        this.resetFormData()
        let startDate ='';
        if(item.start_date){
            startDate = item.start_date.split('-')
        }
        let instituteType 
        if (item.group_id) {
            instituteType = 'pathway'
        } else {
            instituteType = item.university ? item.university.uni_type : ''
        }

        this.form.admission_preference.destination_id=item.destination_id
        this.form.admission_preference.lead_id=item.lead_id
        this.form.admission_preference.enrollment_id=item.id
        this.form.admission_preference.institute_type=instituteType
        this.form.admission_preference.uni_group_id=item.group_id
        this.form.admission_preference.level_id=item.level_id
        this.form.admission_preference.course_group_id=item.course_group_id
        this.form.admission_preference.institute_id=item.university_id
        this.form.admission_preference.subject=item.subject
        this.form.admission_preference.enrollement_priority_id=item.priority
        this.form.admission_preference.year=startDate ? startDate[0] : ''
        this.form.admission_preference.month=startDate ? startDate[1] : ''
        this.form.admission_preference.immigration_destination_exist=false

        // this.form.admission_preference = {
        //     lead_id: item.lead_id,
        //     enrollment_id: item.id,
        //     destination_id: item.destination_id,
        //     institute_type: instituteType,
        //     uni_group_id: item.group_id,
        //     level_id: item.level_id,
        //     course_group_id: item.course_group_id,
        //     institute_id: item.university_id,
        //     subject: item.subject,
        //     year: startDate ? startDate[0] : '',
        //     month: startDate ? startDate[1] : '',
        //     enrollement_priority_id: item.priority,
        //     immigration_destination_exist: false
        // }

        if (item.lead_need_visa) {
            // this.form.admission_preference.leads_need_visa = item.lead_need_visa.need_visa ? '1' : '0'
            this.form.admission_preference.leads_need_visa = item.lead_need_visa.need_visa 
            this.form.admission_preference.visa_type = item.lead_need_visa.visa_type ? item.lead_need_visa.visa_type.id : ''
            console.log('this.form.admission_preference.visa_type', this.form.admission_preference.visa_type)
            this.form.admission_preference.country = item.lead_need_visa.country
            this.form.admission_preference.expire_date = item.lead_need_visa.expiry_date ? moment(item.lead_need_visa.expiry_date).format("DD MMM YYYY") : ''
        }
        this.$refs.admission_preference_modal.show()
    },
    formClose: function () {
        // this.$emit('stage-close')
        this.$refs.admission_preference_modal.hide()
        this.resetFormData()
    },
    resetFormData: function () {
        this.form.admission_preference = {
            lead_id: '',
            enrollment_id: '',
            destination_id: null,
            institute_type: null,
            uni_group_id: null,
            level_id: null,
            course_group_id: null,
            institute_id: null,
            subject: '',
            year: null,
            month: null,
            enrollement_priority_id: null,
            leads_need_visa: '',
            visa_type: null,
            country: null,
            expire_date: null,
            immigration_destination_exist: true,
        }
    },
    initialData: async function () {
      var ref = this;
      var jq = ref.jq();

      try {
        var res = await jq.get(ref.url("api/v2/student/ajax/admission_preference_initial_data"));
        this.listData = res.data.admission_pref_list

        if (res.data.lead && res.data.lead.length) {
            this.leadList = res.data.lead.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }
        
        if (res.data.levels && res.data.levels.length) {
            this.levelList = res.data.levels.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }

        if (res.data.course_groups && res.data.course_groups.length) {
            this.coursegroupList = res.data.course_groups.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }

        if (res.data.institute_types && res.data.institute_types.length) {
            this.instituteTypeList = res.data.institute_types.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }

        if (res.data.universityGroup && res.data.universityGroup.length) {
            this.universityGroupList = res.data.universityGroup.map(item => {
                const customData = {
                    id: item.id,
                    label: item.name
                }
                return Object.assign(item, customData)
            })
        }

        if (res.data.market_countries && res.data.market_countries.length) {
            this.countryList = res.data.market_countries.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }

        if (res.data.universities && res.data.universities.length) {
            this.universityList = res.data.universities.map(item => {
                const customData = {
                    id: item.id,
                    label: item.name
                }
                return Object.assign(customData, item)
            })
        }

        if (res.data.group_related_universities) {
            this.groupRelatedUniversityList = res.data.group_related_universities.map(item => {
                const customData = {
                    id: item.id,
                    label: item.name
                }
                return Object.assign(customData, item)
            })
        }

        if (res.data.years && res.data.years.length) {
            this.yearList = res.data.years.map(item => {
                return {
                    id: item,
                    label: item
                }
            })
        }

        if (res.data.months) {
            // let date = new Date();
            // let longMonth = date.toLocaleString('en', { month: 'long' });
            
            const monthArray = Object.entries(res.data.months)
            // const currentMonthIndex = monthArray.findIndex(item => item[1] == longMonth)

            // for (let index = currentMonthIndex; index < monthArray.length; index++) {
            //     const element = {
            //         id: index,
            //         label: monthArray[index][1]
            //     }
            //     this.monthList.push(element)
            // }
            this.monthList = monthArray.map(item => {
                return {
                    id: item[0],
                    label: item[1]
                }
            })
        }

        if (res.data.visa_types && res.data.visa_types.length) {
            this.visaTypeList = res.data.visa_types.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }
        ref.flag.step_completed = res.data.step_completed;
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      }

    },
    immigrationStatuCheck: async function () {
        var ref = this;
        var jq = ref.jq();

        try {
            const params = Object.assign({}, ref.form.admission_preference)
            var res = await jq.get(ref.url("api/v2/student/ajax/check_lead_immigration_destination"), params);
            ref.form.admission_preference.immigration_destination_exist = false
            if(res.data.exist){
                ref.form.admission_preference.leads_need_visa=res.data.lead_need_visa.need_visa
                ref.form.admission_preference.visa_type=res.data.lead_need_visa.visa_type
                ref.form.admission_preference.country=res.data.lead_need_visa.country
                ref.form.admission_preference.expire_date=res.data.lead_need_visa.expiry_date ? moment(res.data.lead_need_visa.expiry_date).format("DD MMM YYYY") : ''
            }else{
                ref.form.admission_preference.leads_need_visa=''
                ref.form.admission_preference.visa_type=''
                ref.form.admission_preference.country=''
                ref.form.admission_preference.expire_date=''
            }
        } catch (err) {
            ref.alert(ref.err_msg(err), "error");
        }
    },
    saveData: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/save_admission_preference_data");
      var jq = ref.jq();

      try {

        ref.wait_me(".create_admission_preference");
        var res = await jq.post(url, ref.form.admission_preference);
        ref.alert(res.msg);
        ref.resetFormData();
        ref.initialData();
        ref.closeAdmissionPreferenceModal()
        this.$emit('get-dashboard-data', true)

      } catch (error) {
        ref.alert(ref.err_msg(error), "error");
      } finally {
        ref.wait_me(".create_admission_preference", "hide");
      }
    },
    updateData: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/save_admission_preference_data");
      var jq = ref.jq();

      try {

        ref.wait_me(".create_admission_preference");
        const updateData = Object.assign(ref.form.admission_preference)
        var res = await jq.post(url, updateData);
        ref.alert(res.msg);
        ref.resetFormData();
        ref.initialData();
        ref.closeAdmissionPreferenceModal()
        this.$emit('get-dashboard-data', true)

      } catch (error) {
        ref.alert(ref.err_msg(error), "error");
      } finally {
        ref.wait_me(".create_admission_preference", "hide");
      }
    },
    deleteData: function(item){

      var ref = this;
      var jq = ref.jq();

      swal({
        title: "Are you sure? You want to delete this admission preference data",
        text: "Press Yes to confirm.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(ref.url("api/v2/student/ajax/delete_admission_preference_data"), item);
                ref.alert(res.msg);
                ref.initialData();
                ref.$emit('get-dashboard-data', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    },
  },
  created: function() {
    this.setup_urls();
  },
  mounted: function() {
    feather.replace();
    this.initialData();
  },
};
</script>

<style>
.box-typical {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #fff;
    border: solid 1px #d8e2e7;
    margin: 0 0 20px;
}
.box-typical.box-typical-padding {
    padding: 20px 15px;
}
.box-typical {
    font-size: 13px !important;
}
.action_dropdown .dropdown-toggle:hover {
    color: #ff7f00 !important;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    border: 1px solid #ff7f00;
}
.enroll_right_btns .action_dropdown .fa {
    color: #2e1153;
}
.action_dropdown .dropdown-toggle {
    background: #dedbf1;
    padding: 4px 9px 3px;
    border-radius: 50%;
    font-size: 13px;
    border: 1px solid transparent;
    color: #826aa0;
    cursor: pointer;
}
.label_badge_radius {
    background-image: linear-gradient(to right, rgb(46 17 83 / 19%), rgb(245, 244, 253));
    color: rgb(46, 17, 83);
    border-radius: 20px;
    padding: 1px 10px;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: .2px;
    display: inline-block;
    margin-top: 6px;
}
.label_badge_primary {
    background: rgb(222 219 241);
    color: rgb(46, 17, 83);
    border-radius: 4px;
    padding: 2px 10px;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: .2px;
    display: inline-block;
}
.text-muted {
    color: #868e96!important;
}
.horizontal-layout.navbar-floating:not(.blank-page) .app-content {
    padding: 5px 17px !important;
}
.action_dropdown {
    display: inline;
}

.dropdown-toggle::after {
    display: none!important;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}


.box-typical-header .text-muted {
    font-size: 12px !important;
    color: #868e96 !important;
}
.list_table thead tr th, .list_table tbody tr td {
  padding: 11px 6px 10px !important;
}
.list_table thead tr th {
  font-size: 12px;
  font-weight: 700;
  background-color: #dedbf1 !important;
  text-transform: capitalize;
  color: #343434;
}
.list_table tbody tr td {
  font-size: 12px !important;
  font-weight: 400;
  border-style: dashed;
  color: currentColor;
}
.list_table .text_bold {
  color: rgb(57, 59, 62);
  font-size: 12px;
}
.ad_pref_part{
    padding: 16px;
    height: auto;
    min-height: 390px;
}

.wait_me_insert_form #enrolment_form .form_label{
    color: rgb(93, 93, 93) !important; 
    font-size: 12px; 
    font-weight: 600; 
    margin-bottom: 6px !important;
}
.wait_me_insert_form #enrolment_form .form-control:focus{
    border: 1px solid rgba(197,214,222,.7)!important;
    box-shadow: none!important;
    transition: none;
}

.drop_fix .dropdown-item.active, .drop_fix .dropdown-item:active, .action_dropdown .dropdown-item.active, .action_dropdown .dropdown-item:active {
    background-color: #2e1153 !important;
}


    .remove{
        position: absolute;
        right: -3px;
        top: 10px;
        cursor: pointer;
    }
    .upload_btn{
        padding: 4px 11px;
        margin-top: 11px;
        float: left;
    }
    .condtion_box{
        width: 100%;
        /*background: #e9ecef !important;*/
        float: left;
        padding: 5px 8px 3px 7px;
        border-radius: 3px;
            /*border: 1px solid #d6e2e8;*/
           
    }
    .offer_box{
        width: 100%;
        background: #fff;
        float: left;
        padding: 5px 8px 3px 7px;
        border-radius: 0px;
          border-bottom: 1px solid #d6e2e8;
    }
    .offer_box .condtion_right{

          border-left: 0 !important;
    }
    .condtion_right a{color:#6c7a86 !important}
    .condtion_right{
        width: auto;
    float: right;
    display: inline-flex;
    border-left: 1px solid #adb7be;
    padding-left: 7px;
    }
    .condtion_left{
        float: left;
        width: auto;
    }
    .creator_info{
        width: auto;
        float: left;
        clear: both;
        /*padding: 5px;*/
        margin-left: 15px;
    }
    .right_check{
        background: #c2cad0;
        padding: 3px;
        font-size: 10px;
        color: #fff;
        border-radius: 50%;
    }

    .right_success_check{
        background: #28A745;
        padding: 3px;
        font-size: 10px;
        color: #fff;
        border-radius: 50%;
    }
    
    .border_bottom{
        border-bottom: 1px solid #c3c3c3;
        padding-bottom: 5px;
            margin-bottom: 5px;
    }
    .upload_box .form-label{
        color: #5d5d5d !important;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 6px !important;
    }
    .upload_box .form-control{
            padding: 8px !important;
    }
    /*.upload_box{
        background: #e5f6fe;
        border: 1px solid #00a8ff;
        border-radius: 0;
        color: #333 !important;
    }*/

    .doc_btn_div .fa{
        color: #a2a2a2;
        cursor: pointer;
    }
    .doc_btn_div .fa:hover{
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    }
    .doc_btn_div{
        margin-top: 10px;
    }
    .con_btn_div{
        margin-top: 5px;
    }
    .con_btn_div .fa-trash:hover{
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    }
    .con_btn_div .fa-trash{
        color: #a2a2a2;
        cursor: pointer;
    }
    .con_btn_div .conditionFileDelete{
        position: relative;
        left: -2px;
    }
    /*.upload_box{
        background: #eef2f4 !important;
        border: 1px solid #f5f8ff !important;
        border-radius: 4px !important;
        padding: 10px 15px 0px !important;
    }*/

    .single_radius_list_item_header {
        background: #dedbf1;
        padding: 7px 16px;
        margin-bottom: 15px;
        border-radius: 30px;
        position: relative;
        font-size: 12px;
        font-weight: 700;
    }
    .single_radius_list_item {
        display: flex;
        background: #fff;
        padding: 7px 16px;
        margin-top: 10px;
        border-radius: 30px;
        border: 1px solid #e9f0f3;
    }
    .single_radius_list_item .left {
        width: calc(100% - 125px);
        align-self: center;
    }
    .single_radius_list_item .right {
        width: 125px;
        align-self: center;
        text-align: -webkit-right;
    }
    .single_radius_list_item strong {
        color: rgb(57, 59, 62);
        font-size: 12px;
    }
    .single_radius_list_item .round_btn_groups {
        display: flex;
        width: 130px;
        justify-content: flex-end;

    }
    .single_radius_list_item .add_more_drop .dropdown-toggle:after{
        display: none;
    }
    .single_radius_list_item .add_more_drop .dropdown-toggle{
        background: #dedbf1;
        padding: 4px 8px 2px;
        border-radius: 50%;
        font-size: .8rem;
        border: 1px solid transparent;
        color: #2e1153;
        cursor: pointer;
    }
    .single_radius_list_item .add_more_drop .dropdown-toggle:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .single_radius_list_item .add_more_drop{
        z-index: auto !important;

        margin: 0 3.5px;
    }

    .single_radius_list_item .round_btn_groups .add_more_drop .fa{
        color: #826aa0;
     }
     .pdfclose{
        left: -51px !important;
     }
     .image-viewer-open{
        padding-top: 77px !important;
        height: unset !important;
     }

</style>
