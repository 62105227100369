<template>
  <section class="root">
    <div class="row py-2 px-2">
      <div class="col-lg-12">
        <div style="height: 520px">
          <div class="row">
            <div class="col-lg-6" id="form_full_profile_passport_details">
              <div
                class="stage_form_header stage_form_header_with_yes_no_radio"
              >
                <div class="left">
                  <i
                    class="fa fa-info"
                    aria-hidden="true"
                    style="padding: 6px 11px"
                  ></i>
                </div>
                <div class="right">
                  <div class="with_yes_no_radio_inner">
                    <div class="with_yes_no_radio_inner_left">
                      <span class="main_title">Do You have any Passport?</span>
                    </div>
                    <div class="with_yes_no_radio_inner_right">
                      <div
                        class="radio_box mt-50"
                        style="place-content: flex-end"
                      >
                        <label>
                          <input
                            type="radio"
                            class="change_status_passport"
                            name="has_passport"
                            :value="1"
                            v-model="form.has_passport.status"
                            v-on:change="update_has_passport()"
                          />
                          <span>Yes</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            class="change_status_passport"
                            name="has_passport"
                            :value="0"
                            v-model="form.has_passport.status"
                            v-on:change="update_has_passport()"
                          />
                          <span>No</span>
                        </label>
                      </div>
                      <div class="mt-25 ml-25" v-if="form.has_passport.status">
                        <button style="margin-top:14px;margin-left:5px"
                          type="submit"
                          class="btn submit_button passport_add"
                          v-on:click="toggle_passport_form()"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form_part p-0" v-if="flag.show_passport_form">
                <div
                  class="passport_div"
                  style="
                    background: #f5f4fd;
                    padding: 10px;
                    border-radius: 4px;
                    padding-bottom: 0;
                  "
                >
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label class="form_label">Passport Number</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Enter Passport Number"
                        v-model="form.passport.passport_number"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="form_label">Place Of Issue</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Place Of Issue"
                        v-model="form.passport.place_of_issue"
                      />
                    </div>

                    <div class="form-group col-lg-6">
                      <label class="form_label">Issue Date</label>

                      <div class="input-group input-group-sm dateNicon">
                        <flat-pickr
                          id="dob"
                          v-model="form.passport.issue_date"
                          :config="configs.flatpickr_issue_date"
                          class="form-control date2 flatpickr-input"
                          placeholder="Select a Date"
                        />
                        <span class="input-group-text">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="form-group col-lg-6">
                      <label class="form_label">Expire Date</label>
                      <div class="input-group input-group-sm dateNicon">
                        <flat-pickr
                          id="dob"
                          v-model="form.passport.expire_date"
                          :config="flatpickr_expire_date"
                          class="form-control date2 flatpickr-input"
                          placeholder="Select a Date"
                        />
                        <span class="input-group-text">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="text-right my-2 passport_btn"
                v-if="flag.show_passport_form"
              >
                <button
                  class="btn cancel_button mr-1 btn-cancel"
                  style="padding: 4px 8px !important;"
                  @click="formClose()"
                >
                  <i class="fa fa-close" aria-hidden="true"></i>
                </button>
                <button
                  type="submit"
                  class="btn submit_button passport_submit_save"
                  v-on:click="add_edit_lead_passport()"
                >
                  Save
                </button>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="scrolled_content_part_outer">
                <div class="title_in_border">List of Passport Details</div>
                <div class="scrolled_content_part">

                  <div v-if="list.passports==null" class="alert mb-1 text-center" role="alert" style="border: 1px solid #e9f0f3; font-size: 12px !important; border-radius: 30px; padding: 8px 15px;">
                    <span>Loading Data...</span>
                  </div>

                  <section v-if="cn(list.passports, 'length', null)">
                    <div class="single_part" v-for="(row, index) in list.passports" :key="index">
                    <div class="left">
                      <i class="fa fa-list"></i>
                    </div>
                    <div class="right">
                      <div class="first_part">
                        
                        <div class="first_part_left">
                          <span class="progress-lbl" style="font-size: 12px; font-weight: 600; color: black;" v-html="row.passport_number"></span>

                          <span class="text-danger mx-50" style="font-size: 12px;" v-if="row.expired">
                            <i class="fa fa-window-close" v-tooltip="'Expired'"></i>
                          </span>
                          <span class="mx-50" v-else>
                            <i
                              class="fa fa-check-circle"
                              style="color: #46c35f; font-size: 13px"
                              v-tooltip="'Not Expire'"
                            ></i>
                          </span>

                        </div>
                      </div>
                      <div class="upper_part mt-25">
                        <div class="upper_part_left">
                          <span style="font-size: 11px; color: #868e96">
                            {{ dDate(row.issue_date) }} - {{ dDate(row.expire_date) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="dropdown action_dropdown" :class="row.show_menu?'show':''" v-on:click="toggle_menu(row)">

                      <!-- <i class="fa fa-ellipsis-v dropdown-toggle"></i>

                      <div class="dropdown-menu" :class="row.show_menu?'show':''" style="position: absolute; transform: translate3d(0px, 20px, 0px); will-change: transform; top: 0px; left: 0px;">

                        <span class="dropdown-item edit_passport_details" v-on:click="toggle_passport_form(row)">
                          <i class="fa fa-pencil mr-50"></i>
                          Edit
                        </span>
                        <span class="dropdown-item delete" v-on:click="delete_passport(row, index)">
                          <i class="fa fa-trash mr-50"></i>
                          Delete
                        </span>

                      </div> -->
                      
                        <div class="d-flex">                                            
                           <i v-tooltip="'Edit'" @click="toggle_passport_form(row)" class="fa fa-pencil action_button mr-50"></i>
                           <i v-tooltip="'Delete'" @click="delete_passport(row, index)" class="fa fa-trash-o action_button"></i>
                        </div>

                    </div>
                  </div>
                  </section>

                  <div v-if="list.passports && !list.passports.length" class="alert mb-1 text-center" role="alert" style="border: 1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                    <span>No Passport Details available.</span>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <button class="btn submit_button passport_submit_complete complete_step pull-right
            "
            style="bottom: 0; position: absolute; right: 15px"
            v-if="!flag.step_completed"
            v-on:click="complete_this_step()"
          >
            Save &amp; Complete
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import swal from "bootstrap-sweetalert";

export default {
  name: "PassportDetails",
  components: {},
  data() {
    return {
      flag: {
        show_passport_form: false,
        step_completed: false
      },
      form: {
        has_passport: {
          status: 0,
        },
        passport: {
          id: null,
          passport_number: "",
          place_of_issue: "",
          issue_date: "",
          expire_date: "",
        },
      },
      configs: {
        flatpickr_issue_date: {
          dateFormat: "d M Y",
        },
        // flatpickr_expire_date: {
        //   dateFormat: "d M Y",
        //   minDate: "today",
        // },
      },
      list: {
        passports: null,
      },
    };
  },
  computed: {
    flatpickr_expire_date: function () {
      return {
          dateFormat: "d M Y",
          minDate: this.form.passport.issue_date ? this.form.passport.issue_date : 'today',
        }
    }
  },
  methods: {
    formClose: function () {
        this.$emit('stage-close')
    },
    init: async function () {
      var ref = this;
      var jq = ref.jq();

      var url = ref.url(
        "api/v2/student/ajax/full_profile_stage_passport_details"
      );

      try {
        var res = await jq.get(url);
        ref.form.has_passport.status = res.data.has_passport;
        ref.flag.step_completed = res.data.step_completed;
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      }

    },
    update_has_passport: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/update_lead_has_passport_status");
      var jq = ref.jq();

      swal(
        {
          title: "Are you sure?",
          text: "Press Yes to confirm.",
          type: "info",
          showCancelButton: true,
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-light",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, ref.form.has_passport);
                ref.alert(res.msg);
                ref.fetch_lead_passports();
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");

                if (ref.form.has_passport.status == 1) {
                  ref.form.has_passport.status = 0;
                } else {
                  ref.form.has_passport.status = 1;
                }
              }
            })();
          } else {
            if (ref.form.has_passport.status == 1) {
              ref.form.has_passport.status = 0;
            } else {
              ref.form.has_passport.status = 1;
            }
          }
        }
      );
    },
    toggle_passport_form: function (row = null) {
      var ref = this;

      if (row) {
        ref.flag.show_passport_form = true;
      } else {
        ref.flag.show_passport_form = !ref.flag.show_passport_form;
      }

      if(ref.flag.show_passport_form){

        if(row){
          ref.form.passport=ref.clone(row);
          ref.form.passport.issue_date=ref.dDate(row.issue_date);
          ref.form.passport.expire_date=ref.dDate(row.expire_date);
        }else ref.reset_passport_form();

      }else ref.reset_passport_form();
    },
    add_edit_lead_passport: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/add_edit_lead_passport");
      var jq = ref.jq();

      try {

        ref.wait_me("#form_full_profile_passport_details");
        var res = await jq.post(url, ref.form.passport);
        ref.alert(res.msg);
        ref.toggle_passport_form();
        ref.fetch_lead_passports();
        this.$emit('dashboard-info-update', true)

      } catch (error) {
        ref.alert(ref.err_msg(error), "error");
      } finally {
        ref.wait_me("#form_full_profile_passport_details", "hide");
      }
    },
    reset_passport_form: function () {
      var form = this.form.passport;
      (form.id = null),
        (form.passport_number = ""),
        (form.place_of_issue = ""),
        (form.issue_date = ""),
        (form.expire_date = "");
    },
    fetch_lead_passports: async function () {

      var ref = this;
      var jq = ref.jq();

      var url = ref.url(
        "api/v2/student/ajax/full_profile_stage_lead_passports"
      );

      try {
        var res = await jq.get(url);
        ref.list.passports = res.data.passports;
      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }
    },
    toggle_menu: function(row){

      this.list.passports.forEach(function(each){
        if(row.id!=each.id) each.show_menu=false;
      });

      row.show_menu=!row.show_menu;

    },
    delete_passport: function(row, row_index){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/delete_lead_passport_info");
      var jq = ref.jq();

      swal({
        title: "Are you sure? You want to delete this "+row.passport_number+" related information",
        text: "Press Yes to confirm.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, row);
                ref.alert(res.msg);
                ref.remove_row(ref.list.passports, row_index);
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    },
    complete_this_step: async function(){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/lead_passport_step_completed");
      var jq = ref.jq();

      swal({
        // title: "Are you sure? You want to submit your passport related information as completed.",
        title: "Are you sure?",
        text: "Press Yes to confirm.",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url);
                ref.alert(res.msg);
                ref.init();

                ref.$emit("save-and-completed", true);
                ref.$emit('dashboard-info-update', true)
                
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    }
  },
  created: function () {
    this.setup_urls();
    this.init();
    this.fetch_lead_passports();
  },
};
</script>

<style scoped>
.aft_calender {
  position: absolute;
  right: 22px;
}

.input-group-addon:hover {
  background-color: #00a8ff;
  border: none;
  border-color: #00a8ff;
  color: #fff !important;
}
.dateNicon input {
  background: #fff !important;
  border-right: none;
}
.input-group-addon {
  background: #fff;
  padding: 0 0.75rem;
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
}

.scrolled_content_part {
  /*padding: 20px 10px;
      height: 439px;
      overflow-y: auto;
      overflow-x: hidden;
      background: #fff;
      border-radius: .25rem;
      position: relative;*/

  padding: 0;
}
.scrolled_content_part::-webkit-scrollbar {
  width: 7px;
}
.scrolled_content_part::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
}
.scrolled_content_part::-webkit-scrollbar-thumb {
  /*background: #00a8ff;*/
  background: linear-gradient(
    90deg,
    rgba(183, 180, 212, 1) 0%,
    rgb(165 164 181) 0%,
    #fff 75%
  );
  border-radius: 5px;
}
.scrolled_content_part .single_part {
  display: flex;
  width: 100%;
  /*padding: 10px 15px;
    border: none;
    border-bottom: 1px dashed #e6e8e8;*/

  padding: 7px 16px;
  margin-top: 10px;
  border-radius: .25rem;
  border: 1px solid #e9f0f3;

  align-items: center;
}
/*.scrolled_content_part .single_part:last-child {
    border-bottom: none;
}*/
.scrolled_content_part .single_part .right {
  width: calc(100% - 45px);
  position: relative;
}
.scrolled_content_part .single_part .left h3,
.scrolled_content_part .single_part .right h3 {
  font-size: 11px;
  font-weight: bold;
  color: rgb(98, 95, 139);
  margin-bottom: 6px;
}
.scrolled_content_part .single_part .right .first_part {
  display: flex;
  width: 100%;
}
.scrolled_content_part .single_part .right .first_part .first_part_left img {
  margin-right: 8px;
  width: 28px;
  height: 28px;
  border-radius: 3px;
}
.scrolled_content_part .single_part .right .first_part .first_part_right {
  line-height: 1.2;
}
.scrolled_content_part .single_part .right .upper_part {
  display: flex;
  width: 100%;
}
.scrolled_content_part .single_part .right .upper_part .upper_part_left {
  width: 100%;
}
.scrolled_content_part .single_part .left {
  width: 45px;
  align-self: center;
}
.scrolled_content_part .single_part .left i {
  background: #f1f0fb;
  padding: 10px;
  border-radius: 100%;
  font-size: 14px;
  color: #2e1153;
}
.info_middle {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

.scrolled_content_part_outer {
  position: relative;
  /*border: 1px solid #d8e2e7;*/
  border-radius: 0.25rem;
  margin-top: 10px;
}
.scrolled_content_part_outer .title_in_border {
  /*background: white;
    width: fit-content;
    padding: 5px;
    font-size: 13px;
    font-weight: 600;
    top: -14px;
    position: absolute;
    left: 12px;
    z-index: 2;*/

  background: #dedbf1;
  padding: 7px 16px;
  margin-top: 10px;
  border-radius: 30px;
  position: relative;
  font-size: 12px;
  font-weight: 700;
  width: inherit;
  left: 0;
  top: -10px;
}

.stage_form_header_with_yes_no_radio {
  background: #fff !important;
  border: 1px solid #dedbf1 !important;
}
.stage_form_header .with_yes_no_radio_inner {
  display: flex;
  width: 100%;
}
.stage_form_header .with_yes_no_radio_inner .with_yes_no_radio_inner_left {
  width: calc(100% - 200px);
  align-self: center;
}
.stage_form_header .with_yes_no_radio_inner .with_yes_no_radio_inner_right {
  width: 200px;
  display: flex;
}
.action_dropdown .dropdown-toggle {
  background: #dedbf1;
  padding: 4px 9px 3px;
  border-radius: 50%;
  font-size: 13px;
  border: 1px solid transparent;
  color: #826aa0;
  cursor: pointer;
}
.action_dropdown .dropdown-toggle:hover {
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  border: 1px solid #ff7f00;
}
.action_dropdown .dropdown-toggle:after {
  display: none;
}
.action_dropdown .dropdown-menu.show {
  margin-left: -55px;
  min-width: auto;
  padding: 0;
  margin-top: 3px;
}
.action_dropdown .dropdown-menu.show .dropdown-item {
  background: #dedbf1;
  color: #2e1153;
  font-size: 12px;
  border: none;
  padding: 4px 12px;
}
.action_dropdown .dropdown-menu.show .dropdown-item:hover {
  background: #2e1153;
  color: #fff !important;
}
.action_dropdown .dropdown-menu.show .activate {
  background: #2e1153;
  color: #fff;
}
.action_dropdown .dropdown-menu.show .dropdown-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.action_dropdown .dropdown-menu.show .dropdown-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
</style>
