import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import VueFlatPickr from 'vue-flatpickr-component';
import helperMixin from './helper_mixin.js';
import VTooltip from 'v-tooltip';
//import App from './App.vue';

//Style css
import './style.css'

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// V-select 
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

import SamsStudentPortal from '../components/SamsStudentPortal.vue';



// Configure Vue to ignore the element name when defined outside of Vue.

/*Vue.config.ignoredElements=[
  'sams-crm-direct-leads'
];*/

// Enable the plugin
Vue.use(vueCustomElement);
Vue.use(VueFlatPickr);
Vue.use(VTooltip);
Vue.mixin(helperMixin);

//Some text

// Register your component

Vue.customElement('sams-student-portal', SamsStudentPortal, {});