<template>
  <div v-if="studentAllInfo.lead" style="height: auto !important;">
    <div class="box-typical box-typical-padding mb-0 p-0" id="crm_profile_div" style="background: #f2f3f5; border: none;">

      <div id="sub_lead_education_history_div" v-if="studentAllInfo.lead.lead_educational_history.length">
        <div style="background: #fff; padding: 20px 16px; border-radius: 0.25rem;">

          <div class="row">
            <div class="col-lg-6 mb-2">
              <div class="content_header ml-0">
                <div class="left">
                  <i class="fa fa-graduation-cap" style="padding: 7px 6px;"></i>
                </div>
                <div class="right">
                  <span class="main_title">Education History</span>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="table-responsive" v-if="studentAllInfo.lead.lead_educational_history.length">
                <table class="table table-bordered list_table">
                  <thead>
                    <tr>
                      <th>Institution</th>
                      <th>Subject</th>
                      <th class="text-center">Result</th>
                      <th>Duration</th>
                      <th class="text-center">Transcript</th>
                      <!-- <th class="text-center">Certificate</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(education, index) in studentAllInfo.lead.lead_educational_history" :key="index">
                      <td>
                        <div class="institute_info">
                          <div class="left">
                            <i class="fa fa-university" aria-hidden="true"></i>
                          </div>
                          <div class="right">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-html="education.institution"></strong><br />
                            <span class="label_badge_light" v-if="education.certificate" v-html="education.certificate"></span>
                          </div>
                        </div>
                      </td>
                      <td v-html="education.subject"></td>
                      <td class="text-center">
                        <span v-html="education.result"></span>
                        <span v-if="education.unit" v-html="' ' + education.unit"></span>
                      </td>
                      <td v-html="education.start_month + ' ' + education.start_year + ' - ' + education.end_month + ' ' + education.end_year">
                      </td>
                      <td class="text-center">
                        <div>
                          <div class="add_more_drop open_dynamic_modal tooltips text-center" v-tooltip="'Transcript Add & Upload'" @click="openMultipleTranscriptModal(education.id)">
                            <i class="fa fa-plus dropdown-toggle"></i>
                          </div>
                        </div>
                      </td>
                      <!-- <td class="text-center">
                        <div class="round_btn_groups">
                          <div class="add_more_drop open_dynamic_modal tooltips" title="Certificate Upload">
                            <i class="fa fa-upload dropdown-toggle"></i>
                          </div>

                          <a href="javascript:void(0)" style="border-bottom: none;">
                            <div class="add_more_drop tooltips" title="View File">
                              <i class="fa fa-eye dropdown-toggle"></i>
                            </div>
                          </a>
                        </div>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive" v-else>
                <div class="alert mb-1" role="alert" style="border:1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                    <span>No Education History available.</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <hr style="margin: 0; border-top: 25px solid rgb(248 248 248);" />
      </div>

      <div id="sub_lead_prev_stuty_abroad" v-if="studentAllInfo.lead.lead_previous_studies.length">
        <div style="background: #fff; padding: 20px 16px; border-radius: 0.25rem;">
          <div class="row">
            <div class="col-lg-6 mb-2">
              <div class="content_header ml-0">
                <div class="left">
                  <i class="fa fa-plane" style="padding: 7px 9px;"></i>
                </div>
                <div class="right">
                  <span class="main_title">Previous Study Abroad</span>
                </div>
              </div>
            </div>
            
            <div class="col-md-12">
              <div class="table-responsive" v-if="studentAllInfo.lead.lead_previous_studies.length">
                <table class="table table-bordered list_table">
                  <thead>
                    <tr>
                      <th>Institution</th>
                      <th>Subject</th>
                      <th class="text-center">Result</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(prev_studies, index) in studentAllInfo.lead.lead_previous_studies" :key="index">
                      <td>
                        <div class="institute_info">
                          <div class="left">
                            <i class="fa fa-university"></i>
                          </div>
                          <div class="right">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-html="prev_studies.institution"></strong><br />
                            <span class="label_badge_light" v-if="prev_studies.certificate" v-html="prev_studies.certificate"></span>
                          </div>
                        </div>
                      </td>
                      <td v-html="prev_studies.subject"></td>
                      <td class="text-center" v-html="prev_studies.result + ' ' + prev_studies.unit"></td>
                      <td v-html="prev_studies.start_month + ' ' + prev_studies.start_year + ' - ' + prev_studies.end_month + ' ' + prev_studies.end_year">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive" v-else>
                <div class="alert mb-1" role="alert" style="border:1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                    <span>No Previous Study available.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin: 0; border-top: 25px solid rgb(248 248 248);" />
      </div>

      <div id="sub_lead_english_requirement" v-if="studentAllInfo.lead.english_tests.length">
        <div style="background: #fff; padding: 20px 16px; border-radius: 0.25rem;">
          <div class="row">
            <div class="col-lg-12">
              <div style="">
                <div class="row">
                  <div class="col-md-6 mb-2">
                    <div class="content_header ml-0">
                      <div class="left">
                        <i class="fa fa-align-center" style="padding: 6.5px 7.5px;"></i>
                      </div>
                      <div class="right">
                        <span class="main_title">Test Score</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row eng_test_score_list">
                <div class="col-md-12">
                  <div class="table-responsive" v-if="studentAllInfo.lead.english_tests.length">
                    <table class="table table-bordered list_table">
                      <tbody>
                        <tr v-for="(test, index) in studentAllInfo.lead.english_tests" :key="index">
                          <td style="width: 450px;">
                            <div class="institute_info">
                              <div class="left">
                                <i class="fa fa-align-center" style="padding: 8.5px 9.5px;"></i>
                              </div>
                              <div class="right" style="line-height: 1.7;">
                                <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-if="test.language_test" v-html="test.language_test.name"></strong>
                                <br />
                                <span class="label_badge_light test_score_level" v-if="test.language_test && test.language_test.short_name" v-html="test.language_test.short_name"></span>
                                <span class="test_date" v-if="test && test.test_date" v-html="'Test Date: ' + dDate(test.test_date)"></span>
                              </div>
                            </div>
                          </td>
                          
                          <td class="text-center">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-html="getOverAllData(test)"></strong>
                            <br />
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;">Overall</strong>
                          </td>

                          <template v-if="test.results">
                            <td class="text-center" v-for="(item, index2) in getOtherTestData(test)" :key="index2">
                                <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-html="item.result"></strong>
                                <br />
                                <label class="form_label mb-0" v-if="item.language_test" v-html="item.language_test.name"></label>
                            </td>
                          </template>
                          <!-- <td class="text-center">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;">145</strong>
                            <br />
                            <label class="form_label mb-0">Literacy</label>
                          </td> -->
                          <!-- <td class="text-center">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;">145</strong>
                            <br />
                            <label class="form_label mb-0">Comprehension</label>
                          </td>
                          <td class="text-center">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;">110</strong>
                            <br />
                            <label class="form_label mb-0">Conversation</label>
                          </td>
                          <td class="text-center">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;">105</strong>
                            <br />
                            <label class="form_label mb-0">Production</label>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="table-responsive" v-else>
                    <div class="alert mb-1" role="alert" style="border:1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                        <span>No Test Score available.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin: 0; border-top: 25px solid rgb(248 248 248);" />
      </div>

      <div id="sub_lead_work_experience" v-if="studentAllInfo.lead.lead_work_history.length">
        <div style="background: #fff; padding: 20px 16px; border-radius: 0.25rem;">

          <div class="row" id="work_experience">
            <div class="col-lg-6 mb-2">
              <div class="content_header ml-0">
                <div class="left">
                  <i class="fa fa-briefcase" style="padding: 6.5px 7.5px;"></i>
                </div>
                <div class="right">
                  <span class="main_title">Work Experience</span>
                </div>
              </div>
            </div>

            <div id="work_experience_add_form"></div>

            <div class="col-md-12">
              <div class="table-responsive" v-if="studentAllInfo.lead.lead_work_history.length">
                <table class="table table-bordered list_table">
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Designation</th>
                      <th>Duration</th>
                      <!-- <th class="text-center">Upload</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(experience, index) in studentAllInfo.lead.lead_work_history" :key="index">
                      <td>
                        <div class="institute_info">
                          <div class="left">
                            <i class="fa fa-briefcase" style="font-size: 13px; padding: 9px 10px;"></i>
                          </div>
                          <div class="right">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-html="experience.company_name"></strong>
                          </div>
                        </div>
                      </td>
                      <td v-html="experience.designation"></td>
                      <td>
                        {{ getWorkExperienceDuration(experience) }}
                      </td>
                      <!-- <td class="text-center">
                        <div class="round_btn_groups">
                          <div class="add_more_drop open_dynamic_modal tooltips" title="File Upload">
                            <i class="fa fa-upload dropdown-toggle"></i>
                          </div>

                          <a href="javascript:void(0)" style="border-bottom: none;">
                            <div class="add_more_drop tooltips" title="View File">
                              <i class="fa fa-eye dropdown-toggle"></i>
                            </div>
                          </a>
                        </div>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="table-responsive" v-else>
                <div class="alert mb-1" role="alert" style="border:1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                    <span>No Work Experience available.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin: 0; border-top: 25px solid rgb(248 248 248);" />
      </div>

      <div id="sub_lead_reference" v-if="studentAllInfo.lead.references.length">
        <div style="background: #fff; padding: 20px 16px; border-radius: 0.25rem;">

          <div class="row">
            <div class="col-md-6 mb-2">
              <div class="content_header ml-0">
                <div class="left">
                  <i class="fa fa-user-plus" aria-hidden="true" style="padding: 6.5px 6.5px;"></i>
                </div>
                <div class="right">
                  <span class="main_title">References</span>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-6 text-right mb-3">
              <div class="with_yes_no_radio_inner_right"
                style="width: fit-content; display: flex; align-items: center; margin-left: auto;">
                <div style="min-width: max-content; margin-right: 10px; font-weight: 600;">
                  Do you have any Reference?
                </div>
                <div class="radio_box mt-1" style="place-content: flex-end;">
                  <label
                    data-leadid="37946"
                    data-status="1"
                    class="change_status_ref"
                  >
                    <input
                      type="radio"
                      class="toggle_decision toggle_visa"
                      name="radio_ref"
                      value="yes"
                      checked="checked"
                    />
                    <span>Yes</span>
                  </label>
                  <label
                    data-leadid="37946"
                    data-status="0"
                    class="change_status_ref"
                  >
                    <input
                      type="radio"
                      class="toggle_decision toggle_visa"
                      name="radio_ref"
                      value="no"
                    />
                    <span>No</span>
                  </label>
                </div>
                <button class="btn submit_button reference_add_btn ml-auto">
                  Add
                </button>
              </div>
            </div> -->

            <!-- <div id="reference_add_form"></div> -->

            <div class="col-md-12">
              <div class="table-responsive" v-if="studentAllInfo.lead.references.length">
                <table class="table table-bordered list_table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Reference Source</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <!-- <th class="text-center">Upload</th>
                      <th class="text-center" style="width: 75px;">Action</th>
                      <th class="text-center" style="width: 50px;">
                        <i
                          class="fa fa-info tooltips"
                          style="font-size: .87rem;"
                          title="Please select which two shall be used to apply."
                        ></i>
                      </th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(reference, index) in studentAllInfo.lead.references" :key="index">
                      <td>
                        <div class="institute_info">
                          <div class="left">
                            <i class="fa fa-user" style="font-size: 13px; padding: 9px 11px;"></i>
                          </div>
                          <div class="right">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-html="reference.name"></strong><br />
                            <span v-html="reference.organization"></span>
                          </div>
                        </div>
                      </td>
                      <td v-html="reference.reference_source"></td>
                      <td v-html="reference.mobile_number"></td>
                      <td v-html="reference.email"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="table-responsive" v-else>
                <div class="alert mb-1" role="alert" style="border:1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                    <span>No References available.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin: 0; border-top: 25px solid rgb(248 248 248);" />
      </div>

      <div id="sub_lead_passport_details" v-if="studentAllInfo.lead.passports.length">
        <div style="background: #fff; padding: 20px 16px; border-radius: 0.25rem;">

          <div class="row" id="profile_passport">
            <div class="col-lg-6 mb-2">
              <div class="content_header ml-0">
                <div class="left">
                  <i class="bx bxs-spreadsheet" aria-hidden="true" style="padding: 7px; font-size: 16px;"></i>
                </div>
                <div class="right">
                  <span class="main_title">Passport Details</span>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="table-responsive" v-if="studentAllInfo.lead.passports.length">
                <table class="table table-bordered list_table">
                  <thead>
                    <tr>
                      <th>Passport Number</th>
                      <th>Duration</th>
                      <th>Comments</th>
                      <th class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(passport, index) in studentAllInfo.lead.passports" :key="index">
                      <td>
                        <div class="institute_info">
                          <!-- <div class="left">
                            <i class="bx bxs-spreadsheet" style="font-size: 14px; padding: 9px 10px;"></i>
                          </div> -->
                          <div class="right">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-html="passport.passport_number"></strong>
                          </div>
                        </div>
                      </td>
                      <td>{{ dDate(passport.issue_date) }} - {{ dDate(passport.expire_date) }}</td>
                      <td>-</td>
                      <td class="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="table-responsive" v-else>
                <div class="alert mb-1" role="alert" style="border:1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                    <span>No Passport Details available.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin: 0; border-top: 25px solid rgb(248 248 248);" />
      </div>

      <div id="sub_lead_visa_history_div" v-if="studentAllInfo.lead.lead_visa_history.length">
        <div style="background: #fff; padding: 20px 16px; border-radius: 0.25rem;">
          <div class="row" id="profile_visa_history">
            <div class="col-lg-6 mb-2">
              <div class="content_header ml-0">
                <div class="left">
                  <i class="bx bxs-spreadsheet" aria-hidden="true" style="padding: 7px; font-size: 16px;"></i>
                </div>
                <div class="right">
                  <span class="main_title">Visa History</span>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="table-responsive" v-if="studentAllInfo.lead.lead_visa_history.length">
                <table class="table table-bordered list_table">
                  <thead>
                    <tr>
                      <th>Country</th>
                      <th>Purpose</th>
                      <th>Visa Type</th>
                      <th class="text-center">Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(visa_history, index) in studentAllInfo.lead.lead_visa_history" :key="index">
                      <td>
                        <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-if="visa_history.country" v-html="visa_history.country.name"></strong>
                        <!-- <div class="institute_info">
                          <div class="right">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-if="visa_history.country" v-html="visa_history.country.name"></strong>
                          </div>
                        </div> -->
                      </td>
                      <td v-html="visa_history.purpose"></td>
                      <td><span v-if="visa_history.visa_type" v-html="visa_history.visa_type.name"></span></td>
                      <td class="text-center">{{ dDate(visa_history.start_date) }} - {{ dDate(visa_history.end_date) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="table-responsive" v-else>
                <div class="alert mb-1" role="alert" style="border:1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                    <span>No Passport Details available.</span>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <hr style="margin: 0; border-top: 25px solid rgb(248 248 248);" />
      </div>

      <div id="sub_lead_visa_refusal_div" v-if="studentAllInfo.lead.lead_visa_refusal.length">
        <div style="background: #fff; padding: 20px 16px; border-radius: 0.25rem;">
          <div class="row" id="profile_visa_refusal">
            <div class="col-lg-6 mb-2">
              <div class="content_header ml-0">
                <div class="left">
                  <i class="bx bxs-spreadsheet" aria-hidden="true" style="padding: 7px; font-size: 16px;"></i>
                </div>
                <div class="right">
                  <span class="main_title">Visa Refusal</span>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="table-responsive" v-if="studentAllInfo.lead.lead_visa_refusal.length">
                <table class="table table-bordered list_table">
                  <thead>
                    <tr>
                      <th>Country</th>
                      <th>Purpose</th>
                      <th>Visa Type</th>
                      <th class="text-center">Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(visa_refusal, index) in studentAllInfo.lead.lead_visa_refusal" :key="index">
                      <td>
                        <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-if="visa_refusal.country_data" v-html="visa_refusal.country_data.name"></strong>
                        <!-- <div class="institute_info">
                          <div class="right">
                            <strong style="color: rgb(57, 59, 62); font-size: 12px;" v-if="visa_refusal.country_data" v-html="visa_refusal.country_data.name"></strong>
                          </div>
                        </div> -->
                      </td>
                      <td v-html="visa_refusal.reason"></td>
                      <td><span v-if="visa_refusal.visa_type" v-html="visa_refusal.visa_type.name"></span></td>
                      <td class="text-center">{{ dDate(visa_refusal.start_date) }} - {{ dDate(visa_refusal.end_date) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="table-responsive" v-else>
                <div class="alert mb-1" role="alert" style="border:1px solid #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                    <span>No Visa Refusal available.</span>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

    </div>
    <!-- <div v-else class="box-typical box-typical-padding mb-0 p-0" id="crm_profile_div" style="background: #f2f3f5; border: none;">
      <span class="text-danger">No information found</span>
    </div> -->

    <b-modal ref="multiple_trascript_upload" size="lg" centered hide-footer>
        <template #modal-header>
          <h5 class="modal_title">Transcript Add & Upload</h5>
          <button type="button" @click="$refs.multiple_trascript_upload.hide()">
                <i class="font-icon-close-2"></i>
            </button>
        </template>
        <div class="wait_me_insert_form">

          <div class="row add_edit_additional_doc_form">
            <div class="col-lg-12">
                <div class="box-typical" style="padding: 15px 15px 16px; margin: 10px 15px 15px; border-radius: .25rem; border: none;">
                  <div class="row">
                      <div class="col-md-12">
                          <div style=" padding: 15px 15px 5px; background: #f5f4fd; margin-bottom: 20px; border-radius: .25rem; ">
                              <div class="form-row">
                                  <div class="form-group col-md-12 mb-1">
                                      <label class="form-label" for="documentfile" style="color: #5d5d5d !important; font-size: 12px; font-weight: 600; margin-bottom: 6px !important;">
                                          Upload File
                                      </label>
                                      <input type="file" v-on:change="add_attachment($event)" name="documentfile" id="documentfile" class="form-control">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
            <div class="col-lg-12 mt-0 text-right">
                <button class="btn cancel_button mr-2" @click="$refs.multiple_trascript_upload.hide()">Cancel</button>
                <button class="btn submit_button btn-submit" style="margin-right: 16px;" @click="submitTranscriptDocument()" type="submit">Submit</button>
            </div>
            <div class="col-lg-12">
              <div class="box-typical" style="padding: 15px 15px 16px; margin: 10px 15px 15px; border-radius: .25rem; border: none;">
                <div class="row">
                  <div class="col-md-12 overflow_div_responsive transcript_documentation_part" v-if="educationTranscriptDocument.length">
                    <div class="single_doc_item" v-for="(row, index) in educationTranscriptDocument" :key="index">
                      <div class="left">
                          <i class="fa fa-file-pdf-o" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                          <strong v-if="row.document_edu" v-html="row.document_edu.institution"></strong>
                          <span v-if="row.name"> • </span>
                          <span style="font-size: 12px; color: rgb(134, 142, 150);" v-html="row.name"></span> 
                      </div>
                      <div class="right">
                          <div class="round_btn_groups">
                            <div class="add_more_drop tooltips" v-if="row.file_url">
                              <i v-tooltip="'View File'"  @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                            </div>
                            <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                              <div class="add_more_drop open_dynamic_modal tooltips">
                                  <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                              </div>
                            </a>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </b-modal>
    <div :class="imageViewerImageUrl ? 'image-viewer-open' : 'image-viewer'">
          <div class="row">
            <div class="col-md-12 text-right">
                <div style="width: 100%; position: relative; display: inline;">
                    <span class="fa fa-times pdfclose" @click="closeViewFile()"></span>
                    <iframe :src="imageViewerImageUrl" style="width: 91%; height: 90vh; background: #f2f3f5;"></iframe>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import feather from "feather-icons";
// import VueApexCharts from 'vue-apexcharts';
// import { BDropdown, BDropdownItem } from 'bootstrap-vue';
// import Treeselect from '@riophae/vue-treeselect';
import { BModal } from 'bootstrap-vue';
export default {
  name: "ProfileContent",
  props: ['studentAllInfo'],
  components: {
    BModal
    // VueApexCharts,
    // BDropdown, BDropdownItem,
    // Treeselect
  },
  data: () => {
    return {
      flag: {
        show_filter: false,
      },
      search: {
        intake_id: null,
      },
      form:{
        document_upload: {
            edu_id: '',
            documentfile: '',
            file_url: '',
            comments: '',
          }
      },
      educationTranscriptDocument: [],
      imageViewerImageUrl: ''
    };
  },
  computed: {
    blockDataCheck: function () {
      const lead = this.studentAllInfo.lead
      if (lead) {
        if (lead.lead_educational_history || lead.lead_previous_studies || lead.lead_english_test || 
        lead.lead_work_history || lead.references || lead.passports || lead.lead_visa_history || lead.lead_visa_refusal) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  watch: {},
  methods: {
    getOverAllData: function (lead_english_test) {
        const resultItem = lead_english_test.results.find(item => item.language_test.id == lead_english_test.language_test_id)
        if (resultItem) {
          return resultItem.result
        } else {
          return ''
        }
    },
    
    viewFile: function(item) {
      this.imageViewerImageUrl = item.file_url
    },
    closeViewFile: function() {
      this.imageViewerImageUrl = ''
    },
    getOtherTestData: function (lead_english_test) {
        const resultItems = lead_english_test.results.filter(item => item.language_test.id != lead_english_test.language_test_id)
        if (resultItems && resultItems.length) {
          return resultItems
        } else {
          return ''
        }
    },
    getWorkExperienceDuration: function (item) {
        const endtDate = item.end_date ? this.dDate(item.end_date) : 'Continue'
        const startDate = this.dDate(item.start_date)
        return startDate + ' - ' + endtDate
    },
    toggle_filter: function() {
      this.flag.show_filter = !this.flag.show_filter;
    },
    openMultipleTranscriptModal: function(documentId) {
      var ref=this;
      ref.resetTranscriptDocuments()
      ref.form.document_upload.edu_id = documentId;
      console.log(ref.studentAllInfo.educational_documents)
      
      let data_list =[];
      if (this.studentAllInfo.educational_documents && this.studentAllInfo.educational_documents.length) {
          this.studentAllInfo.educational_documents.map(item => {
              if (item.module_id ==documentId && item.document_type=='Transcript') {
                  data_list.push(item)
              }
          })
      }
      console.log(data_list)
      ref.educationTranscriptDocument=data_list;
      ref.$refs.multiple_trascript_upload.show()
      
    },
    add_attachment: function (e) {
        let file_input = e.target.files[0];
        this.form.document_upload.documentfile = file_input
    },
    submitTranscriptDocument: async function () {
        var ref=this;
        var jq=ref.jq();

        var form_data = new FormData();
        const item = ref.form.document_upload

        form_data.append('edu_id', item.edu_id);
        form_data.append('comments', item.comments);
        form_data.append('documentfile', item.documentfile);

        ref.wait_me('.wait_me_form');

        jq.ajax({
            url: ref.url('api/v2/student/ajax/store_transcript_documents_data'),
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{
                ref.alert(res.msg);
                ref.$refs.multiple_trascript_upload.hide()
                this.resetTranscriptDocuments()
                ref.$emit('get-dashboard-data', true)
            }
        }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
        ).always(()=>ref.wait_me('.wait_me_form', 'hide'));
    },    
    resetTranscriptDocuments: function () {
        this.form.document_upload = {
            edu_id: '',
            documentfile: '',
            comments: '',
        }
    },
    // dashboard_init_data: async function(){

    // 	var ref=this;
    // 	var jq=ref.jq();
    // 	var url=ref.url('api/v1/university/ajax/dashboard_init_data');

    // 	try{
    // 		const params = { intake_id: intake_id}
    // 		var res=await jq.get(url, params);
    // 		ref.info.total_agents=res.data.total_agents;
    // 	}catch(error){
    // 		console.log(error);
    // 	}

    // },
  },
  created: function() {
    this.setup_urls();
  },
  mounted: function() {
    feather.replace();
    // this.dashboard_init_data();
  },
};
</script>

<style>
.checkbox-detailed {
  margin: 0 3px 0px 0;
}
.checkbox-detailed input + label {
  width: auto;
  height: 30px;
  padding: 0 10px 0 10px;
}
.checkbox-detailed input + label:before {
  left: 4px;
  display: none;
}
.checkbox-detailed .checkbox-detailed-title {
  font-weight: 400;
}
.short_name_lbl {
  background: #ffc107 !important;
  padding: 0 5px !important;
  border-radius: 5% !important;
}

.test_list button {
  overflow: visible;
  margin: 0;
  -webkit-appearance: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  vertical-align: middle;
  border: 0;
  padding: 0;
  border-radius: 0;
  line-height: 1em;
  color: inherit;
  font-family: inherit;
  display: block;
  cursor: pointer;
}
.test_list .btn {
  display: inline-block;
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #d8e2e7;
  margin: 5px;
  font-size: 13px;
  font-weight: 500;
}
.test_list {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0;
}
.test_list .btn-block {
  margin-top: 0 !important;
}
.test_list .test_open {
  background: #dfdcf2;
}
.test_list .btn:hover {
  background: #dfdcf2;
}
.eng_test_score_list .list_table .test_score_level {
  /*border-left: 3px solid #2e1153;*/
  font-size: 11px;
  letter-spacing: 0.5px;
}
.eng_test_score_list .list_table .test_date {
  font-weight: 400;
  font-size: 12px;
}
.box {
  position: relative;
  /* background: #ebebed; */
  /* border: 1px solid #d8e2e7; */
  /* border-radius: 5px; */
  padding: 10px;
  margin: 0px -10px;
  margin-bottom: 15px;
}
.box h5 {
  margin-bottom: 0px;
}
.tabs-section > .tab-content:not(.no-styled) {
  background: none;
  padding: 0;
}
h5 {
  border-bottom: none;
}
.addBtn {
  background-color: #2e58a6 !important;
  border-width: 1px !important;
  border-color: #2e58a6 !important;
  color: #fff !important;
  border-radius: 30px !important;
  padding: 1px 12px !important;
  height: 25px;
}
.addBtn:hover {
  background-color: #fff !important;
  border-color: #2e58a6 !important;
  color: #2e58a6 !important;
}
.list_table .institute_info .left .bx {
  background: #f1f0fb;
  padding: 10px;
  border-radius: 100%;
  font-size: 14px;
  color: #2e1153;
}
.list_table .institute_info .left .bx {
  background: #f1f0fb;
  padding: 10px;
  border-radius: 100%;
  font-size: 14px;
  color: #2e1153;
}
.box {
  position: relative;
  /* background: #ebebed; */
  /* border: 1px solid #d8e2e7; */
  /* border-radius: 5px; */
  padding: 10px;
  margin: 0px -10px;
  margin-bottom: 15px;
}
.remove {
  position: absolute;
  color: red;
  right: 10px;
  top: 5px;
  z-index: 50;
  cursor: pointer;
}
.addBtn {
  background-color: #2e58a6 !important;
  border-width: 1px !important;
  border-color: #2e58a6 !important;
  color: #fff !important;
  border-radius: 30px !important;
  padding: 1px 12px !important;
  height: 25px;
}
.addBtn:hover {
  background-color: #fff !important;
  border-color: #2e58a6 !important;
  color: #2e58a6 !important;
}

.profile_tab_basic_info {
  /*border: 1px solid #d8e2e7;
		background-image: linear-gradient( to right, #e1dff3 0%, #faf9fd 2% );*/

  background: #fff;
  padding: 25px 16px 20px;
  border-radius: 0.25rem;
  /* margin-top: -20px; */
}
.profile_tab_basic_info .single_part {
  border: 1px solid #d8e2e7;
  border-radius: 0.25rem;
  padding: 10px;
  display: grid;
  height: 145px;
}
.profile_tab_basic_info .single_part table {
  width: -webkit-fill-available;
  height: fit-content;
}
.profile_tab_basic_info .single_part table td {
  padding: 2px 0;
  /*font-size: 12.5px;*/
  color: #868e96;
  font-size: 12px;
}
.profile_tab_basic_info .single_part table td:last-child {
  /*font-weight: 600;
    font-size: 12px;*/
  color: currentColor;
}

/*Style for included profile page*/
.tab_add_form .form_label,
.tab_edit_form .form_label {
  color: #5d5d5d !important;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px !important;
}
.tab_add_form .form-control,
.tab_edit_form .form-control {
  font-size: 12px;
  line-height: 19px;
}
.tab_add_form .cancel_button,
.tab_edit_form .cancel_button {
  margin-right: 5px !important;
  padding: 0;
}
.tab_add_form .cancel_button .fa-refresh,
.tab_edit_form .cancel_button .fa-refresh {
  padding: 5px 8px;
}
.tab_add_form, .tab_edit_form {
  padding: 15px;
  background: #dedbf1;
  margin-bottom: 20px;
  border-radius: 0.25rem;
}
.tab_add_form_part,
.tab_edit_form_td,
.tab_add_form_part_study_gap,
.tab_edit_form_td_study_gap,
.tab_add_form_part_ps_abroad,
.tab_edit_form_td_ps_abroad,
.tab_add_form_part_work_ex,
.tab_edit_form_td_work_ex,
.tab_add_form_part_reference,
.tab_edit_form_td_reference,
.tab_add_form_part_test_score_parent,
.tab_add_form_part_test_score,
.tab_edit_form_td_test_score,
.tab_add_form_part_passport,
.tab_edit_form_td_passport,
.tab_add_form_part_visa_history,
.tab_edit_form_td_visa_history,
.tab_add_form_part_visa_refusal,
.tab_edit_form_td_visa_refusal {
  display: none;
}
.tab_add_form_part .input-group-addon:hover,
.tab_edit_form_td .input-group-addon:hover,
.tab_edit_form_td_ps_abroad .input-group-addon:hover {
  background-color: #fff !important;
  border: solid 1px rgba(197, 214, 222, 0.7);
  border-color: #ccd7e4 !important;
}
#tabsEducation .list_table thead tr th {
  font-size: 12px;
  font-weight: 700;
  background-color: #f2f1fa;
}
.list_table tbody tr td {
  font-size: 12.5px;
  font-weight: 400;
  border-style: dashed;
}
#tabsEducation .table-bordered {
  border-style: dashed;
}
.list_table .institute_info {
  display: flex;
}
.list_table .institute_info .left {
  width: 45px;
  align-self: center;
}
.list_table .institute_info .left .fa {
  background: #f1f0fb;
  padding: 10px;
  border-radius: 100%;
  font-size: 14px;
  color: #2e1153;
}
.list_table .institute_info .right {
  width: calc(100% - 45px);
  align-self: center;
}

.list_table tbody + tbody {
  border: none;
}
/*Style for included profile page*/

.content_bordered_box {
  text-align-last: start;
  margin: 30px 0 20px;
  border: 1px solid #d8e2e7;
  padding: 15px;
  border-radius: 0.25rem;
  position: relative;
}
.content_bordered_box .title_in_border {
  background: #fff;
}
.icon_bar {
  width: fit-content;
  /*background-color: #f1f0fb;*/
  overflow: auto;
  border-radius: 0.25rem;
  padding: 2px 4px;
  margin: 0 auto;
  border: 0.5px solid #d8e2e7;
}
.icon_bar a {
  text-align: center;
  padding: 0;
  color: #818084;
  font-size: 16px;
  margin: 0 3px;
  border-bottom: none;
  top: 0;
}
.content_header {
  display: flex;
  width: 100%;
  line-height: 1.2;
}

.content_header .left {
  width: 40px;
  align-self: center;
}
.content_header .right {
  width: calc(100% - 40px);
  align-self: center;
}
.content_header .right .main_title {
  font-size: 18px;
  font-weight: 600;
  display: block;
}
.content_header .left .fa,
.content_header .left .bx,
.content_header .left .font-icon,
.content_header .left .glyphicon {
  padding: 7px 6px;
  background: #f1f0fb;
  border-color: transparent;
  color: #2e1153;
  border-radius: 100%;
  font-size: 14px;
  border: 1px solid #ceccda;
}

.yes_no_drop {
  top: 12px;
  right: 30px;
}
.yes_no_drop .dropdown-toggle:after {
  display: none;
}
.yes_no_drop .dropdown-toggle {
  color: #868e96;
  cursor: pointer;
  font-size: 16px;
}
.yes_no_drop .dropdown-menu.show {
  margin-left: -43px;
  min-width: auto;
  padding: 0;
}
.yes_no_drop .dropdown-menu.show .dropdown-item {
  background: #dedbf1;
  color: #2e1153;
  font-size: 12px;
  border: none;
}
.yes_no_drop .dropdown-menu.show .dropdown-item:hover {
  background: #2e1153;
  color: #fff;
}
.yes_no_drop .dropdown-menu.show .activate {
  background: #2e1153;
  color: #fff;
}
.yes_no_drop .dropdown-menu.show .dropdown-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.yes_no_drop .dropdown-menu.show .dropdown-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.round_check_mark {
  position: relative;
}
.round_check_mark label {
  background-color: #dedbf1;
  border: 1px solid #d8e2e7;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 4px;
  position: absolute;
  top: -2px;
  width: 22px;
}
.round_check_mark label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}
.round_check_mark input[type="checkbox"] {
  visibility: hidden;
}
.round_check_mark input[type="checkbox"]:checked + label {
  background-color: #2e1153;
  border-color: #2e1153;
}
.round_check_mark input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.round_radio_mark {
  text-align: center;
}
.round_radio_mark label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #00005c;
  margin: 0 auto;
}
.round_radio_mark label input:checked + span:before {
  box-shadow: inset 0 0 0 0.5em #2e1153;
}
.round_radio_mark label input {
  position: absolute;
  left: -9999px;
}

#tabsEducation .round_btn_groups .add_more_drop .dropdown-toggle:after {
  display: none;
}
#tabsEducation .round_btn_groups .add_more_drop .dropdown-menu.show {
  /*margin-left: -97px;*/
  min-width: auto;
  padding: 0;

  z-index: 2;
  top: -5px !important;
  left: 60px !important;
}
#tabsEducation
  .round_btn_groups
  .add_more_drop
  .dropdown-menu.show
  .dropdown-item {
  background: #dedbf1;
  color: #2e1153;
  font-size: 12px;
  border: none;
}
#tabsEducation
  .round_btn_groups
  .add_more_drop
  .dropdown-menu.show
  .dropdown-item:hover {
  background: #2e1153;
  color: #fff !important;
}
#tabsEducation .round_btn_groups .add_more_drop .dropdown-menu.show .activate {
  background: #2e1153;
  color: #fff;
}
#tabsEducation
  .round_btn_groups
  .add_more_drop
  .dropdown-menu.show
  .dropdown-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
#tabsEducation
  .round_btn_groups
  .add_more_drop
  .dropdown-menu.show
  .dropdown-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

#tabsEducation .round_btn_groups .add_more_drop .dropdown-toggle {
  background: #dedbf1;
  padding: 4px 5px;
  border-radius: 50%;
  font-size: 0.8rem;
  border: 1px solid transparent;
  color: #2e1153;
  cursor: pointer;
}
#tabsEducation .round_btn_groups .add_more_drop .dropdown-toggle:hover {
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  border: 1px solid #ff7f00;
}
#tabsEducation .round_btn_groups .add_more_drop {
  z-index: auto !important;
}

#tabsEducation .round_btn_groups {
  display: flex;
  width: 60px;
  justify-content: space-around;
  margin: 0 auto;
}
#tabsEducation .round_btn_groups .add_more_drop .fa-paperclip {
  padding: 4px 6px 4px;
  font-size: 0.82rem;
}
#tabsEducation .round_btn_groups .add_more_drop .fa-upload {
  color: #826aa0 !important;
}
#tabsEducation .round_btn_groups .add_more_drop .fa-eye {
  padding: 4px 5px 5px;
  font-size: 0.77rem;
  color: #826aa0 !important;
}

.radio_box label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}
.radio_box label input {
  position: absolute;
  left: -9999px;
}
.radio_box label input:checked + span {
  background-color: #d6d6e5;
}
.radio_box label input:checked + span:before {
  box-shadow: inset 0 0 0 0.3em #2e1153;
}
.radio_box label span {
  display: flex;
  align-items: center;
  padding: 3px 8px 3px 5px;
  border-radius: 4px;
  transition: 0.25s ease;
}
.radio_box label span:hover {
  background-color: #d6d6e5;
}
.radio_box label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #00005c;
}
.radio_box {
  width: 100%;
  display: flex;
}
.box {
  position: relative;
  /* background: #ebebed; */
  /* border: 1px solid #d8e2e7; */
  /* border-radius: 5px; */
  padding: 10px;
  margin: 0px -10px;
  margin-bottom: 15px;
}
.box h5 {
  margin-bottom: 0px;
}
.tabs-section > .tab-content:not(.no-styled) {
  background: none;
  padding: 0;
}
h5 {
  border-bottom: none;
}

.addBtn {
  background-color: #2e58a6 !important;
  border-width: 1px !important;
  border-color: #2e58a6 !important;
  color: #fff !important;
  border-radius: 30px !important;
  padding: 1px 12px !important;
  height: 25px;
}
.addBtn:hover {
  background-color: #fff !important;
  border-color: #2e58a6 !important;
  color: #2e58a6 !important;
}

#study_gap_switch .swraper.light {
  margin-left: auto !important;
}

.info_middle {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

.input-group-addon:hover {
  background-color: #00a8ff !important;
  border: none;
  border-color: #00a8ff !important;
  color: #fff !important;
}
.dateNicon input {
  background: #fff !important;
  border-right: none;
}
.input-group-addon {
  background: #fff;
  padding: 0 0.75rem;
}
.list_table .label_badge_light {
  border-radius: 20px;
  padding: 1px 10px;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.3;
  /* background: #e0ddf2; */
  letter-spacing: 0.2px;
  color: black;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-image: linear-gradient(to right, #dedbf1, #f5f4fd);
}

.box {
  position: relative;
  /* background: #ebebed; */
  /* border: 1px solid #d8e2e7; */
  /* border-radius: 5px; */
  padding: 10px;
  margin: 0px -10px;
  margin-bottom: 15px;
}
.box h5 {
  margin-bottom: 0px;
}
.tabs-section > .tab-content:not(.no-styled) {
  background: none;
  padding: 0;
}
h5 {
  border-bottom: none;
}

.addBtn {
  background-color: #2e58a6 !important;
  border-width: 1px !important;
  border-color: #2e58a6 !important;
  color: #fff !important;
  border-radius: 30px !important;
  padding: 1px 12px !important;
  height: 25px;
}
.addBtn:hover {
  background-color: #fff !important;
  border-color: #2e58a6 !important;
  color: #2e58a6 !important;
}

#study_gap_switch .swraper.light {
  margin-left: auto !important;
}

.info_middle {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

.input-group-addon:hover {
  background-color: #00a8ff !important;
  border: none;
  border-color: #00a8ff !important;
  color: #fff !important;
}
.dateNicon input {
  background: #fff !important;
  border-right: none;
}
.input-group-addon {
  background: #fff;
  padding: 0 0.75rem;
}
.list_table .label_badge_light {
  border-radius: 20px;
  padding: 1px 10px;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.3;
  /* background: #e0ddf2; */
  letter-spacing: 0.2px;
  color: black;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-image: linear-gradient(to right, #dedbf1, #f5f4fd);
}
.list_table .institute_info .left .bx {
  background: #f1f0fb;
  padding: 10px;
  border-radius: 100%;
  font-size: 14px;
  color: #2e1153;
}

.stage_form_header_pro_tab_info {
  background: #e7f2fb !important;
  border: 1px solid #dedbf1 !important;
}
.box_icon{
  padding: 7px;
  background: #f1f0fb;
  border-color: transparent;
  color: #2e1153;
  border-radius: 100%;
  font-size: 16px;
  border: 1px solid #ceccda;
}

 /* Upload document css */
 .add_edit_additional_doc_form .radio_box label input:checked + span {
    background-color: #f5f4fd;
}
.add_edit_additional_doc_form .radio_box label span {
    padding: 7px 16px;
    margin-top: 10px;
    border-radius: 30px;
    border: 1px solid #e9f0f3;
    width: 100%;
    color: rgb(57, 59, 62);
    font-size: 12px;
    font-weight: 600;
    display: -webkit-inline-box;
}
.add_edit_additional_doc_form .radio_box label span:hover {
    background: #f5f4fd;
}
.add_edit_additional_doc_form .radio_box label span:before {
    top: 2px;
    position: relative;
}
.add_edit_additional_doc_form .radio_box label {
    width: 100%;
}


.transcript_documentation_part .single_doc_item{
        display: flex;
        background: fff;
        padding: 7px 16px;
        margin-top: 10px;
        border-radius: 30px;
        border: 1px solid #e9f0f3;
    }
    .transcript_documentation_part .single_doc_item:hover{
        background: #f5f4fd;
    }
    .transcript_documentation_part .single_doc_item .left{
        width: calc(100% - 125px);
        align-self: center;
    }
    .transcript_documentation_part .single_doc_item .left strong{
        color: rgb(57, 59, 62);
        font-size: 12px;
    }
    .transcript_documentation_part .single_doc_item .right{
        width: 125px;
        align-self: center;
        text-align: -webkit-right;
    }
    .transcript_documentation_part .single_doc_item .right .rounded_icon_bar{
        width: fit-content;
        overflow: auto;
        border-radius: .25rem;
        /*margin: 0 auto;*/
        display: flex;
    }
    .transcript_documentation_part .single_doc_item .right .rounded_icon_bar a {
        text-align: center;
        padding: 0;
        /*color: #818084;*/
        color: #c3c3c3;
        font-size: 16px;
        margin: 0 3px;
        border-bottom: none;
        top: 0;

        display: block;
    }
    .transcript_documentation_part .single_doc_item .right .rounded_icon_bar .fa{
        font-size: .9rem;
        padding: 3px 5px;
    }
    .transcript_documentation_part .single_doc_item .right .rounded_icon_bar .btn_round{
        border: .5px solid #d8e2e7;
        border-radius: 50%;
        /*width: 25px;
        height: 25px;*/
    }
    .transcript_documentation_part .single_doc_item .right .rounded_icon_bar .btn_round:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .transcript_documentation_part .single_doc_item .right .rounded_icon_bar img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-left: 3px;
    }
    /*.transcript_documentation_part .single_doc_item .right .rounded_icon_bar .fa-file-pdf-o {
        padding: 3px 6px;
    }*/

    .transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle:after{
        display: none;
    }
    /*.transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle{
        color: #c3c3c3;
        cursor: pointer;
        border: .5px solid #d8e2e7;
        border-radius: 50%;
        padding: 3px 5px;
        font-size: .9rem;
        padding: 3px 5px;
    }*/
    .transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show{
        /*margin-left: -97px;*/
        min-width: auto;
        padding: 0;

        z-index: 2;
        top: -5px !important; 
        left: 60px !important;
    }
    .transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .dropdown-item{
        background: #dedbf1;
        color: #2e1153;
        font-size: 12px;
        border: none;
    }
    .transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .dropdown-item:hover{
        background: #2e1153;
        color: #fff !important;
    }
    .transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .activate{
        background: #2e1153;
        color: #fff;
    }
    .transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .dropdown-item:first-child{
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
    .transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .dropdown-item:last-child{
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }


    .transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle{
        background: #dedbf1;
        padding: 4px 8px 2px;
        border-radius: 50%;
        font-size: 13px;
        border: 1px solid transparent;
        color: #826aa0;
        cursor: pointer;
    }
    .transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .transcript_documentation_part .single_doc_item .right .add_more_drop{
        z-index: auto !important;

        margin: 0 3.5px;
    }

    .transcript_documentation_part .single_doc_item .right img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }

    .transcript_documentation_part .single_doc_item .round_btn_groups{
        display: flex;
        width: 130px;
        justify-content: flex-end;
      }
      .transcript_documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-paperclip{
        padding: 4px 6px 4px !important;
        font-size: 13px;
      }
      .transcript_documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-upload, .transcript_documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-trash, .transcript_documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-pencil{
        color: #826aa0 !important;
      }
      .transcript_documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-eye{
        padding: 4px 5px !important;
        color: #826aa0 !important;
        font-size: 14px;
      }
      /*.transcript_documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle{
        padding: 4px 5px !important;
      }*/
      .transcript_documentation_part .single_doc_item .round_btn_groups .add_more_drop .font-icon{
        color: #826aa0 !important;
      }
</style>
