<template>
<section class="personal-info">

    <div class="row py-2 px-2">
        <div class="col-lg-12">
            <div style="min-height: 520px;">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form_part p-0">
                            <div class="stage_form_header stage_form_header_with_yes_no_radio">
                                <div class="left">
                                <i class="fa fa-info" aria-hidden="true" style="padding: 6px 11px;"></i>
                                </div>
                                <div class="right">
                                    <div class="with_yes_no_radio_inner">
                                        <div class="with_yes_no_radio_inner_left">
                                            <span class="main_title">Do you have any Visa?</span>
                                        </div>
                                        <div class="with_yes_no_radio_inner_right" v-if="list.lead">
                                            <div class="radio_box" style="place-content: flex-end;" v-if="!list.lead_has_applyuser_enrollment || list.lead.has_visa_history ==null">
                                                <label>
                                                    <input type="radio" class="toggle_decision toggle_visa" name="has_visa_history_div" value="1" v-model.number="form.has_visa_history" v-on:change="update_has_visa_history()"/>
                                                    <span>Yes</span>
                                                </label>
                                                <label>
                                                    <input type="radio" class="toggle_decision toggle_visa" name="has_visa_history_div" value="0" v-model.number="form.has_visa_history" v-on:change="update_has_visa_history()"/>
                                                    <span>No</span>
                                                </label>
                                            </div>
                                            <div class="radio_box" style="place-content: flex-end;" v-else-if="list.lead.has_visa_history ==1">
                                                <label>
                                                    <input type="radio" class="toggle_decision toggle_visa" name="has_visa_history_div" v-model.number="form.has_visa_history" value="1" v-on:change="update_has_visa_history()"/>
                                                    <span>Yes</span>
                                                </label>
                                            </div>
                                            <div class="radio_box" style="place-content: flex-end;" v-else-if="list.lead.has_visa_history ==0">
                                                <label>
                                                    <input type="radio" class="toggle_decision toggle_visa" v-model.number="form.has_visa_history" name="has_visa_history_div" value="0" v-on:change="update_has_visa_history()"/>
                                                    <span>No</span>
                                                </label>
                                            </div>
                                            <button style="margin-top:12px" class="btn submit_button add_visa ml-1" v-if="form.has_visa_history"  v-on:click="visa_history_add_btn()">
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="form.visa_history_add_edit_section">
                                <div class="has_visa_history_div" id="has_visa_history_div" style="background: rgb(245, 244, 253); padding: 10px; border-radius: 4px; margin-top: 10px;">
                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label class="form_label mb-1">Choose Destination Country<span class="color-red ml-1">*</span></label>
                                            <treeselect
                                                id="country_id"
                                                :options="list.countries"
                                                placeholder="Select Destination"
                                                autocomplete="off"
                                                v-model="form.visa_history_info.country_id"
                                            />
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label class="form_label mb-1">Visa Type<span class="color-red ml-1">*</span></label>
                                            
                                            <treeselect
                                                id="visa_type"
                                                :options="list.visa_types"
                                                placeholder="Select Visa Type"
                                                autocomplete="off"
                                                v-model="form.visa_history_info.visa_type"
                                            />
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label class="form_label mb-1">From (Date)<span class="color-red ml-1">*</span></label>
                                            <div class="input-group dateNicon">
                                                <flat-pickr
                                                    id="start_date"
                                                    v-model="form.visa_history_info.start_date"
                                                    :config="configs.flatpickr"
                                                    class="form-control date2 flatpickr-input"
                                                    placeholder="Date From"
                                                />
                                                <span class="input-group-addon">
                                                    <i
                                                    class="fa fa-calendar fa-lg mt-50"
                                                    aria-hidden="true"
                                                    ></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label class="form_label mb-1">To (Date)<span class="color-red ml-1">*</span></label>
                                            <div class="input-group dateNicon">
                                                <flat-pickr
                                                    id="end_date"
                                                    v-model="form.visa_history_info.end_date"
                                                    :config="configs.flatpickr"
                                                    class="form-control date2 flatpickr-input"
                                                    placeholder="Date to"
                                                />
                                                <span class="input-group-addon">
                                                    <i
                                                    class="fa fa-calendar fa-lg mt-50"
                                                    aria-hidden="true"
                                                    ></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-12 mb-0">
                                            <label class="form_label mb-1">Purpose</label>
                                            <input type="text" class="form-control" name="purpose" 
                                                v-model="form.visa_history_info.purpose" placeholder="Add a Purpose ...">
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right my-2 visa-btn">
                                    <button @click="formClose()" class="btn cancel_button mr-1 btn-cancel" style="padding: 4px 8px !important;">
                                        <i class="fa fa-close" aria-hidden="true"></i>
                                    </button>
                                    <button type="submit" class="btn submit_button" v-on:click="submit_visa_history()">
                                        {{form.visa_history_action}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="scrolled_content_part_outer" v-if="list.visa_history.length>0">
                            <div class="title_in_border">List of Visa History</div>
                            <div class="scrolled_content_part">
                                <div class="single_part" v-for="(visa_history_row, index) in list.visa_history" :key="index">
                                    <div class="left">
                                        <div class="first_part">
                                            <div class="first_part_left">
                                                <img class="img-responsive tooltips" v-bind:src="visa_history_row.country.flag_url" alt="Flag" data-placement="top" title="" data-original-title="American Samoa">
                                            </div>
                                            <div class="first_part_right">
                                                <span class="progress-lbl mr-1" style="font-size: 12px; font-weight: 600; color: black;">{{visa_history_row.visa_type.name}}</span>•
                                                <span style="font-size: 11px; color: #868e96;">
                                                {{visa_history_row.start_date_formated}} - {{visa_history_row.end_date_formated}}
                                                </span><br>
                                                <span style="color: #343434;font-weight: 400;">{{visa_history_row.purpose}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right">
                                        <div class="dropdown action_dropdown" :class="visa_history_row.show_menu?'show':''" v-on:click="visa_history_toggle_dropdown_item(visa_history_row)">
                                            <!-- <i class="fa fa-ellipsis-v dropdown-toggle"></i>
                                            <div class="dropdown-menu" :class="visa_history_row.show_menu?'show':''" style="position: absolute; transform: translate3d(0px, 20px, 0px); will-change: transform; top: 0px; left: 0px;">
                                                <span class="dropdown-item" v-on:click="visa_history_edit_btn(visa_history_row)">
                                                <i class="fa fa-pencil mr-50"></i>
                                                Edit
                                                </span>
                                                <span class="dropdown-item delete" v-on:click="delete_visa_history(visa_history_row, index)">
                                                <i class="fa fa-trash mr-50"></i>
                                                Delete
                                                </span>
                                            </div> -->
                                            <div class="d-flex">                                            
                                                <i v-tooltip="'Edit'" @click="visa_history_edit_btn(visa_history_row)" class="fa fa-pencil action_button mr-50"></i>
                                                <i v-tooltip="'Delete'" @click="delete_visa_history(visa_history_row, index)" class="fa fa-trash-o action_button"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="collaps_refusal_history">
                            <div class="form_part p-0">

                                <div class="stage_form_header stage_form_header_with_yes_no_radio">
                                <div class="left">
                                    <i class="fa fa-info" aria-hidden="true" style="padding: 6px 11px;"></i>
                                </div>
                                <div class="right">
                                    <div class="with_yes_no_radio_inner">
                                        <div class="with_yes_no_radio_inner_left">
                                            <span class="main_title">Do you have any Visa Refusal?</span>
                                        </div>
                                        <div class="with_yes_no_radio_inner_right" v-if="list.lead">
                                            <div class="radio_box" style="place-content: flex-end;" v-if="!list.lead_has_applyuser_enrollment || list.lead.has_visa_refusal ==null">
                                                <label>
                                                    <input type="radio" class="toggle_decision toggle_visa" name="has_visa_refusal_div" value="1" v-model.number="form.has_visa_refusal" v-on:change="update_has_visa_refusal()"/>
                                                    <span>Yes</span>
                                                </label>
                                                <label>
                                                    <input type="radio" class="toggle_decision toggle_visa" name="has_visa_refusal_div" value="0" v-model.number="form.has_visa_refusal" v-on:change="update_has_visa_refusal()"/>
                                                    <span>No</span>
                                                </label>
                                            </div>
                                            <div class="radio_box" style="place-content: flex-end;" v-else-if="list.lead.has_visa_refusal ==1">
                                                <label>
                                                    <input type="radio" class="toggle_decision toggle_visa" name="has_visa_refusal_div" v-model.number="form.has_visa_refusal" value="1" v-on:change="update_has_visa_refusal()"/>
                                                    <span>Yes</span>
                                                </label>
                                            </div>
                                            <div class="radio_box" style="place-content: flex-end;" v-else-if="list.lead.has_visa_refusal ==0">
                                                <label>
                                                    <input type="radio" class="toggle_decision toggle_visa" v-model.number="form.has_visa_refusal" name="has_visa_refusal_div" value="0" v-on:change="update_has_visa_refusal()"/>
                                                    <span>No</span>
                                                </label>
                                            </div>
                                            <button style="margin-top:12px;" class="btn submit_button add_visa ml-1" v-if="form.has_visa_refusal"  v-on:click="visa_refusal_add_btn()">
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                
                            <div v-if="form.visa_refusal_add_edit_section">
                                <div class="has_visa_refusal_div" id="has_visa_refusal_div" style="background: rgb(245, 244, 253); padding: 10px; border-radius: 4px; margin-top: 10px;">
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label class="form_label mb-1">Applied Country<span class="color-red ml-1">*</span></label>
                                        <treeselect
                                            id="country_id"
                                            :options="list.countries"
                                            placeholder="Select Country"
                                            autocomplete="off"
                                            v-model="form.visa_refusal_info.country_id"
                                        />
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label class="form_label mb-1">Visa Type<span class="color-red ml-1">*</span></label>
                                        
                                        <treeselect
                                            id="visa_type"
                                            :options="list.visa_types"
                                            placeholder="Select Visa Type"
                                            autocomplete="off"
                                            v-model="form.visa_refusal_info.visa_type"
                                        />
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label class="form_label mb-1">Rejection Date<span class="color-red ml-1">*</span></label>
                                        <div class="input-group dateNicon">
                                            <flat-pickr
                                                id="start_date"
                                                v-model="form.visa_refusal_info.rejection_date"
                                                :config="configs.flatpickr"
                                                class="form-control date2 flatpickr-input"
                                                placeholder="Rejection Date"
                                            />
                                            <span class="input-group-addon">
                                                <i
                                                class="fa fa-calendar fa-lg mt-50"
                                                aria-hidden="true"
                                                ></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-12 mb-0">
                                        <label class="form_label mb-1">Reason</label>
                                        <input type="text" class="form-control" name="reason" v-model="form.visa_refusal_info.reason" placeholder="Add a Reason ...">
                                    </div>
                                </div>
                                </div>

                                <div class="text-right my-2 refusal-btn">
                                    <button @click="formClose()" class="btn cancel_button mr-1 btn-cancel" style="padding: 4px 8px !important;">
                                    <i class="fa fa-close" aria-hidden="true"></i>
                                </button>
                                    <button type="submit" class="btn submit_button" v-on:click="submit_visa_refusal()">
                                        {{form.visa_refusal_action}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="scrolled_content_part_outer" v-if="list.visa_refusal.length>0">
                        <div class="title_in_border">List of Visa Refusal</div>
                        <div class="scrolled_content_part">
                            <div class="single_part" v-for="(visa_refusal_row, index) in list.visa_refusal" :key="index">
                                <div class="left">
                                    <div class="first_part">
                                        <div class="first_part_left">
                                            <img class="img-responsive tooltips" v-bind:src="visa_refusal_row.country_data.flag_url" alt="Flag" data-placement="top" title="" data-original-title="Antarctica">
                                        </div>
                                        
                                        <div class="first_part_right">
                                            <span class="progress-lbl mr-1" style="font-size: 12px; font-weight: 600; color: black;">{{visa_refusal_row.visa_type.name}}</span>•
                                            <span style="font-size: 11px; color: #868e96;">
                                            {{visa_refusal_row.rejection_date_formated}} 
                                            </span><br>
                                            <span style="color: #343434;font-weight: 400;">{{visa_refusal_row.reason}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="right">
                                    <div class="dropdown action_dropdown" :class="visa_refusal_row.show_menu?'show':''" v-on:click="visa_refusal_toggle_dropdown_item(visa_refusal_row)">
                                        <div class="d-flex">                                            
                                            <i v-tooltip="'Edit'" @click="visa_refusal_edit_btn(visa_refusal_row)" class="fa fa-pencil action_button mr-50"></i>
                                            <i v-tooltip="'Delete'" @click="delete_visa_refusal(visa_refusal_row, index)" class="fa fa-trash-o action_button"></i>
                                        </div>
                                        <!-- <i class="fa fa-ellipsis-v dropdown-toggle"></i>
                                        <div class="dropdown-menu" :class="visa_refusal_row.show_menu?'show':''" style="position: absolute; transform: translate3d(0px, 20px, 0px); will-change: transform; top: 0px; left: 0px;">
                                            <span class="dropdown-item" v-on:click="visa_refusal_edit_btn(visa_refusal_row)">
                                            <i class="fa fa-pencil mr-50"></i>
                                            Edit
                                            </span>
                                            <span class="dropdown-item delete" v-on:click="delete_visa_refusal(visa_refusal_row, index)">
                                            <i class="fa fa-trash mr-50"></i>
                                            Delete
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                                </div>

                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
            <button class="btn submit_button passport_submit_complete complete_step pull-right"
                style="bottom: 0; position: absolute; right: 15px"
                v-if="!flag.step_completed"
                v-on:click="complete_this_step()">
                Save &amp; Complete
            </button>
        </div>
    </div>
</div>

</section>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import swal from "bootstrap-sweetalert";
export default {
  name: 'VisaHistoryAndRefusal',
  components: {
    Treeselect,
  },
  data(){
    return {
      list:{
        lead:'',
        lead_has_applyuser_enrollment:false,
        countries:[],
        visa_types:[],
        visa_history:[],
        visa_refusal:[],
      },
      form: {
        visa_history_add_edit_section:false,
        visa_history_action:'Save',
        has_visa_history:null,

        visa_refusal_add_edit_section:false,
        visa_refusal_action:'Save',
        has_visa_refusal:null,
        visa_history_info: {
          edit_id:'',
          country_id: null,
          visa_type: null,
          start_date: "",
          end_date: "",
          purpose: "",
        },
        visa_refusal_info: {
          edit_id:'',
          country_id: null,
          visa_type: null,
          rejection_date: "",
          reason: "",
        },
      },
      configs: {
        flatpickr: {
          dateFormat: "d M Y"
        },
        
      },
      flag:{
        step_completed: false,
        show_dropdown_toggle:false
      }
    }
  },
  methods:{
    formClose: function () {
        this.$emit('stage-close')
    },
    fetch_visa_history_info: async function(){
      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v2/student/ajax/full_profile_stage_visa_history');

      try{
        var res=await jq.get(url);
        ref.list.lead=res.data.lead;
        ref.form.has_visa_history=res.data.lead.has_visa_history;
        ref.form.has_visa_refusal=res.data.lead.has_visa_refusal;
        ref.list.countries=res.data.countries;
        ref.list.visa_types=res.data.visa_types;
        ref.list.visa_history=res.data.visa_history;
        ref.list.visa_refusal=res.data.visa_refusal;
        ref.list.lead_has_applyuser_enrollment=res.data.lead_has_applyuser_enrollment;
        ref.flag.step_completed = res.data.step_completed;
        
      }catch(err){
        ref.alert(ref.err_msg(err), 'error');
      }

    },
    submit_visa_history: async function(){
        var ref = this;
        var jq = ref.jq();
        var url = ref.url("api/v2/student/ajax/save_full_profile_stage_visa_history");

        var form_data={
            action:ref.form.visa_history_action,
            edit_id:ref.form.visa_history_info.edit_id,
            country_id:ref.form.visa_history_info.country_id,
            visa_type:ref.form.visa_history_info.visa_type,
            start_date:ref.form.visa_history_info.start_date,
            end_date:ref.form.visa_history_info.end_date,
            purpose:ref.form.visa_history_info.purpose
        };

        try {

            ref.wait_me('#form_lead_visa_history');
            var res = await jq.post(url, form_data);
            ref.alert(res.msg);
            ref.fetch_visa_history_info();
            ref.reset_visa_history_data();
            this.$emit('dashboard-info-update', true)

        }catch(err){
            ref.alert(ref.err_msg(err), "error");
        }finally{
            ref.wait_me('#form_lead_visa_history', 'hide');
        }
    },
    reset_visa_history_data: function(){
        var ref=this;
        ref.form.visa_history_info.edit_id='';
        ref.form.visa_history_info.country_id=null;
        ref.form.visa_history_info.visa_type=null;
        ref.form.visa_history_info.start_date="";
        ref.form.visa_history_info.end_date="";
        ref.form.visa_history_info.purpose="";
        ref.form.visa_history_action='Save';
        ref.form.visa_history_add_edit_section=false;
    },
    visa_history_close_btn: function(){
        var ref=this;
        ref.form.visa_history_action='';
        ref.form.visa_history_add_edit_section=false;
    },
    visa_history_add_btn: function(){
        var ref=this;
        ref.reset_visa_history_data();
        ref.form.visa_history_action='Save';
        ref.form.visa_history_add_edit_section=true;
    },
    
    visa_history_toggle_dropdown_item: function(row){
        this.list.visa_history.forEach(function(each){
        if(row.id!=each.id) each.show_menu=false;
      });
      row.show_menu=!row.show_menu;
    },
    visa_history_edit_btn: function(row){
        var ref=this;
        ref.form.visa_history_info.edit_id=row.id; 
        ref.form.visa_history_info.country_id=row.country_id;
        ref.form.visa_history_info.visa_type=row.visa_type_id;
        ref.form.visa_history_info.start_date=row.start_date;
        ref.form.visa_history_info.end_date=row.end_date;
        ref.form.visa_history_info.purpose=row.purpose;
        ref.form.visa_history_action='Update';
        ref.form.visa_history_add_edit_section=true;
    },
    update_has_visa_history: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/save_full_profile_stage_visa_history");
      var jq = ref.jq();

      swal(
        {
          title: "Are you sure?",
          text: "Press Yes to confirm.",
          type: "info",
          showCancelButton: true,
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-light",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, {action:'Status_change',value:ref.form.has_visa_history});
                ref.alert(res.msg);
                ref.fetch_visa_history_info();
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");

                if (ref.form.has_visa_history == 1) {
                  ref.form.has_visa_history = 0;
                } else {
                  ref.form.has_visa_history = 1;
                }
              }
            })();
          } else {
            if (ref.form.has_visa_history == 1) {
              ref.form.has_visa_history = 0;
            } else {
              ref.form.has_visa_history = 1;
            }
          }
        }
      );
    },
    delete_visa_history: function(row, row_index){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/save_full_profile_stage_visa_history");
      var jq = ref.jq();

      swal({
        title: "Are you sure? You want to delete this information",
        text: "Press Yes to confirm.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, {action:'Delete',delete_id:row.id});
                ref.alert(res.msg);
                ref.remove_row(ref.list.visa_history, row_index);
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    },


    submit_visa_refusal: async function(){
        var ref = this;
        var jq = ref.jq();
        var url = ref.url("api/v2/student/ajax/save_full_profile_stage_visa_refusal");

        var form_data={
            action:ref.form.visa_refusal_action,
            edit_id:ref.form.visa_refusal_info.edit_id,
            country_id:ref.form.visa_refusal_info.country_id,
            visa_type:ref.form.visa_refusal_info.visa_type,
            rejection_date:ref.form.visa_refusal_info.rejection_date,
            reason:ref.form.visa_refusal_info.reason
        };

        try {

            ref.wait_me('#form_lead_visa_history');
            var res = await jq.post(url, form_data);
            ref.alert(res.msg);
            ref.fetch_visa_history_info();
            ref.reset_visa_refusal_data();
            this.$emit('dashboard-info-update', true)

        }catch(err){
            ref.alert(ref.err_msg(err), "error");
        }finally{
            ref.wait_me('#form_lead_visa_history', 'hide');
        }
    },
    reset_visa_refusal_data: function(){
        var ref=this;
        ref.form.visa_refusal_info.country_id=null;
        ref.form.visa_refusal_info.visa_type=null;
        ref.form.visa_refusal_info.rejection_date="";
        ref.form.visa_refusal_info.reason="";
        ref.form.visa_refusal_action='';
        ref.form.visa_refusal_add_edit_section=false;
    },
    visa_refusal_close_btn: function(){
        var ref=this;
        ref.form.visa_refusal_action='';
        ref.form.visa_refusal_add_edit_section=false;
    },
    visa_refusal_add_btn: function(){
        var ref=this;
        ref.reset_visa_refusal_data();
        ref.form.visa_refusal_action='Save';
        ref.form.visa_refusal_add_edit_section=true;
    },
    visa_refusal_toggle_dropdown_item: function(row){
        this.list.visa_refusal.forEach(function(each){
        if(row.id!=each.id) each.show_menu=false;
      });
      row.show_menu=!row.show_menu;
    },
    visa_refusal_edit_btn: function(row){
        var ref=this;
        ref.form.visa_refusal_info.edit_id=row.id; 
        ref.form.visa_refusal_info.country_id=row.country;
        ref.form.visa_refusal_info.visa_type=row.visa_type_id;
        ref.form.visa_refusal_info.rejection_date=row.rejection_date;
        ref.form.visa_refusal_info.reason=row.reason;
        ref.form.visa_refusal_action='Update';
        ref.form.visa_refusal_add_edit_section=true;
    },
    update_has_visa_refusal: async function () {
      var ref = this;
      var url = ref.url("api/v2/student/ajax/save_full_profile_stage_visa_refusal");
      var jq = ref.jq();

      swal(
        {
          title: "Are you sure?",
          text: "Press Yes to confirm.",
          type: "info",
          showCancelButton: true,
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-light",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, {action:'Status_change',value:ref.form.has_visa_refusal});
                ref.alert(res.msg);
                ref.fetch_visa_history_info();
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");

                if (ref.form.has_visa_refusal == 1) {
                  ref.form.has_visa_refusal = 0;
                } else {
                  ref.form.has_visa_refusal = 1;
                }
              }
            })();
          } else {
            if (ref.form.has_visa_refusal == 1) {
              ref.form.has_visa_refusal = 0;
            } else {
              ref.form.has_visa_refusal = 1;
            }
          }
        }
      );
    },
    delete_visa_refusal: function(row, row_index){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/save_full_profile_stage_visa_refusal");
      var jq = ref.jq();

      swal({
        title: "Are you sure? You want to delete this information",
        text: "Press Yes to confirm.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, {action:'Delete',delete_id:row.id});
                ref.alert(res.msg);
                ref.remove_row(ref.list.visa_refusal, row_index);
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    },
    complete_this_step: async function(){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/lead_visa_history_and_refusal_step_completed");
      var jq = ref.jq();

      swal({
        // title: "Are you sure? You want to submit your visa history & refusal information as completed.",
        title: "Are you sure?",
        text: "Press Yes to confirm.",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url);
                ref.alert(res.msg);
                ref.fetch_visa_history_info();

                ref.$emit("save-and-completed", true);
                ref.$emit('dashboard-info-update', true)
                
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    }
  },
  created: function(){
    this.setup_urls();
    this.fetch_visa_history_info();
  }
  
}
</script>

<style scoped>

    .pretty{
        font-size: 12px;
    }

    .input-group-addon:hover {
        background-color: #00a8ff;
        border: none;
        border-color: #00a8ff;
        color: #fff !important;
    }
    .dateNicon input {
        background: #fff !important;
        border-right: none;
    }
    .input-group-addon {
        background: #fff;
        padding: 0 .75rem;
    }
    .glyphicon{
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 15px;
    }

    .scrolled_content_part {
        /*padding: 20px 10px;*/

        padding: 0;
    }
    .scrolled_content_part .single_part {
        display: flex;
        width: 100%;
        /*padding: 10px 15px;
        border: none;
        border-bottom: 1px dashed #e6e8e8;*/

        padding: 7px 16px;
        margin-top: 10px;
        border-radius: .25rem;
        border: 1px solid #e9f0f3;

        align-items: center;
    }
    /*.scrolled_content_part .single_part:last-child {
        border-bottom: none;
    }*/
    .scrolled_content_part .single_part .left {
        width: 100%;
        position: relative;
    }
    .scrolled_content_part .single_part .left h3, .scrolled_content_part .single_part .right h3 {
        font-size: 11px; 
        font-weight: bold; 
        color: rgb(98, 95, 139); 
        margin-bottom: 6px;
    }
    .scrolled_content_part .single_part .left .first_part{
        display: flex;
        width: 100%;
    }
    .scrolled_content_part .single_part .left .first_part .first_part_left img{ 
        width: 20px; 
        height: 20px;
        border-radius: 50%;
        margin-left: -2px;
    }
    .scrolled_content_part .single_part .left .first_part .first_part_right{
        line-height: 1.2;
    }
    .scrolled_content_part .single_part .left .upper_part{
        display: flex;
        width: 100%;
    }
    .scrolled_content_part .single_part .left .upper_part .upper_part_left{
        width: 36px;
        text-align: center;
        padding-right: 8px;
    }
    .scrolled_content_part .single_part .left .upper_part .upper_part_right{
        width: auto;
    }

    #new_drop {
        background-color: transparent !important;
        border-color: transparent !important;
    }


    .scrolled_content_part_outer{
        position: relative;
        border-radius: .25rem;
        margin-top: 25px;
    }
    .scrolled_content_part_outer .title_in_border {
        background: #dedbf1;
        padding: 7px 16px;
        margin-top: 10px;
        border-radius: 30px;
        position: relative;
        font-size: 12px;
        font-weight: 700;
        width: inherit;
        left: 0;
        top: -10px;
    }

    .stage_form_header_with_yes_no_radio{
        background: #fff !important;
        border: 1px solid #dedbf1 !important;
    }
    .stage_form_header .with_yes_no_radio_inner{
        display: flex; 
        width: 100%;
    }
    .stage_form_header .with_yes_no_radio_inner .with_yes_no_radio_inner_left{
        width: calc(100% - 200px);
        align-self: center;
    }
    .stage_form_header .with_yes_no_radio_inner .with_yes_no_radio_inner_right{
        width: 200px;
        display: flex;
    }

    .action_dropdown .dropdown-toggle{
        background: #dedbf1;
        padding: 4px 9px 3px;
        border-radius: 50%;
        font-size: 13px;
        border: 1px solid transparent;
        color: #826aa0;
        cursor: pointer;
    }
    .action_dropdown .dropdown-toggle:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .action_dropdown .dropdown-toggle:after{
        display: none;
    }
    .action_dropdown .dropdown-menu.show{
        margin-left: -55px;
        min-width: auto;
        padding: 0;
        margin-top: 3px;
    }
    .action_dropdown .dropdown-menu.show .dropdown-item{
        background: #dedbf1;
        color: #2e1153;
        font-size: 12px;
        border: none;
        padding: 4px 12px;
    }
    .action_dropdown .dropdown-menu.show .dropdown-item:hover{
        background: #2e1153;
        color: #fff !important;
    }
    .action_dropdown .dropdown-menu.show .activate{
        background: #2e1153;
        color: #fff;
    }
    .action_dropdown .dropdown-menu.show .dropdown-item:first-child{
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
    .action_dropdown .dropdown-menu.show .dropdown-item:last-child{
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }
    .scrolled_content_part .single_part .left .first_part .first_part_left {
        width: 26px;
        align-self: center;
    }
    .scrolled_content_part .single_part .left .first_part .first_part_left img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-left: -2px;
    }
    .scrolled_content_part .single_part .left .first_part .first_part_right {
        width: calc(100% - 26px);
        align-self: center;
    }
    .scrolled_content_part .single_part .right {
        width: 50px;
        align-self: center;
        text-align: right;
    }
    .cancel_button {
        background-color: #ffc107;
        border-width: 1px;
        border-color: #ffc107;
        color: #fff;
        border-radius: 30px;
        padding: 0 15px;
        height: 28px;
        font-size: .9rem;
        margin-right: 8px !important;
    }
    .submit_button {
        background-color: #2e1153;
        border-width: 1px;
        border-color: #2e1153;
        color: #fff;
        border-radius: 30px;
        padding: 0 15px;
        height: 28px;
        font-size: .9rem;
        line-height: 2;
    }

</style>
