<template>
<section class="container">
        <div class="content-body" style="margin-top:50px;" v-if="listData && listData.length">
            <!-- <section id="input-sizing">
                <div class="row match-height">
                    <div class="col-md-8 col-md-offset-4 m-auto">
                        <div class="card">
                            <div class="card-body wait_me_insert_form">
                                <div class="form-row">
                                    <div class="col-md-12 font-size-14">
                                        <div>
                                            <p>"Thank you. You have authorised <b v-html="cn(leadData, 'company.name')"></b> to help you secure a place on course(s) of study. If you have any queries, please get in touch."</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->
            


            <div class="ad_pref_part p-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-lg-10 text-center">
                                <h3 class="text-center">This student needs GDPR for these applications</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 create_admission_preference">
                        <div class="ad_pref_item_header">
                            <div class="destination">
                                Destination
                            </div>
                            <div class="second">
                                University Details
                            </div>
                            <div class="third">
                                Course Information
                            </div>

                            <div class="last">
                                Action
                            </div>
                        </div>

                        <div class="scrolled_content_part" v-if="listData && listData.length">
                            <div class="single_ad_pref_item" v-for="(item, index) in listData" :key="index">
                                <div class="destination">
                                    <div class="destination_left" v-if="item.country">
                                        <img :src="item.country.flag_url" alt="Flag" v-tooltip="item.country.name" />
                                    </div>
                                    <div class="destination_info">
                                        <strong v-if="item.country" v-html="item.country.name"></strong>
                                    </div>
                                </div>

                                <div class="left">
                                    <div class="uni_details">
                                        <div class="inner_left">
                                            <i class="fa fa fa-university"></i>
                                        </div>
                                        <div class="inner_right">
                                            <strong v-if="item.university" v-html="item.university.name"></strong> <br />
                                            <span class="label_badge_radius" v-if="item.level" v-html="item.level.name"></span>
                                            <span v-if="item.start_date"> • </span>
                                            <span class="label_badge_radius" v-if="item.start_date" v-html="dMonth(item.start_date)"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="middle">
                                    <span v-if="item.priority == 1" class="label_badge_radius" style="background: #d2f9ea; color: #009845;">1st Choice</span>
                                    <span v-else-if="item.priority == 3" class="label_badge_radius" style="background: #ffedcc; color: orange;">2nd Choice</span>
                                    <span v-else-if="item.priority == 2" class="label_badge_radius" style="background: #dbdbdb; color: #686767;">3rd Choice</span>
                                </div>

                                <div class="right">
                                    <!-- <span class="fa fa-check-square ad_pref_round_action" style="padding: 4px 5px 3px;"></span> -->
                                    <div class="pretty p-default p-curve req_sou_pretty">
                                        <input class="requirement_source" type="checkbox" v-model="form.enrollment_ids" :value="item.id">
                                        <div class="state p-primary-o">
                                            <label>
                                                <!-- <small>Check</small> -->
                                            </label>
                                        </div>
                                    </div>

                                    <div class="btn-group btn-group-sm vue-dropdown" style="float: none;">
                                        <div v-if="!item.university" style="display: flex; justify-content: flex-end;">
                                            <span @click="editData(item)" class="btn pull-right submit_btn_sm mr-25 enroll-edit" style="margin-top: 1px;">Update</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="scrolled_content_part">
                            <div class="alert mb-1" role="alert" style="border-color: #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                                <span>No Admission Preference available.</span>
                            </div>
                        </div>
                    </div>
                </div>

            <div class="row">
                <div class="col-lg-12 text-right">
                    <button class="btn submit_button mt-2"
                        v-on:click="updateGdprAgreement()">
                        Submit
                    </button>
                </div>
            </div>
            </div>



        </div>
        <div class="content-body" style="margin-top:50px;" v-if="thankYou">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="text-center" v-html="thankYou"></h2>
                </div>
            </div>
        </div>
</section>
</template>

<script>
// import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
// import swal from 'bootstrap-sweetalert';
// import { EditIcon } from 'vue-feather-icons'
// import { AlertTriangleIcon } from 'vue-feather-icons'

export default{
    name: 'GdprAgreementConfirmation',
    metaInfo: {
        titleTemplate: 'Student Portal | Gdpr Agreement Confirmation'
    },
    components:{
        // MainLayout,
        // EditIcon,
    },
    data:()=>{
        return {
            saveData: true,
            leadData: {},
            showModal: false,
            icon: 'edit',
            flag:{
                show_modal_form:false
            },
            form: {
                enrollment_ids: []
            },
            listData: [],
            errorMessages: {},
            thankYou: ''
        };
    },
    computed: {
        store_auth_user:function(){
            return this.$store.state.site.auth_user;
        },
    },
    created: async function () {
        this.setup_urls();
    },
    methods:{
        getGdprAgreementData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                // const gdpr_agreement_id = this.hash_id(this.$route.params.gdpr_agreement_id, false)[0]
                // const params = { id: gdpr_agreement_id }
                const lead_id = this.hash_id(this.$route.params.lead_id, false)[0]
                const params = { lead_id: lead_id }
                var res = await jq.get(ref.url('api/v2/student/auth/get_gdpr_agreement_data'), params);
                this.listData = res.data.gdpr_enrollment_list
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateGdprAgreement: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                const lead_id = this.hash_id(this.$route.params.lead_id, false)[0]
                const params = { 
                    lead_id: lead_id,
                    enrollment_ids: this.form.enrollment_ids
                }
                console.log('params', params)
                var res = await jq.post(ref.url('api/v2/student/auth/update_gdpr_agreement_data'), params);
                ref.alert(res.msg);
                this.thankYou = 'Thank you for your consent against our GDPR policy. '
                this.getGdprAgreementData()
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateGdprAgreementOld: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                const gdpr_agreement_id = this.hash_id(this.$route.params.gdpr_agreement_id, false)[0]
                const params = { id: gdpr_agreement_id }
                var res = await jq.post(ref.url('api/v2/student/auth/update_gdpr_agreement_data'), params);
                ref.leadData = res.data.lead
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
    },
    mounted: function(){
        feather.replace();
        // this.updateGdprAgreement()
        this.getGdprAgreementData()
    }
}
</script>

<style scoped>
.pretty .state label:before {
    border-color: #2e1153;
}

.pretty.p-default:not(.p-fill) input:checked~.state.p-primary-o label:after {
    background-color: #2e1153!important;
}
</style>