<template>
  <div class="box-typical box-typical-padding" style="border: none;">
    <!-- <div v-if="studentAllInfo.educational_documents.length || studentAllInfo.work_history_documents.length ||
    studentAllInfo.reference_documents.length || studentAllInfo.visa_history_documents.length" class="documentation_part p-0"> -->
    <div class="documentation_part p-0">

      <div class="stage_form_header stage_form_header_pro_tab_info d-flex p-50 font-size-12 color-navy-blue">
        <div class="left" style="width: 30px;">
          <i class="fa fa-info font-size-14" aria-hidden="true" style="padding: 6px 11px; background: #e7f2fb; border: none;"
          ></i>
        </div>
        <div class="right" style="align-self: center;">
          <span class="main_title font-weight-bold">Please upload Personal Statement and updated CV (if any) in
            Additional Documents under Document Tab.</span>
        </div>
      </div>

        <div class="row" id="reload_edu_history_doc" v-if="studentAllInfo.educational_documents && studentAllInfo.educational_documents.length">
          <div class="col-md-12">
              <div class="content_header ml-0">
                <div class="left">
                    <i class="fa fa-graduation-cap" aria-hidden="true" style="padding: 7px 6px;"></i>
                </div>
                <div class="right">
                    <span class="main_title">
                    Education History
                    </span>
                </div>
              </div>
          </div>
          <div class="col-md-12 overflow_div_responsive" v-if="studentAllInfo.educational_documents && studentAllInfo.educational_documents.length">
              <div class="single_doc_item" v-for="(row, index) in studentAllInfo.educational_documents" :key="index">
                <div class="left">
                    <i class="fa fa-file-pdf-o" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                    <strong v-if="row.document_edu" v-html="row.document_edu.institution"></strong>
                    <span v-if="row.name"> • </span>
                    <span style="font-size: 12px; color: rgb(134, 142, 150);" v-html="row.name"></span>
                    <span v-if="row.document_edu"> • </span>
                    <span v-if="row.document_edu" v-html="row.document_edu.subject" style="font-size: 12px;"></span> 
                </div>
                <div class="right">
                    <div class="round_btn_groups">
                      <div class="add_more_drop tooltips" v-if="row.file_url">
                        <i v-tooltip="'View File'" @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                      </div>
                      <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                        <div class="add_more_drop open_dynamic_modal tooltips">
                            <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                        </div>
                      </a>
                      <div @click="openDocumentUploadModal(row.id)" v-if="!studentAllInfo.lead.file_number && !studentAllInfo.student.steps_submitted_at" class="add_more_drop">
                          <i v-tooltip="'Upload File'" class="fa fa-upload dropdown-toggle"></i>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          <div class="col-md-12 mt-2" v-else>
            <div role="alert" class="alert mb-1" style="border:1px solid rgb(233, 240, 243); font-size: 12px !important; color: rgb(220, 53, 69); border-radius: 30px; padding: 8px 15px; margin-left: 40px;"><span>No Education History available.</span></div>
          </div>
        </div>

        <div class="row" id="reload_edu_history_doc" v-if="studentAllInfo.work_history_documents && studentAllInfo.work_history_documents.length" style="margin-top:50px;">
          <div class="col-md-12">
              <div class="content_header ml-0">
                <div class="left">
                    <i class="fa fa-graduation-cap" aria-hidden="true" style="padding: 7px 6px;"></i>
                </div>
                <div class="right">
                    <span class="main_title">
                    Work Experience
                    </span>
                </div>
              </div>
          </div>
          <div class="col-md-12 overflow_div_responsive" v-if="studentAllInfo.work_history_documents && studentAllInfo.work_history_documents.length">

              <div class="single_doc_item" v-for="(row, index) in studentAllInfo.work_history_documents" :key="index">
                <div class="left">
                    <i class="fa fa-file-pdf-o" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                    <strong  v-html="row.name"></strong>
                    <span v-if="row.document_work_history"> • </span>
                    <small style="font-size: 12px; color: rgb(134, 142, 150);"  v-if="row.document_work_history" v-html="row.document_work_history.company_name"></small>
                    <span v-if="row.document_work_history"> • </span>
                    <span  v-if="row.document_work_history" v-html="row.document_work_history.designation" style="font-size: 12px;"></span> 
                </div>
                
                <div class="right">
                    <div class="round_btn_groups">
                      <div class="add_more_drop tooltips" v-if="row.file_url">
                        <i v-tooltip="'View File'" @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                      </div>
                      <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                        <div class="add_more_drop open_dynamic_modal tooltips">
                            <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                        </div>
                      </a>
                      <div @click="openDocumentUploadModal(row.id)" v-if="!studentAllInfo.lead.file_number && !studentAllInfo.student.steps_submitted_at" class="add_more_drop">
                          <i v-tooltip="'Upload File'" class="fa fa-upload dropdown-toggle"></i>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          
          <div class="col-md-12 mt-2" v-else>
            <div role="alert" class="alert mb-1" style="border:1px solid rgb(233, 240, 243); font-size: 12px !important; color: rgb(220, 53, 69); border-radius: 30px; padding: 8px 15px; margin-left: 40px;"><span>No Work Experience available.</span></div>
          </div>
        </div>

        <div id="reload_edu_history_doc" v-if="studentAllInfo.english_test_documents && studentAllInfo.english_test_documents.length" class="row" style="margin-top:50px;">
          <div class="col-md-12">
              <div class="content_header ml-0">
                <div class="left">
                    <i class="fa fa-align-center" aria-hidden="true" style="padding: 7px 6px;"></i>
                </div>
                <div class="right">
                    <span class="main_title">
                    Test Score
                    </span>
                </div>
              </div>
          </div>
          <div class="col-md-12 overflow_div_responsive" v-if="studentAllInfo.english_test_documents && studentAllInfo.english_test_documents.length">

              <div class="single_doc_item" v-for="(row, index) in studentAllInfo.english_test_documents" :key="index">
                <div class="left">
                    <i class="fa fa-file-pdf-o" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                    <strong  v-html="row.test_name"></strong>
                    <span v-if="row.name"> • </span>
                    <small style="font-size: 12px; color: rgb(134, 142, 150);"  v-if="row.name" v-html="row.name"></small>
                </div>
                
                <div class="right">
                    <div class="round_btn_groups">
                      <div class="add_more_drop tooltips" v-if="row.file_url">
                        <i v-tooltip="'View File'" @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                      </div>
                      <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                        <div class="add_more_drop open_dynamic_modal tooltips">
                            <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                        </div>
                      </a>
                      <div @click="openDocumentUploadModal(row.id)" v-if="!studentAllInfo.lead.file_number && !studentAllInfo.student.steps_submitted_at" class="add_more_drop">
                          <i v-tooltip="'Upload File'" class="fa fa-upload dropdown-toggle"></i>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          
          <div class="col-md-12 mt-2" v-else>
            <div role="alert" class="alert mb-1" style="border:1px solid rgb(233, 240, 243); font-size: 12px !important; color: rgb(220, 53, 69); border-radius: 30px; padding: 8px 15px; margin-left: 40px;"><span>No Test Score available.</span></div>
          </div>
        </div>

        <div id="reload_edu_history_doc" v-if="studentAllInfo.reference_documents && studentAllInfo.reference_documents.length" class="row" style="margin-top:50px;">
          <div class="col-md-12">
              <div class="content_header ml-0">
                <div class="left">
                    <i class="fa fa-user-plus" aria-hidden="true" style="padding: 7px 6px;"></i>
                </div>
                <div class="right">
                    <span class="main_title">
                      References
                    </span>
                </div>
              </div>
          </div>
          <div class="col-md-12 overflow_div_responsive" v-if="studentAllInfo.reference_documents && studentAllInfo.reference_documents.length">

              <div class="single_doc_item"  v-for="(row, index) in studentAllInfo.reference_documents" :key="index">
                <div class="left">
                    <i class="fa fa-file-pdf-o" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                    <strong  v-html="row.name"></strong>
                    <span v-if="row.lead_reference"> • </span>
                    <small style="font-size: 12px; color: rgb(134, 142, 150);"  v-if="row.lead_reference" v-html="row.lead_reference.name"></small>
                    <span v-if="row.lead_reference"> • </span>
                    <span  v-if="row.lead_reference" v-html="row.lead_reference.designation" style="font-size: 12px;"></span> 
                </div>
                <div class="right">
                    <div class="round_btn_groups">
                      <div class="add_more_drop tooltips" v-if="row.file_url">
                        <i v-tooltip="'View File'" @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                      </div>
                      <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                        <div class="add_more_drop open_dynamic_modal tooltips">
                            <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                        </div>
                      </a>
                      <div @click="openDocumentUploadModal(row.id)" v-if="!studentAllInfo.lead.file_number && !studentAllInfo.student.steps_submitted_at" class="add_more_drop">
                          <i v-tooltip="'Upload File'" class="fa fa-upload dropdown-toggle"></i>
                      </div>
                    </div>
                </div>
                <!-- <div class="right">
                    <div class="round_btn_groups">
                      <a href=" javascript:void(0)" onclick="pdfviewer('eyJpdiI6IkxrS2w1ZVZoZm04ZklDK3pkQXFHZWc9PSIsInZhbHVlIjoiVmtmUFJldVArTFdza1BcL0w1ZzdSdHc9PSIsIm1hYyI6IjY2ZGUxOGI4NGM1ODJiZmJmNGViY2JlYjBkMWMzY2MyZTYzOWJhNzliNDQ4NzkwZmYwNDViNTI5MjZkNWQ5YTcifQ==');" style="border-bottom: none;">
                          <div class="add_more_drop tooltips" title="" data-original-title="View File">
                            <i class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                          </div>
                      </a>
                      <div class="add_more_drop open_dynamic_modal tooltips" title="">
                          <i class="fa fa-upload dropdown-toggle"></i>
                      </div>
                    </div>
                </div>-->
              </div>
          </div>
          
          <div class="col-md-12 mt-2" v-else>
            <div role="alert" class="alert mb-1" style="border:1px solid rgb(233, 240, 243); font-size: 12px !important; color: rgb(220, 53, 69); border-radius: 30px; padding: 8px 15px; margin-left: 40px;"><span>No Reference available.</span></div>
          </div>
        </div>

        <div id="reload_edu_history_doc" v-if="studentAllInfo.visa_history_documents && studentAllInfo.visa_history_documents.length" class="row" style="margin-top:50px;">
          <div class="col-md-12">
              <div class="content_header ml-0">
                <div class="left">
                    <i class="bx bxs-spreadsheet" aria-hidden="true" style="padding: 7px; font-size: 16px;"></i>
                </div>
                <div class="right">
                    <span class="main_title">
                      Visa Details
                    </span>
                </div>
              </div>
          </div>
          <div class="col-md-12 overflow_div_responsive" v-if="studentAllInfo.visa_history_documents && studentAllInfo.visa_history_documents.length">
              <div class="single_doc_item"  v-for="(row, index) in studentAllInfo.visa_history_documents" :key="index">
                <div class="left">
                    <i class="fa fa-file-pdf-o" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                    <strong  v-html="row.name"></strong>
                    <span v-if="row.document_visa_history && row.document_visa_history.country"> • </span>
                    <small style="font-size: 12px; color: rgb(134, 142, 150);" v-if="row.document_visa_history && row.document_visa_history.country" v-html="row.document_visa_history.country.name"></small>
                    <span v-if="row.document_visa_history && row.document_visa_history.visa_type"> • </span>
                    <span  v-if="row.document_visa_history && row.document_visa_history.visa_type" v-html="row.document_visa_history.visa_type.name" style="font-size: 12px;"></span> 
                </div>
                <div class="right">
                    <div class="round_btn_groups">
                      <div class="add_more_drop tooltips" v-if="row.file_url">
                        <i v-tooltip="'View File'" @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                      </div>
                      <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                        <div class="add_more_drop open_dynamic_modal tooltips">
                            <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                        </div>
                      </a>
                      <div @click="openDocumentUploadModal(row.id)" v-if="!studentAllInfo.lead.file_number && !studentAllInfo.student.steps_submitted_at" class="add_more_drop">
                          <i v-tooltip="'Upload File'" class="fa fa-upload dropdown-toggle"></i>
                      </div>
                    </div>
                </div>
                <!-- <div class="right">
                    <div class="round_btn_groups">
                      <a href=" javascript:void(0)" onclick="pdfviewer('eyJpdiI6IkxrS2w1ZVZoZm04ZklDK3pkQXFHZWc9PSIsInZhbHVlIjoiVmtmUFJldVArTFdza1BcL0w1ZzdSdHc9PSIsIm1hYyI6IjY2ZGUxOGI4NGM1ODJiZmJmNGViY2JlYjBkMWMzY2MyZTYzOWJhNzliNDQ4NzkwZmYwNDViNTI5MjZkNWQ5YTcifQ==');" style="border-bottom: none;">
                          <div class="add_more_drop tooltips" title="" data-original-title="View File">
                            <i class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                          </div>
                      </a>
                      <div class="add_more_drop open_dynamic_modal tooltips" title="">
                          <i class="fa fa-upload dropdown-toggle"></i>
                      </div>
                    </div>
                </div>-->
              </div>
          </div>
          
          <div class="col-md-12 mt-2" v-else>
            <div role="alert" class="alert mb-1" style="border:1px solid rgb(233, 240, 243); font-size: 12px !important; color: rgb(220, 53, 69); border-radius: 30px; padding: 8px 15px; margin-left: 40px;"><span>No Visa Details available.</span></div>
          </div>
        </div>

        <div id="reload_edu_history_doc" v-if="studentAllInfo.passport_documents && studentAllInfo.passport_documents.length" class="row" style="margin-top:50px;">
          <div class="col-md-12">
              <div class="content_header ml-0">
                <div class="left">
                    <i class="bx bxs-spreadsheet" aria-hidden="true" style="padding: 7px; font-size: 16px;"></i>
                </div>
                <div class="right">
                    <span class="main_title">
                      Passport
                    </span>
                </div>
              </div>
          </div>
          <div class="col-md-12 overflow_div_responsive" v-if="studentAllInfo.passport_documents && studentAllInfo.passport_documents.length">
              <div class="single_doc_item" v-for="(row, index) in studentAllInfo.passport_documents" :key="index">
                <div class="left">
                    <i class="fa fa-file-pdf-o" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                    <strong v-if="row.document_passport" v-html="row.document_passport.passport_number"></strong>
                    <span v-if="row.document_passport"> • </span>
                    <small style="font-size: 12px; color: rgb(134, 142, 150);" v-if="row.name" v-html="row.name"></small>
                </div>
                <div class="right">
                    <div class="round_btn_groups">
                      <div class="add_more_drop tooltips" v-if="row.file_url">
                        <i v-tooltip="'View File'" @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                      </div>
                      <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                        <div class="add_more_drop open_dynamic_modal tooltips">
                            <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                        </div>
                      </a>
                      <div @click="openDocumentUploadModal(row.id)" v-if="!studentAllInfo.lead.file_number && !studentAllInfo.student.steps_submitted_at" class="add_more_drop">
                          <i v-tooltip="'Upload File'" class="fa fa-upload dropdown-toggle"></i>
                      </div>
                    </div>
                </div>
                <!-- <div class="right">
                    <div class="round_btn_groups">
                      <a href=" javascript:void(0)" onclick="pdfviewer('eyJpdiI6IkxrS2w1ZVZoZm04ZklDK3pkQXFHZWc9PSIsInZhbHVlIjoiVmtmUFJldVArTFdza1BcL0w1ZzdSdHc9PSIsIm1hYyI6IjY2ZGUxOGI4NGM1ODJiZmJmNGViY2JlYjBkMWMzY2MyZTYzOWJhNzliNDQ4NzkwZmYwNDViNTI5MjZkNWQ5YTcifQ==');" style="border-bottom: none;">
                          <div class="add_more_drop tooltips" title="" data-original-title="View File">
                            <i class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                          </div>
                      </a>
                      <div class="add_more_drop open_dynamic_modal tooltips" title="">
                          <i class="fa fa-upload dropdown-toggle"></i>
                      </div>
                    </div>
                </div>-->
              </div>
          </div>
          
          <div class="col-md-12 mt-2" v-else>
            <div role="alert" class="alert mb-1" style="border:1px solid rgb(233, 240, 243); font-size: 12px !important; color: rgb(220, 53, 69); border-radius: 30px; padding: 8px 15px; margin-left: 40px;"><span>No Visa Details available.</span></div>
          </div>
        </div>

        <div id="reload_edu_history_doc" class="full_profile_additional_doc" style="padding: 10px; border-radius: 0.25rem; border: 1px solid #e9f0f3; background: ghostwhite; margin-top: 50px;">
          <div class="row">
          <div class="col-md-6">
              <div class="content_header ml-0">
                <div class="left">
                    <i class="fa fa-graduation-cap" aria-hidden="true" style="padding: 7px 6px;"></i>
                </div>
                <div class="right">
                    <span class="main_title">
                      Additional Documentations
                    </span>
                </div>
              </div>
          </div>
          <div class="col-md-6 text-right">
              <button class="submit_button" @click="$refs.additional_document_modal.show()">Upload New</button>
          </div>
          <div class="col-md-12 overflow_div_responsive" v-if="studentAllInfo.lead">
              <div class="single_doc_item"  v-for="(row, index) in studentAllInfo.lead.lead_additional_files" :key="index">
                <div class="left">
                    <i class="fa fa-file-pdf-o" style="background: #f1f0fb; padding: 5px 6px; border-radius: 50%; border: 1px solid #ceccda; margin: 0 5px 0 -5px;"></i>
                    <strong  v-html="row.name"></strong>
                    <span v-if="row.enrollment && row.enrollment.university"> • </span>
                    <span style="color: rgb(134, 142, 150);" v-if="row.enrollment && row.enrollment.university" v-html="row.enrollment.university.name"></span>
                    <span v-if="row.enrollment && row.enrollment.level"> • </span>
                    <span v-if="row.enrollment && row.enrollment.level" v-html="row.enrollment.level.name" style="color: rgb(134, 142, 150);"></span> 
                    <span v-if="row.comments"> • </span>
                    <span v-html="row.comments" style="color: rgb(134, 142, 150);"></span>
                </div>
                <div class="right">
                    <div class="round_btn_groups">
                      <div class="add_more_drop tooltips" v-if="row.file_url">
                        <i v-tooltip="'View File'" @click="viewFile(row)" class="fa fa-eye dropdown-toggle" style="line-height: 13px;"></i>
                      </div>
                      <a v-if="row.file_download_url" :href="row.file_download_url" download="download">
                        <div class="add_more_drop open_dynamic_modal tooltips">
                            <i v-tooltip="'Download File'" class="fa fa-paperclip dropdown-toggle"></i>
                        </div>
                      </a>
                    </div>
                </div>
              </div>
          </div>
          </div>
        </div>

    </div>
    <!-- <div v-else class="box-typical box-typical-padding mb-0 p-0" id="crm_profile_div" style="background: #f2f3f5; border: none;">
      <span class="text-danger">No information found</span>
    </div> -->
      <b-modal ref="document_upload_modal" size="lg" centered hide-footer>
        <!-- <template #modal-title>
            <span class="text-primary">Add Document</span>
        </template> -->

        <template #modal-header>
          <h5 class="modal_title">Add Document</h5>
          <button type="button" @click="$refs.document_upload_modal.hide()">
                <i class="font-icon-close-2"></i>
            </button>
        </template>

        <div class="wait_me_insert_form">
            <div class="row add_edit_additional_doc_form">
              <div class="col-lg-12">
                  <div class="box-typical" style="padding: 15px 15px 16px; margin: 10px 15px 15px; border-radius: .25rem; border: none;">

                    <div class="row">
                        <div class="col-md-12">
                            <div style=" padding: 15px 15px 5px; background: #f5f4fd; margin-bottom: 20px; border-radius: .25rem; ">
                                <div class="form-row">
                                    <div class="form-group col-md-6 mb-1">
                                        <label class="form-label" for="documentfile" style="color: #5d5d5d !important; font-size: 12px; font-weight: 600; margin-bottom: 6px !important;">
                                            Upload File
                                        </label>
                                        <input type="file" v-on:change="add_attachment_for_document_upload($event)" name="documentfile" id="documentfile" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div class="row">
                      <div class="col-md-12 mt-1">
                        <div class="form-group mb-0">
                            <div style="background: #dedbf1; padding: 7px 16px; border-radius: 30px; font-size: 12px; font-weight: 700; margin-bottom: 20px;">
                                Comments
                            </div>
                            <textarea class="form-control" v-model="form.document_upload.comments" id="comments" name="comments" rows="2" placeholder="Add a Comments..." style="min-height: 55px; max-height: 55px;" spellcheck="false"></textarea>
                        </div>
                      </div>
                    </div>

                  </div>
              </div>
              <div class="col-lg-12 mt-0 text-right">
                  <button class="btn cancel_button mr-1" @click="$refs.document_upload_modal.hide()">Cancel</button>
                  <button class="btn submit_button btn-submit mr-1" @click="uploadDocumentData()" type="submit">Submit</button>
              </div>
          </div>
        </div>
    </b-modal>


      <b-modal ref="additional_document_modal" size="lg" centered hide-footer>
        <template #modal-header>
          <h5 class="modal_title">Add/Edit Additional Document</h5>
          <button type="button" @click="$refs.additional_document_modal.hide()">
                <i class="font-icon-close-2"></i>
            </button>
        </template>
        <!-- <template #modal-title>
            <span class="text-primary">Add/Edit Additional Document</span>
        </template> -->

        <div class="wait_me_insert_form">
            <!-- <div class="form-row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="form_label" for="file_name">Old password <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.additional_documents.file_name" class="form-control" id="file_name" placeholder="Enter file name" autocomplete="off"/>
                    </div>
                    <div class="form-row">
                        <div class="col text-right">
                            <button class="submit_button">Submit</button>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row add_edit_additional_doc_form">
              <div class="col-lg-12">
                  <div class="box-typical" style="padding: 15px 15px 16px; margin: 10px 15px 15px; border-radius: .25rem; border: none;">

                    <div class="row">
                        <div class="col-md-12">
                            <div style=" padding: 15px 15px 5px; background: #f5f4fd; margin-bottom: 20px; border-radius: .25rem; ">
                                <div class="form-row">
                                    <input type="hidden" name="type" value="">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="name" style="color: #5d5d5d !important; font-size: 12px; font-weight: 600; margin-bottom: 6px !important;">
                                          File Name
                                        </label>
                                        <input type="text" v-model="form.additional_documents.name" id="name" class="form-control" required="" value="" style="padding: 8px .75rem !important;">
                                    </div>
                                    <div class="form-group col-md-6 mb-1">
                                        <label class="form-label" for="documentfile" style="color: #5d5d5d !important; font-size: 12px; font-weight: 600; margin-bottom: 6px !important;">
                                            Upload File
                                        </label>
                                        <input type="file" v-on:change="add_attachment($event)" name="documentfile" id="documentfile" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div class="row">
                      <div class="col-md-12">
                        <div style="background: #dedbf1; padding: 7px 16px; border-radius: 30px; font-size: 12px; font-weight: 700; margin-bottom: 10px;">
                            Please select from below if the document belongs to any specific application
                        </div>

                        <div class="radio_box">
                            <label>
                                <input type="radio" checked="checked" v-model="form.additional_documents.enrolment" value="0">
                                <span><b>Applicable to all applications</b></span>
                            </label>
                        </div>
                            
                        <!-- <div class="radio_box">
                            <label>
                                <input type="radio" v-model="form.additional_documents.enrolment" name="enrolment" value="17459">
                                <span>
                                    Australian Institute of Commerce and Language
                                    <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;">Bachelor ( Y1 )</small>
                                                                        •
                                    <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;">Oct 2021</small>
                                                                        •
                                    <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;">Not Specified</small>
                                </span>
                            </label>
                        </div> -->
                        <div class="radio_box" v-for="(enrollment, index) in enrolmentList" :key="index">
                            <label>
                                <input type="radio" v-model="form.additional_documents.enrolment" :value="enrollment.id">
                                <span>
                                    <b class="mr-50">{{ cn(enrollment, 'university.name', 'N/A') }}</b>
                                    <b v-if="enrollment.level"> • </b>
                                    <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;" v-if="enrollment.level" v-html="enrollment.level.name"></small>
                                    <b v-if="enrollment.start_date"> • </b>
                                    <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;" v-if="enrollment.start_date" v-html="dMonth(enrollment.start_date)"></small>
                                    <b v-if="enrollment.subject"> • </b>
                                    <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;" v-if="enrollment.subject" v-html="enrollment.subject"></small>
                                </span>
                            </label>
                        </div>
                        <!-- <div class="radio_box">
                            <label>
                                <input type="radio" v-model="form.additional_documents.enrolment" name="enrolment" value="24653">
                                <span>
                                    American University in Switzerland
                                                                        •
                                    <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;">APC</small>
                                                                        •
                                    <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;">Sep 2023</small>
                                                                        •
                                    <small style="color: rgb(134, 142, 150); font-size: 12px !important; margin: 0 4px;">English</small>
                                </span>
                            </label>
                        </div> -->
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="form-group mb-0">
                            <div style="background: #dedbf1; padding: 7px 16px; border-radius: 30px; font-size: 12px; font-weight: 700; margin-bottom: 20px;">
                                Comments
                            </div>
                            <textarea class="form-control" v-model="form.additional_documents.comments" id="comments" name="comments" rows="2" placeholder="Add a Comments..." style="min-height: 55px; max-height: 55px;" spellcheck="false"></textarea>
                        </div>
                      </div>
                    </div>

                  </div>
              </div>
              <div class="col-lg-12 mt-0 text-right">
                  <button class="btn cancel_button mr-2" @click="$refs.additional_document_modal.hide()">Cancel</button>
                  <button class="btn submit_button btn-submit" style="margin-right: 16px;" @click="submitAdditionalDocumentData()" type="submit">Submit</button>
              </div>
          </div>
        </div>
    </b-modal>

    <div :class="imageViewerImageUrl ? 'image-viewer-open' : 'image-viewer'">
          <div class="row">
            <div class="col-md-12 text-right">
                <div style="width: 100%; position: relative; display: inline;">
                    <span class="fa fa-times pdfclose" @click="closeViewFile()"></span>
                    <iframe :src="imageViewerImageUrl" style="width: 91%; height: 90vh; background: #f2f3f5;"></iframe>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>
<script>
import feather from "feather-icons";
// import VueApexCharts from 'vue-apexcharts';
// import { BDropdown, BDropdownItem } from 'bootstrap-vue';
// import Treeselect from '@riophae/vue-treeselect';
import { BModal } from 'bootstrap-vue';

export default {
  name: "DocumentsContent",
  props: ['studentAllInfo'],
  components: {
    // VueApexCharts,
    // BDropdown, BDropdownItem,
    // Treeselect
    BModal
  },
  data: () => {
    return {
        flag: {
            show_filter: false,
        },
        search: {
            intake_id: null,
        },
        enrolmentList: [],
        form: {
          additional_documents: {
            id: '',
            name: '',
            documentfile: '',
            file_url: '',
            comments: '',
            enrolment: '',
          },
          document_upload: {
            id: '',
            name: '',
            documentfile: '',
            file_url: '',
            comments: '',
            enrolment: '',
          }
        },
        imageViewerImageUrl: ''
    };
  },
  watch: {},
  methods: {
    openDocumentUploadModal: function(documentId) {
      this.form.document_upload.id = documentId;
      this.$refs.document_upload_modal.show()
    },
    toggle_filter: function() {
      this.flag.show_filter = !this.flag.show_filter;
    },
    viewFile: function(item) {
      // window.open(item.file_url)
      this.imageViewerImageUrl = item.file_url
    },
    closeViewFile: function() {
      // window.open(item.file_url)
      this.imageViewerImageUrl = ''
    },
    additionalDocumentInitialData: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v2/student/ajax/get_additional_document_initial_data');

        try{
          var res=await jq.get(url);
          this.enrolmentList = res.data.enrollments;
        }catch(error){
          console.log(error);
        }

    },
    resetAdditionDocuments: function () {
        this.form.additional_documents = {
            id: '',
            name: '',
            documentfile: '',
            file_url: '',
            comments: '',
            enrolment: '',
        }
    },
    resetDocumentData: function () {
        this.form.document_upload = {
            id: '',
            name: '',
            documentfile: '',
            file_url: '',
            comments: '',
            enrolment: '',
        }
    },
    add_attachment_for_document_upload: function (e) {
        let file_input = e.target.files[0];
        this.form.document_upload.documentfile = file_input
    },
    add_attachment: function (e) {
        let file_input = e.target.files[0];
        this.form.additional_documents.documentfile = file_input
    },
    uploadDocumentData: async function () {
        var ref=this;
        var jq=ref.jq();

        var form_data = new FormData();
        const item = ref.form.document_upload

        // if (item.file) {
        //     form_data.append('attachment', item.file);
        // } else if (item.file_url) {
        //     form_data.append('attachment', null);
        // } else {
        //     form_data.append('attachment', false);
        // }

        form_data.append('id', item.id);
        form_data.append('name', item.name);
        form_data.append('enrolment', item.enrolment);
        form_data.append('comments', item.comments);
        form_data.append('documentfile', item.documentfile);

        ref.wait_me('.wait_me_form');

        jq.ajax({
            url: ref.url('api/v2/student/ajax/store_lead_documents_data'),
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{
                ref.alert(res.msg);
                ref.$refs.document_upload_modal.hide()
                this.resetDocumentData()
                ref.$emit('get-dashboard-data', true)
            }
        }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
        ).always(()=>ref.wait_me('.wait_me_form', 'hide'));
    },
    submitAdditionalDocumentData: async function () {
        var ref=this;
        var jq=ref.jq();

        var form_data = new FormData();
        const item = ref.form.additional_documents

        // if (item.file) {
        //     form_data.append('attachment', item.file);
        // } else if (item.file_url) {
        //     form_data.append('attachment', null);
        // } else {
        //     form_data.append('attachment', false);
        // }

        form_data.append('id', item.id);
        form_data.append('name', item.name);
        form_data.append('enrolment', item.enrolment);
        form_data.append('comments', item.comments);
        form_data.append('documentfile', item.documentfile);

        ref.wait_me('.add_edit_additional_doc_form');

        jq.ajax({
            url: ref.url('api/v2/student/ajax/store_additional_documents_data'),
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{
                ref.alert(res.msg);
                ref.$refs.additional_document_modal.hide()
                this.resetAdditionDocuments()
                ref.$emit('get-dashboard-data', true)
            }
        }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
        ).always(()=>ref.wait_me('.add_edit_additional_doc_form', 'hide'));
    },
  },
  created: function() {
    this.setup_urls();
  },
  mounted: function() {
    feather.replace();
    this.additionalDocumentInitialData();
  },
};
</script>

<style>
    
.download_btn{

    color: #999999;
    font-size: initial;
    border-bottom: none !important;
    padding: 0;
    border: none;
    margin: 0;

}

.documentation_part .single_doc_item{
        display: flex;
        background: fff;
        padding: 7px 16px;
        margin-top: 10px;
        border-radius: 30px;
        border: 1px solid #e9f0f3;
        margin-left: 27px;
    }
    .documentation_part .single_doc_item:hover{
        background: #f5f4fd;
    }
    .documentation_part .single_doc_item .left{
        width: calc(100% - 125px);
        align-self: center;
    }
    .documentation_part .single_doc_item .left strong{
        color: rgb(57, 59, 62);
        font-size: 12px;
    }
    .documentation_part .single_doc_item .right{
        width: 125px;
        align-self: center;
        text-align: -webkit-right;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar{
        width: fit-content;
        overflow: auto;
        border-radius: .25rem;
        /*margin: 0 auto;*/
        display: flex;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar a {
        text-align: center;
        padding: 0;
        /*color: #818084;*/
        color: #c3c3c3;
        font-size: 16px;
        margin: 0 3px;
        border-bottom: none;
        top: 0;

        display: block;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar .fa{
        font-size: .9rem;
        padding: 3px 5px;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar .btn_round{
        border: .5px solid #d8e2e7;
        border-radius: 50%;
        /*width: 25px;
        height: 25px;*/
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar .btn_round:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .documentation_part .single_doc_item .right .rounded_icon_bar img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-left: 3px;
    }
    /*.documentation_part .single_doc_item .right .rounded_icon_bar .fa-file-pdf-o {
        padding: 3px 6px;
    }*/

    .documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle:after{
        display: none;
    }
    /*.documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle{
        color: #c3c3c3;
        cursor: pointer;
        border: .5px solid #d8e2e7;
        border-radius: 50%;
        padding: 3px 5px;
        font-size: .9rem;
        padding: 3px 5px;
    }*/
    .documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show{
        /*margin-left: -97px;*/
        min-width: auto;
        padding: 0;

        z-index: 2;
        top: -5px !important; 
        left: 60px !important;
    }
    .documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .dropdown-item{
        background: #dedbf1;
        color: #2e1153;
        font-size: 12px;
        border: none;
    }
    .documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .dropdown-item:hover{
        background: #2e1153;
        color: #fff !important;
    }
    .documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .activate{
        background: #2e1153;
        color: #fff;
    }
    .documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .dropdown-item:first-child{
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
    .documentation_part .single_doc_item .right .add_more_drop .dropdown-menu.show .dropdown-item:last-child{
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }


    .documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle{
        background: #dedbf1;
        padding: 4px 8px 2px;
        border-radius: 50%;
        font-size: 13px;
        border: 1px solid transparent;
        color: #826aa0;
        cursor: pointer;
    }
    .documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .documentation_part .single_doc_item .right .add_more_drop{
        z-index: auto !important;

        margin: 0 3.5px;
    }

    .documentation_part .single_doc_item .right img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }

    .documentation_part .single_doc_item .round_btn_groups{
        display: flex;
        width: 130px;
        justify-content: flex-end;
      }
      .documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-paperclip{
        padding: 4px 6px 4px !important;
        font-size: 13px;
      }
      .documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-upload, .documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-trash, .documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-pencil{
        color: #826aa0 !important;
      }
      .documentation_part .single_doc_item .round_btn_groups .add_more_drop .fa-eye{
        padding: 4px 5px !important;
        color: #826aa0 !important;
        font-size: 14px;
      }
      /*.documentation_part .single_doc_item .right .add_more_drop .dropdown-toggle{
        padding: 4px 5px !important;
      }*/
      .documentation_part .single_doc_item .round_btn_groups .add_more_drop .font-icon{
        color: #826aa0 !important;
      }
.submit_button {
    background-color: #2e1153 !important;
    border-width: 1px !important;
    border-color: #2e1153 !important;
    color: #fff !important;
    border-radius: 30px !important;
    padding: 0 15px !important;
    height: 28px !important;
    font-size: .9rem !important;
    line-height: 2 !important;
}
.submit_button:hover {
    background-color: #fff !important;
    border-color: #2e1153 !important;
    color: #2e1153 !important;
}

.image-viewer {
    position: fixed;
    right: 0px;
    top: -14px;
    width: 0;
    height: 100%;
    overflow: hidden;
    padding-top: 80px;
    transition: all 500ms;
}
.image-viewer-open {
    position: fixed;
    right: 0px;
    top: -14px;
    width: 43%;
    height: 100%;
    overflow: hidden;
    padding-top: 80px;
    transition: all 500ms;
    z-index: 999999;
}
.pdfclose {
    cursor: pointer;
    margin: 10px;
    display: inline;
    position: absolute;
    z-index: 999;
    background: #2e1153;
    padding: 5px 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    color: #fff;
    left: -53px;
    font-size: 16px !important;
}

.documentation_part .full_profile_additional_doc .single_doc_item {
    background: #fff;
    font-size: 12px;
}
.documentation_part .full_profile_additional_doc .single_doc_item:hover {
    background: #f5f4fd;
}
.add_edit_additional_doc_form .radio_box label input:checked + span {
    background-color: #f5f4fd;
}
.add_edit_additional_doc_form .radio_box label span {
    padding: 7px 16px;
    margin-top: 10px;
    border-radius: 30px;
    border: 1px solid #e9f0f3;
    width: 100%;
    color: rgb(57, 59, 62);
    font-size: 12px;
    font-weight: 600;
    display: -webkit-inline-box;
}
.add_edit_additional_doc_form .radio_box label span:hover {
    background: #f5f4fd;
}
.add_edit_additional_doc_form .radio_box label span:before {
    top: 2px;
    position: relative;
}
.add_edit_additional_doc_form .radio_box label {
    width: 100%;
}
</style>
