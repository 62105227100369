<template>
<section class="root">

   <div class="row py-2 px-2">
      <div class="col-lg-12">
         <div style="min-height: 570px;">
            <div class="row">
               <div class="col-lg-6" style="padding:5px;">
                  <div id="form_full_profile_lead_english_test">

                     <div class="form_part pt-0">

                        <div class="stage_form_header">
                           <div class="left">
                              <i class="fa fa-align-center" aria-hidden="true"></i>
                           </div>
                           <div class="right">
                              <span class="main_title">Test Score</span>
                              <span class="sub_title">
                              Provide test scores taken recently.
                              </span>
                           </div>
                        </div>

                        <div>
                           <span style="font-size: 12px; font-weight: 500;">
                              To add please select from the list below.
                           </span>                           
                        </div>

                        <div class="row">
                           <div class="col-md-12 mt-50">

                              <div class="select-language-test hand"  v-for="(row, index) in list.language_tests" :key="index" :class="row.id==cn(info.active_test, 'id')?'active-test':''" v-on:click="toggle_active_test(row)">
                                 <span v-html="row.short_name"></span>
                              </div>

                           </div>
                        </div>

                        <div class="date-div" style="background: rgb(245, 244, 253); padding: 10px 10px 0px; border-radius: 4px; margin-top: 10px;"  v-if="info.active_test">
                           <div class="form-row">
                              <div class="form-group col-lg-6">
                                 <label class="form_label">Test Date</label>
                                 <div class="input-group input-group-sm">

                                    <flat-pickr
                                       v-model="form.eng_test.test_date"
                                       :disabled="info.active_test.id == 61 ? true : false"
                                       :config="configs.flatpickr"
                                       :class="info.active_test.id == 61 ? 'test_date' : ''"
                                       class="form-control flatpickr-input"
                                       placeholder="Enter Test Date"
                                    />

                                    <span class="input-group-text">
                                       <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </span>
                                 </div>
                              </div>
                              <div class="form-group col-lg-6">
                                 <label class="form_label">Expiration Date</label>
                                 <div class="input-group input-group-sm">
                                    <flat-pickr
                                       v-model="form.eng_test.expiration_date"
                                       :disabled="info.active_test.id == 61 ? true : false"
                                       :config="flatePickerExpiryDateConfig"
                                       class="form-control date flatpickr-input"
                                       :class="info.active_test.id == 61 ? 'exp_date' : ''"
                                       placeholder="Enter Expiration Date"
                                    />
                                    <span class="input-group-text">
                                       <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </span>
                                 </div>
                              </div>
                           </div>
                           <div class="child_test_fields">
                              <div class="form-row">
                                 <div class="form-group col-md-6">
                                    <label for="test_name" class="form_label">Test Name</label>
                                    <input type="text" id="test_name" class="form-control form-control-sm" name="test_name" :value="info.active_test.short_name" readonly/>
                                 </div>
                                 <div class="form-group col-md-6" v-for="(row, index) in list.child_test_fields" :key="index">
                                    <label for="test_name" class="form-control-label text-muted" v-html="info.active_test.id==61?'English  Score':row.label"></label>
                                    <input type="text" id="test_name" class="form-control form-control-sm" placeholder="Enter Score" v-model="row.result"/>
                                 </div>
                              </div>

                              <!-- <div class="row">
                                 <div class="form-group col">
                                    <label for="Listening" class="form_label mb-1">Subject: Listening</label>
                                    <input type="text" id="Listening" class="form-control" name="results[14]" placeholder="Enter Score" value="">
                                 </div>
                                 <div class="form-group col">
                                    <label for="Reading" class="form_label mb-1">Subject: Reading</label>
                                    <input type="text" id="Reading" class="form-control" name="results[15]" placeholder="Enter Score" value="">
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="form-group col">
                                    <label for="Writing" class="form_label mb-1">Subject: Writing</label>
                                    <input type="text" id="Writing" class="form-control" name="results[16]" placeholder="Enter Score" value="">
                                 </div>
                                 <div class="form-group col">
                                    <label for="Speaking" class="form_label mb-1">Subject: Speaking</label>
                                    <input type="text" id="Speaking" class="form-control" name="results[17]" placeholder="Enter Score" value="">
                                 </div>
                              </div> -->

                           </div>
                        </div>
                     </div>

                     <div class="text-right mt-50 btn-div mr-50" v-if="info.active_test">
                        <button @click="formClose()" class="btn cancel_button mr-1 btn-cancel" style="padding: 4px 8px !important;">
                        <i class="fa fa-close" aria-hidden="true"></i>
                        </button>
                        <button type="submit" class="btn submit_button test_submit_save" v-on:click="submit_eng_test()">
                           Save
                        </button>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="scrolled_content_part_outer">
                     <div class="title_in_border">List of Test Scores</div>
                     <div class="scrolled_content_part">
                        <div class="single_part" v-for="(row, index) in list.lead_english_tests" :key="index">
                           <div class="left">
                              <h3>
                                 <span class="progress-lbl" style="font-size: 12px; font-weight: 600; color: black;" v-html="cn(row, 'language_test.name', 'Not Specified')"></span>
                              </h3>
                              <span v-if="row.test_date" style="font-size: 11px; color: #868e96;">
                              Test Date: {{ dDate(row.test_date) }}
                              </span>
                              <div v-else class="my-2"></div>
                              <div class="row mt-50" style="background: #f5f4fd; padding: 5px 0; margin: 0; border-radius: 4px;">

                                 <template v-for="(result, index) in row.results">
                                    <div class="col text-center" style="font-weight: 600;" :key="index" v-if="index==0">
                                       <span v-if="result.child_language_test_id==61">English Score</span>  
                                       <span v-else>Overall</span><br>
                                       {{ result.result }}
                                    </div>
                                    <div v-else class="col text-center"  :key="index">
                                    {{ result.language_test.name }} <br>
                                    {{ result.result }}
                                    </div>
                                 </template> 

                              </div>

                              <div class="dropdown action_dropdown" style="right: -10px; top: 3px; position: absolute;" :class="row.show_menu?'show':''" v-on:click="toggle_menu(row)">
                                 <!-- <i class="fa fa-ellipsis-v dropdown-toggle"></i>
                                 <div
                                    class="dropdown-menu" style="position: absolute; transform: translate3d(0px, 20px, 0px); will-change: transform; top: 0px; left: 0px;"
                                    :class="row.show_menu?'show':''"
                                 >
                                    <span class="dropdown-item edit-test-score" v-on:click="edit_englist_test(row)">
                                       <i class="fa fa-pencil mr-1" aria-hidden="true"></i>
                                       Edit
                                    </span>

                                 <span class="dropdown-item delete" v-on:click="delete_lead_english_test(row, index)">
                                    <i class="fa fa-trash mr-1" aria-hidden="true"></i>Delete</span>
                                 </div> -->
                                 <div class="d-flex">                                            
                                    <i v-tooltip="'Edit'" @click="edit_englist_test(row)" class="fa fa-pencil action_button mr-50"></i>
                                    <i v-tooltip="'Delete'" @click="delete_lead_english_test(row, index)" class="fa fa-trash-o action_button"></i>
                                 </div>
                              </div>

                           </div>
                           <div class="right">
                           </div>
                        </div>
                        <div v-if="list.lead_english_tests && list.lead_english_tests.length==0" class="alert text-center" role="alert" style="border-color: #e9f0f3; font-size: 12px !important; color: #dc3545; border-radius: 30px; padding: 8px 15px;">
                              <span>No information available.</span>
                        </div>
                        <div v-if="list.lead_english_tests==null" class="alert text-center" role="alert" style="border-color: #e9f0f3; font-size: 12px !important; border-radius: 30px; padding: 8px 15px;">
                              <span>Loading Data...</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <button type="submit" class="btn submit_button test_submit_complete pull-right" data-leadid="37228" style="bottom: 0;position: absolute;right: 15px;" v-if="!flag.step_completed" v-on:click="complete_this_step()">
            Save &amp; Complete
            </button>
         </div>
      </div>
   </div>

</section>
</template>

<script>
import swal from "bootstrap-sweetalert";
export default {
  name: 'TestScore',
  data(){
    return {
      list:{
        language_tests:[],
        lead_english_tests: null,
        child_test_fields:[]
      },
      flag:{
         step_completed: false
      },
      info:{
         active_test: null
      },
      configs: {
        flatpickr: {
          dateFormat: "d M Y",
          maxDate: 'today'
        },
        flatpickr_expiration_date: {
          dateFormat: "d M Y",
        }
      },
      form:{
         eng_test:{
            id: null,
            language_test_id:'',
            test_date:'',
            expiration_date:'',
            results:[]
         }
      }
    }
  },
  computed: {
     flatePickerExpiryDateConfig: function () {
        if (this.form.eng_test.test_date) {
           const date = {
               dateFormat: "d M Y",
               minDate: this.form.eng_test.test_date
            }
            return date 
        } else {
            const date = {
               dateFormat: "d M Y",
            }
            return date
        }
     }
  },
  methods:{
    formClose: function () {
        this.$emit('stage-close')
    },
    init: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v2/student/ajax/lead_english_form_data');

      try{
        var res=await jq.get(url);
        ref.flag.step_completed=res.data.step_completed;
        ref.list.language_tests=res.data.language_tests;
        ref.list.lead_english_tests=res.data.lead_english_tests;
      }catch(err){
        ref.alert(ref.err_msg(err), 'error');
      }

    },
    toggle_active_test: function(row=null){

       if(!row){
          this.info.active_test=null;
          this.list.child_test_fields=[];
       }else if(this.info.active_test && row.id==this.info.active_test.id){
          this.info.active_test=null;
          this.list.child_test_fields=[];
       }else{
          this.info.active_test=this.clone(row);
          this.fetch_active_test_info();
       }

    },
    fetch_active_test_info: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v2/student/ajax/lead_english_child_test_fields');

      var qstr={
         test_id: ref.info.active_test.id,
         lead_english_test_id: ref.cn(ref.form.eng_test, 'id', '')
      };

      try{

         ref.list.child_test_fields=[];
        var res=await jq.get(url, qstr);
        ref.list.child_test_fields=res.data.child_test_fields;

      }catch(err){
        ref.alert(ref.err_msg(err), 'error');
      }

    },
    submit_eng_test: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v2/student/ajax/save_lead_eng_test');

      var form_data=ref.form.eng_test;
      form_data.language_test_id=ref.info.active_test.id;
      form_data.results=ref.list.child_test_fields;

      try{

         ref.wait_me('#form_full_profile_lead_english_test');

        var res=await jq.post(url, form_data);        
        ref.alert(res.msg);
        ref.toggle_active_test();
        ref.fetch_lead_english_test_list();
        this.$emit('dashboard-info-update', true)

      }catch(err){
         ref.alert(ref.err_msg(err), 'error');
      }finally{
         ref.wait_me('#form_full_profile_lead_english_test', 'hide');
      }

    },
    toggle_menu: function(row){

      this.list.lead_english_tests.forEach(function(each){
        if(row.id!=each.id) each.show_menu=false;
      });

      row.show_menu=!row.show_menu;

    },
    edit_englist_test: function(row){

       var ref=this;
       var form=ref.form.eng_test;

       form.id=row.id;
       form.test_date=row.test_date?ref.dDate(row.test_date):null;
       form.expiration_date=row.expiration_date?ref.dDate(row.expiration_date):null;

       this.toggle_active_test(row.language_test);

    },
    fetch_lead_english_test_list: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v2/student/ajax/lead_english_test_list');

      ref.list.lead_english_tests=null;

      try{

         var res=await jq.get(url);
         ref.list.lead_english_tests=res.data.lead_english_tests;

      }catch(err){
        ref.alert(ref.err_msg(err), 'error');
      }

    },
    delete_lead_english_test: function(row, row_index){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/delete_lead_english_test");
      var jq = ref.jq();

      swal({
        title: "Are you sure? You want to delete this "+row.language_test.name+" test related information",
        text: "Press Yes to confirm.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, row);
                ref.alert(res.msg);
                ref.remove_row(ref.list.lead_english_tests, row_index);
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    },
    complete_this_step: async function(){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/lead_eng_test_step_completed");
      var jq = ref.jq();

      swal({
      //   title: "Are you sure? You want to submit your english related information as completed.",
        title: "Are you sure?",
        text: "Press Yes to confirm.",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url);
                ref.alert(res.msg);
                ref.init();

                ref.$emit("save-and-completed", true);
                
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    }
  },
  created: function(){
    this.setup_urls();
    this.init();
  }
  
}
</script>

<style scoped>

   .form-control-label {
   font-size: 12px;
   color: #9b9b9b !important;
   font-weight: 600;
   margin-bottom: 7px;
   }
   .checkbox-detailed input+label{
   height: 25px !important;
   font-size: 12px !important;
   }
   .checkbox-detailed .checkbox-detailed-cell{
   padding: 4px 0 !important;
   }
   .scrolled_content_part{
   /*padding: 20px 10px;
   height: 513px;
   overflow-y: auto;
   overflow-x: hidden;
   border:1px solid #f2f3f5;
   background: #fff;
   position: relative;*/
   padding: 0;
   }
   .scrolled_content_part::-webkit-scrollbar {
   width: 7px;
   }
   .scrolled_content_part::-webkit-scrollbar-track {
   background: #fff;
   border-radius: 5px;
   }
   .scrolled_content_part::-webkit-scrollbar-thumb {
   /*background: #00a8ff;*/
   background: linear-gradient(90deg, rgba(183,180,212,1) 0%, rgb(165 164 181) 0%, #fff 75%);
   border-radius: 5px;
   }
   .scrolled_content_part .single_part {
   display: flex;
   width: 100%;
   /*padding: 10px 15px;
   border: none;
   border-bottom: 1px dashed #e6e8e8;*/
   padding: 7px 16px;
   margin-top: 10px;
   border-radius: .25rem;
   border: 1px solid #e9f0f3;
   align-items: center;
   }
   .scrolled_content_part .single_part .left {
   width: 100%;
   position: relative;
   }
   .scrolled_content_part .single_part .left h3, .scrolled_content_part .single_part .right h3 {
   font-size: 11px; 
   font-weight: bold; 
   color: rgb(98, 95, 139); 
   margin-bottom: 6px;
   }
   .input-group-addon:hover {
   background-color: #00a8ff;
   border: none;
   border-color: #00a8ff;
   color: #fff !important;
   }
   .dateNicon input {
   background: #fff !important;
   border-right: none;
   }
   .input-group-addon {
   background: #fff;
   padding: 0 .75rem;
   }
   .glyphicon{
   position: relative;
   top: 1px;
   display: inline-block;
   font-family: 'Glyphicons Halflings';
   font-style: normal;
   font-weight: 400;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   font-size: 15px;
   }
   .info_middle {
   margin: 0;
   position: absolute;
   top: 50%;
   left: 50%;
   margin-right: -50%;
   transform: translate(-50%, -50%);
   font-size: 12px;
   }
   .scrolled_content_part_outer{
   position: relative;
   /*border: 1px solid #d8e2e7;*/
   border-radius: .25rem;
   margin-top: 10px;
   }
   .scrolled_content_part_outer .title_in_border {
   /*background: white;
   width: fit-content;
   padding: 5px;
   font-size: 13px;
   font-weight: 600;
   top: -14px;
   position: absolute;
   left: 12px;
   z-index: 2;*/
   background: #dedbf1;
   padding: 7px 16px;
   margin-top: 10px;
   border-radius: 30px;
   position: relative;
   font-size: 12px;
   font-weight: 700;
   width: inherit;
   left: 0;
   top: -10px;
   }
   .action_dropdown .dropdown-toggle{
   background: #dedbf1;
   padding: 4px 9px 3px;
   border-radius: 50%;
   font-size: 13px;
   border: 1px solid transparent;
   color: #826aa0;
   cursor: pointer;
   }
   .action_dropdown .dropdown-toggle:hover {
   color: #ff7f00 !important;
   transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
   border: 1px solid #ff7f00;
   }
   .action_dropdown .dropdown-toggle:after{
   display: none;
   }
   .action_dropdown .dropdown-menu.show{
   margin-left: -55px;
   min-width: auto;
   padding: 0;
   margin-top: 3px;
   }
   .action_dropdown .dropdown-menu.show .dropdown-item{
   background: #dedbf1;
   color: #2e1153;
   font-size: 12px;
   border: none;
   padding: 4px 12px;
   }
   .action_dropdown .dropdown-menu.show .dropdown-item:hover{
   background: #2e1153;
   color: #fff !important;
   }
   .action_dropdown .dropdown-menu.show .activate{
   background: #2e1153;
   color: #fff;
   }
   .action_dropdown .dropdown-menu.show .dropdown-item:first-child{
   border-top-left-radius: .25rem;
   border-top-right-radius: .25rem;
   }
   .action_dropdown .dropdown-menu.show .dropdown-item:last-child{
   border-bottom-left-radius: .25rem;
   border-bottom-right-radius: .25rem;
   }
   .checkbox-detailed {
   display: inline-block!important;
   vertical-align: top!important;
   margin: 0 6px 4px 0!important;
   }
   .checkbox-detailed input+label{
   width: max-content!important;
   padding: 0 10px!important;
   }
   .checkbox-detailed input+label:before{
   display: none!important;
   }
   .checkbox-detailed .checkbox-detailed-title{
   font-weight: 400!important;
   }

   .select-language-test{
      display: inline-block;
      padding: 2px 5px;
      margin: 4px;
      background: #f1f1f1;
      border: 1px solid #ece6e6;
      border-radius: 5px;
      font-size: 12px;
   }

   .active-test{
      background-color: #e4f6fe;
      border-color: #00a8ff;
      border-top-color: rgb(0, 168, 255);
      border-right-color: rgb(0, 168, 255);
      border-bottom-color: rgb(0, 168, 255);
      border-left-color: rgb(0, 168, 255);
   }

   .test_date{
      background: rgb(235, 235, 228) !important;
   }

   .exp_date{
      background: rgb(235, 235, 228) !important;
   }

</style>
