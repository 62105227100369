<template>
  <section class="personal-info">


<div class="row py-2 px-2">
   <div class="col-lg-12">
      <div style="min-height: 520px;">
         <div class="row">
            <div class="col-lg-6" id="wait_me">
               
                  <input type="hidden" name="_token" value="dl2XAXVAWlyDeeblNA4G7LXtr2g0AU2eheGjMxtN">
                  <input type="hidden" name="lead_id" value="37228">
                  <div class="stage_form_header">
                     <div class="left">
                        <i class="fa fa-university" aria-hidden="true"></i>
                     </div>
                     <div class="right">
                        <span class="main_title">Education History</span>
                        <span class="sub_title">
                        List down all your education information here.
                        </span>
                     </div>
                  </div>
                  <div class="form_part" style="background: #f5f4fd;">
                     <div class="row">
                        <div class="form-group col-lg-12">
                           <label class="form_label">Academic level<span class="color-red ml-1">*</span></label>

                           <treeselect
                              id="territory_ids"
                              :options="list.academic_levels"
                              placeholder="Select Nationality"
                              autocomplete="off"
                              v-model="form.edu_history.academic_level"
                            />
                        </div>
                        <div class="form-group col-lg-12">
                           <label class="form_label">Subject<span class="color-red ml-1">*</span></label>
                           <input type="text" class="form-control required"  v-model="form.edu_history.subject" required="">
                        </div>
                        <div class="form-group col-lg-12">
                           <label class="form_label">Institution<span class="color-red ml-1">*</span></label>
                           <div class="autocomplete">
                              <input type="text" class="form-control required" id="institute" v-model="form.edu_history.institute" required="">
                           </div>
                        </div>
                        <div class="col-lg-12">
                           <div class="row">
                              <div class="form-group col-lg-6">
                                 <label class="form_label">Result<span class="color-red ml-1">*</span></label>
                                 <div class="input-group result_input" style="height: 31px;">
                                    <input type="text" class="form-control" min="0" step=".1" v-model="form.edu_history.result" required="">
                                    <div class="input-group-addon" style="background: #f5f4fb !important;">
                                       <select class="select_unit" v-model="form.edu_history.result_unit" required="">
                                          <option value="GPA">GPA</option>
                                          <option value="%">%</option>
                                          <option value="other">Other</option>
                                       </select>
                                    </div>
                                 </div>
                                 
                              </div>
                              <div class="form-group col-lg-6">
                                 <label class="form_label">Passing Year<span class="color-red ml-1">*</span></label>
                                  <treeselect
                                    :options="list.years"
                                    placeholder="Select Passing Year"
                                    autocomplete="off"
                                    v-model="form.edu_history.year"
                                  />
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-12">
                           <div class="row">
                              <div class="col-lg-6">
                                 <div class="row">
                                    <div class="form-group col-lg-6">
                                       <label class="form_label">Start Year<span class="color-red ml-1">*</span></label>

                                        <treeselect
                                          :options="list.years"
                                          placeholder="Start Year"
                                          autocomplete="off"
                                          v-model="form.edu_history.start_year"
                                        />

                                    </div>
                                    <div class="form-group col-lg-6">
                                       <label class="form_label">Start Month<span class="color-red ml-1">*</span></label>
                                       <select class="form-control required cls-start-month" v-model="form.edu_history.start_month" required="">
                                          <option selected="" disabled="" value="">Month</option>
                                          <option value="January">January</option>
                                          <option value="February">February</option>
                                          <option value="March">March</option>
                                          <option value="April">April</option>
                                          <option value="May">May</option>
                                          <option value="June">June</option>
                                          <option value="July">July</option>
                                          <option value="August">August</option>
                                          <option value="September">September</option>
                                          <option value="October">October</option>
                                          <option value="November">November</option>
                                          <option value="December">December</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-lg-6">
                                 <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label class="form_label">End Year<span class="color-red ml-1">*</span></label>
                                        <treeselect
                                          :options="endYearList"
                                          placeholder="End Year"
                                          autocomplete="off"
                                          v-model="form.edu_history.end_year"
                                        />
                                    </div>
                                    <div class="form-group col-lg-6">
                                       <label class="form_label">End Month<span class="color-red ml-1">*</span></label>
                                       <select class="form-control required cls-end-month" v-model="form.edu_history.end_month" required="">
                                          <option selected="" disabled="" value="">Month</option>
                                          <option value="January">January</option>
                                          <option value="February">February</option>
                                          <option value="March">March</option>
                                          <option value="April">April</option>
                                          <option value="May">May</option>
                                          <option value="June">June</option>
                                          <option value="July">July</option>
                                          <option value="August">August</option>
                                          <option value="September">September</option>
                                          <option value="October">October</option>
                                          <option value="November">November</option>
                                          <option value="December">December</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <input type="hidden" class="btn_name" name="btn_name" value="">
                  <div class="text-right mt-3">
                     <button class="btn cancel_button mr-1 btn-cancel" style="padding: 4px 8px !important;" @click="formClose()">
                        <i class="fa fa-close" aria-hidden="true"></i>
                     </button>
                     <button type="submit" class="btn submit_button edu_submit_save" v-on:click="submit_edu_history_info()">
                     Save
                     </button>
                  </div>
               
               <!--<div class="stage_form_header stage_form_header_with_yes_no_radio mt-3">
                  <div class="left">
                     <i class="fa fa-info" aria-hidden="true" style="padding: 6px 11px;"></i>
                  </div>
                  <div class="right">
                     <div class="with_yes_no_radio_inner">
                        <div class="with_yes_no_radio_inner_left" style="width: calc(100% - 190px);">
                           <span class="main_title">Do you have any Study Gap?</span>
                        </div>
                        <div class="with_yes_no_radio_inner_right" style="width: 190px;">
                           <div class="radio_box mt-1" style="place-content: flex-end;">
                              <label>
                              <input type="radio" class="change_status" name="study_gap_0" data-leadid="37228" value="1">
                              <span>Yes</span>
                              </label>
                              <label>
                              <input type="radio" class="change_status" name="study_gap_0" data-leadid="37228" value="0" checked="checked">
                              <span>No</span>
                              </label>
                              <button type="submit" class="btn submit_button study_gap_add" style="display: none;">
                              Add
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <form method="POST" action="http://iecc-new.local/agent/ajax-form/sub_lead_edu_gap_save" class="sub_lead_edu_gap_save_form">
                  <input type="hidden" name="_token" value="dl2XAXVAWlyDeeblNA4G7LXtr2g0AU2eheGjMxtN">
                  <input type="hidden" name="lead_id" value="37228">
                  <input type="hidden" name="action" value="add"> 
                  <div class="row study_div" style="display: none;">
                     <div class="col-lg-12">
                        <div class="tab_add_form for_parent_label" style="background: #f5f4fd;">
                           <div class="form-row ">
                              <div class="col-md-4 form-group">
                                 <label class="form_label mb-1">From (Date)<span class="color-red ml-1">*</span></label>
                                 <div class="input-group dateNicon">
                                    <input type="text" class="form-control start_date flatpickr-input" name="start_date" required="" value="" placeholder="Date From" readonly="readonly">
                                    <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-calendar" aria-hidden="true"></span>
                                    </span>
                                 </div>
                              </div>
                              <div class="col-md-4 form-group">
                                 <label class="form_label mb-1">To (Date)<span class="color-red ml-1">*</span></label>
                                 <div class="input-group dateNicon">
                                    <input type="text" class="form-control start_end_date end_date flatpickr-input" name="end_date" required="" value="" placeholder="Date To" readonly="readonly">
                                    <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-calendar" aria-hidden="true"></span>
                                    </span>
                                 </div>
                              </div>
                              <div class="col-md-4 form-group">
                                 <label class="form_label mb-1">Type to Take this Gap<span class="color-red ml-1">*</span></label>
                                 <select id="reason" name="reason" class="form-control required" required="">
                                    <option selected="" disabled="">Choose one..</option>
                                    <option value="Work">Work</option>
                                    <option value="Study">Study</option>
                                    <option value="None">None</option>
                                 </select>
                              </div>
                              <div class="col-md-12 form-group mb-0">
                                 <label class="form_label mb-1">Institute</label>
                                 <div class="autocomplete">
                                    <input type="text" class="form-control required" id="institute" name="institute" required="">
                                 </div>
                              </div>
                              <div class="col-md-12 form-group">
                                 <label class="form_label mb-1">Remarks</label>
                                 <div class="autocomplete">
                                    <textarea class="form-control" name="remarks" placeholder="Add a Remark ..." style="max-height: 31px;"></textarea>
                                 </div>
                              </div>
                              <div class="col-md-12 form-group mb-0">
                                 <div class="text-right">
                                    <span class="btn cancel_button mr-1 btn-cancel-edu-gap close_form">
                                    <i aria-hidden="true" class="fa fa-refresh"></i>
                                    </span>
                                    <button type="submit" class="btn submit_button">
                                    Save
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </form>-->
            </div>
            <div class="col-lg-6">
               <div class="scrolled_content_part_outer">
                  <div class="title_in_border">List of Education History</div>
                  <div class="scrolled_content_part">
                     
                     <div class="single_part" v-for="(row, index) in list.edu_histories" :key="index">
                        <div class="left">
                           <i class="fa fa-university" aria-hidden="true"></i>
                        </div>
                        <div class="middle">
                           <span class="progress-lbl" style="font-size: 12px; font-weight: 600; color: black;" v-html='row.institution'></span> <br>
                           <span style="color: #343434;font-weight: 400;" v-html='row.subject'></span><br>
                           <span class="label_badge_radius" v-if='row.level'  v-html='row.level.name'>A Level</span>
                           <span style="font-size: 11px; color: #868e96;">
                              •
                              <span v-html='row.start_year'></span> <span v-html='row.start_month'></span> - <span v-html='row.end_year'></span> <span v-html='row.end_month'></span>
                           </span> <br>
                        </div>
                        <div class="d-flex">                                            
                           <i v-tooltip="'Edit'" @click="edit_data(row)" class="fa fa-pencil action_button mr-50"></i>
                           <i v-tooltip="'Delete'" @click="delete_edu_history(row, index)" class="fa fa-trash-o action_button"></i>
                        </div>
                        <!-- <div class="action_dropdown">                                            
                           <b-dropdown size="sm" variant="link" class="text-dark" toggle-class="text-decoration-none" no-caret>
                              <template #button-content>
                                    <span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
                              </template>
                              <b-dropdown-item href="javascript:void(0)" @click="edit_data(row)">
                                    <i v-tooltip="'Edit Agent Details'" class="fa fa-pencil mr-50"></i> Edit
                              </b-dropdown-item>
                              <b-dropdown-item href="javascript:void(0)" @click="delete_edu_history(row, index)">
                                    <i v-tooltip="'Delete Agent'" class="fa fa-trash-o text-danger mr-50"></i> Delete
                              </b-dropdown-item>
                           </b-dropdown>
                        </div> -->
                     </div>

                  </div>
               </div>
            </div>
         </div>

          <button class="btn submit_button passport_submit_complete complete_step pull-right"
            style="bottom: 0; position: absolute; right: 15px"
            v-if="!flag.step_completed"
            v-on:click="complete_this_step()">
            Save &amp; Complete
          </button>

      </div>
   </div>
</div>
    
  </section>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import swal from "bootstrap-sweetalert";
// import { BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
  name: "PersonalInfo",
  components: {
    Treeselect,
   //  BDropdown, BDropdownItem
  },
  data() {
    return {
      list: {
        academic_levels: [],
        years: [],
        edu_histories: [],
      },
      form: {
        edu_history: {
          id: null,
          academic_level: null,
          subject: "",
          institute: "",
          result: "",
          result_unit: 'GPA',
          year: null,
          start_year: null,
          start_month: null,
          end_year: null,
          end_month: null,
        },
      },
      configs: {
        flatpickr: {
          dateFormat: "d M Y",
          //maxDate: new Date()
        },
      },
      flag:{
        step_completed: false
      }
    };
  },
  computed: {
     endYearList: function () {
        const start_year = this.form.edu_history.start_year
        if (start_year) {
            const yearIndex = this.list.years.findIndex(item => item.id == start_year)
            return this.list.years.filter((item, index) => index >= yearIndex)
        } else {
            return this.list.years
        }
     }
  },
  methods: {
    formClose: function () {
        this.$emit('stage-close')
    },
    fetch_edu_history_form_data: async function () {

      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v2/student/ajax/edu_history_form_data");

      try {

        var res = await jq.get(url);

        ref.list.academic_levels = res.data.academic_levels;
        ref.list.years = res.data.years;
        ref.list.edu_histories = res.data.edu_histories;
        ref.flag.step_completed = res.data.step_completed;

      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }

    },
    submit_edu_history_info: async function(){

      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v2/student/ajax/save_edu_history_info");

      var form_data=ref.form.edu_history;

      try {

        ref.wait_me('#wait_me');
        var res = await jq.post(url, form_data);

        ref.reset_form();
        ref.fetch_edu_history_form_data();

        ref.alert(res.msg);
        this.$emit('dashboard-info-update', true)

      }catch(err){
        ref.alert(ref.err_msg(err), "error");
      }finally{
        ref.wait_me('#wait_me', 'hide');
      }

    },
    edit_data: function (row){
      this.form.edu_history = {
            id: row.id,
            academic_level: row.level_id,
            subject: row.subject,
            institute: row.institution,
            result: row.result,
            result_unit: row.unit,
            year: row.year,
            start_year: row.start_year,
            start_month: row.start_month,
            end_year: row.end_year,
            end_month: row.end_month,
        }
    },
    reset_form: function () {
      var form = this.form.edu_history;
      form.id= null;
      form.academic_level= null;
      form.subject= "";
      form.institute= "";
      form.result= "";
      form.result_unit= null;
      form.year= null;
      form.start_year= null;
      form.start_month= null;
      form.end_year= null;
      form.end_month= null;
    },
    complete_this_step: async function(){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/lead_edu_history_step_completed");
      var jq = ref.jq();

      swal({
      //   title: "Are you sure? You want to submit your education history information as completed.",
        title: "Are you sure?",
        text: "Press Yes to confirm.",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
               try {
                  var res = await jq.post(url);
                  ref.alert(res.msg);
                  ref.fetch_edu_history_form_data();
                  
                  ref.$emit("save-and-completed", true);
                  
               } catch (error) {
                  ref.alert(ref.err_msg(error), "error");
               }
            })();
          }
        }
      );

    },
   delete_edu_history: function(row, row_index){

      var ref = this;
      var url = ref.url("api/v2/student/ajax/delete_edu_history_info");
      var jq = ref.jq();

      swal({
        title: "Are you sure? You want to delete this related information",
        text: "Press Yes to confirm.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-light",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
        function (isConfirm) {
          if (isConfirm) {
            (async function () {
              try {
                var res = await jq.post(url, row);
                ref.alert(res.msg);
                ref.remove_row(ref.list.edu_histories, row_index);
                ref.$emit('dashboard-info-update', true)
              } catch (error) {
                ref.alert(ref.err_msg(error), "error");
              }
            })();
          }
        }
      );

    }

  },

  created: function () {
    this.setup_urls();
    this.fetch_edu_history_form_data();
  },
};
</script>

<style scoped>
    .scrolled_content_part{
        /*padding: 20px 10px;
        height: 439px;
        overflow-y: auto;
        overflow-x: hidden;
        background: #fff;
        border-radius: .25rem;
        position: relative;*/

        padding: 0;
    }
    .scrolled_content_part::-webkit-scrollbar {
      width: 7px;
    }
    .scrolled_content_part::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 5px;
    }
    .scrolled_content_part::-webkit-scrollbar-thumb {
      /*background: #00a8ff;*/
      background: linear-gradient(90deg, rgba(183,180,212,1) 0%, rgb(165 164 181) 0%, #fff 75%);
      border-radius: 5px;
    }
    .scrolled_content_part .single_part {
        display: flex;
        width: 100%;
        padding: 7px 16px;
        margin-top: 10px;
        border-radius: .25rem;
        border: 1px solid #e9f0f3;

        align-items: center;
    }
    .scrolled_content_part .single_part:last-child {
        /*border-bottom: none;*/
    }
    .scrolled_content_part .single_part .middle/*, .scrolled_content_part .single_part .right*/ {
        width: calc(100% - 45px);
    }
    .scrolled_content_part .single_part .middle h3, .scrolled_content_part .single_part .right h3 {
        font-size: 11px; 
        font-weight: bold; 
        color: rgb(98, 95, 139); 
        margin-bottom: 6px;
    }
    .scrolled_content_part .single_part .left {
        width: 45px;
        align-self: center;
    }
    .scrolled_content_part .single_part .left .fa {
        background: #f1f0fb;
        padding: 10px;
        border-radius: 100%;
        font-size: 14px;
        color: #2e1153;
    }
    .info_middle {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
    }

    .scrolled_content_part_outer{
        position: relative;
        /*border: 1px solid #d8e2e7;*/
        border-radius: .25rem;
        margin-top: 10px;
    }
    .scrolled_content_part_outer .title_in_border {
        /*background: white;
        width: fit-content;
        padding: 5px;
        font-size: 13px;
        font-weight: 600;
        top: -14px;
        position: absolute;
        left: 12px;
        z-index: 2;*/

        background: #dedbf1;
        padding: 7px 16px;
        margin-top: 10px;
        border-radius: 30px;
        position: relative;
        font-size: 12px;
        font-weight: 700;
        width: inherit;
        left: 0;
        top: -10px;
    }
    .scrolled_content_part_outer .label_badge_light {
        border-radius: 20px;
        padding: 1px 10px;
        font-size: 10px;
        font-weight: 400;
        line-height: 1.3;
        /*background: #e0ddf2;*/
        letter-spacing: .2px;
        color: black;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-image: linear-gradient(to right, #dedbf1 , #f5f4fd);
    }


    .stage_form_header_with_yes_no_radio{
        background: #fff !important;
        border: 1px solid #dedbf1 !important;
    }
    .stage_form_header .with_yes_no_radio_inner{
        display: flex; 
        width: 100%;
    }
    .stage_form_header .with_yes_no_radio_inner .with_yes_no_radio_inner_left{
        width: calc(100% - 100px);
        align-self: center;
    }
    .stage_form_header .with_yes_no_radio_inner .with_yes_no_radio_inner_right{
        width: 100px;
    }

    .scrolled_content_part .single_part:hover {
        background: #f5f4fd;
    }
    .action_dropdown .dropdown-toggle{
        background: #dedbf1;
        padding: 4px 9px 3px;
        border-radius: 50%;
        font-size: 13px;
        border: 1px solid transparent;
        color: #826aa0;
        cursor: pointer;
    }
    .action_dropdown .dropdown-toggle:hover {
        color: #ff7f00 !important;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        border: 1px solid #ff7f00;
    }
    .action_dropdown .dropdown-toggle:after{
        display: none;
    }
    .action_dropdown .dropdown-menu.show{
        margin-left: -55px;
        min-width: auto;
        padding: 0;
        margin-top: 3px;
    }
    .action_dropdown .dropdown-menu.show .dropdown-item{
        background: #dedbf1;
        color: #2e1153;
        font-size: 12px;
        border: none;
        padding: 4px 12px;
    }
    .action_dropdown .dropdown-menu.show .dropdown-item:hover{
        background: #2e1153;
        color: #fff !important;
    }
    .action_dropdown .dropdown-menu.show .activate{
        background: #2e1153;
        color: #fff;
    }
    .action_dropdown .dropdown-menu.show .dropdown-item:first-child{
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
    .action_dropdown .dropdown-menu.show .dropdown-item:last-child{
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }


    .result_input .input-group-addon:hover{
       border: 1px solid rgba(197, 214, 222, .7) !important;
    }
    .input-group-addon {
       padding: 0;
    }
    select.form-control:not([multiple='multiple']){
      background-position: calc(100% - 7px) 7px, calc(100% - 20px) 13px, 100% 0;
    }
    .select_unit {
         font-weight: 400; 
         font-size: 13px; 
         border: solid 1px rgba(197, 214, 222, .7);
         height: 28px !important;
      }
</style>
