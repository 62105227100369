
import Login from './Login.vue';
import NotFound from './NotFound.vue';
import Dashboard from './Dashboard.vue';

import ForgotPassword from './ForgotPassword.vue';
import ResetPassword from './ResetPassword.vue';

import Logout from './Logout.vue';

import ChangePassword from './ChangePassword.vue';
import GdprAgreementConfirmation from './GdprAgreementConfirmation.vue';
import SetLoginPassword from './SetLoginPassword.vue';

export default [
  { 
    path: '/',
    redirect: '/login' 
  },
  {
    path: '/login',
    component: Login,
    name: 'Login'
  },
  
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'ForgotPassword'
  },
  {
    path: '/reset-password/:password_reset_token',
    component: ResetPassword,
    name: 'ResetPassword'
  },
  {
    path: '/student/password/reset/:token/:token_info?',
    component: SetLoginPassword,
    name: 'SetLoginPassword'
  },
  {
    path: '/logout',
    component: Logout,
    name: 'Logout'
  },
  {
    path: '/change-password',
    component: ChangePassword,
    name: 'ChangePassword'
  },
  {
    // path: '/gdpr-agreement-confirmation/:gdpr_agreement_id',
    path: '/gdpr-agreement-confirmation/:lead_id',
    component: GdprAgreementConfirmation,
    name: 'GdprAgreementConfirmation'
  },

  
]